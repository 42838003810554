<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Penerimaan</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm patient-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Rawat Inap</th>
                  <th>Laboratorium</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tanggal/Jam</td>
                  <td>{{isParent.rowEdit.arantl_ph_tanggal | moment('DD MMM YYYY')}}, {{isParent.rowEdit.arantl_ph_waktu||"-"}} </td>
                  <td>{{isParent.rowEdit.arantl_ph_tanggal_lab | moment('DD MMM YYYY')}}, {{isParent.rowEdit.arantl_ph_waktu_lab||"-"}} </td>
                  
                </tr>
                <tr>
                  <td>Jumlah Kantong</td>
                  <td>{{isParent.rowEdit.arantl_ph_jml_kantong||"-"}}</td>
                  <td>{{isParent.rowEdit.arantl_ph_jml_kantong_lab||"-"}}</td>
                </tr>
                <tr>
                  <td>Petugas</td>
                  <td>{{isParent.rowEdit.arantl_ph_petugas||"-"}}</td>
                  <td>{{isParent.rowEdit.arantl_ph_petugas_lab||"-"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pengembalian</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm patient-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Rawat Inap</th>
                  <th>Laboratorium</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tanggal/Jam</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_tanggal | moment('DD MMM YYYY')}}, {{isParent.rowEdit.arantl_ph_pengembalian_waktu||"-"}} </td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_tanggal_lab | moment('DD MMM YYYY')}}, {{isParent.rowEdit.arantl_ph_pengembalian_waktu_lab||"-"}} </td>
                </tr>
                <tr>
                  <td>Jumlah Kantong</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_jml_kantong||"-"}}</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_jml_kantong_lab||"-"}}</td>
                </tr>
                <tr>
                  <td>Jumlah Darah</td>
                  <td>
                    <table class="table table-bordered table-sm patient-table">
                      <tbody>
                        <tr v-for="(v,k) in (isParent.rowEdit.arantl_ph_kantong||[])" :key="k">
                          <td>Kantong {{k+1}}</td>
                          <td>{{v}} cc </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table table-bordered table-sm patient-table">
                      <tbody>
                        <tr v-for="(v,k) in (isParent.rowEdit.arantl_ph_kantong_lab||[])" :key="k">
                          <td>Kantong {{k+1}}</td>
                          <td>{{v}} cc </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>Petugas</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_petugas||"-"}}</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_petugas_lab||"-"}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="text-right">
        <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Tutup</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
}

</script>