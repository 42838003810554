<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive mb-0">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Asesmen</th>
                <th>Instruksi</th>
                <th width="150">Aksi</th>
              </tr>
            </thead>
            <template v-for="(v,k) in (isParent.listCPPTRiwayat||[])">
            <tbody :key="k">
              <tr class="table-info">
                <td colspan="2">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancpd_tanggal | moment("DD MMMM YYYY")}}, 
                      {{v.data.arancpd_jam}}</div>
                      <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppas}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                  </div>
                </td>
                <td rowspan="5">
                  <a href="javascript:;" @click="salinData(v)" class="btn btn-icon btn-sm btn-success ml-2" v-b-tooltip.hover title="Salin CPPT">
                    <i class="icon-file-check2"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>S</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpd_subjektif||"-"}}</pre>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="4">-</td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>O</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpd_objektif||"-"}}</pre>
                      <p><span class="font-weight-semibold">Status Lokalis:</span> <br/>
                      <ul v-if="(v.data.arancpd_status_lokalis||[]).length" >
                        <li v-for="(vSl,kSl) in (v.data.arancpd_status_lokalis||[])" :key="kSl">
                          Terdapat {{(vSl.data||[]).length}} titik yang ditandai pada bagian {{defineAnat(vSl.type)}} pasien. 
                        </li>
                      </ul>
                      <span v-else> Tidak ada pemeriksaan </span>
                      <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a></p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <div>
                          <div class="font-weight-semibold">Diagnosis:</div>   
                          <ul class="pl-3 mb-0" v-if="(v.diagnosa||[]).length">
                            <li v-for="(v1,k1) in (v.diagnosa||[])" :key="k1">
                              {{v1.mi10_name||"-"}}
                              <span class="font-weight-semibold">[{{v1.mi10_code||"-"}}]</span>
                            </li>
                          </ul>
                        </div>
                        <p>Diagnosa Lainnya: 
                        <pre class="pre-input">{{v.data.arancpd_diagnosa_text||"-"}}</pre>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>P</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancpd_perencanaan||"-"}}</p>
                        <p>
                          <span class="font-weight-semibold">Tindak Lanjut: </span> {{checkTindakLanjut(v.data)}}
                          <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            </template>
            <template v-if="!(isParent.listCPPTRiwayat||[]).length">
              <tbody>
                <tr class="table-info">
                  <td colspan="99" class="text-center">Tidak ada Data CPPT</td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
    
    <b-modal v-model="openDetailSBAR" :title="'Detail CPPT Dokter'" size="xl" ok-only ok-title="Tutup">
      <DetailSBAR :rowData="dataSBAR"/>
    </b-modal> 
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import DetailSBAR from '@/components/Ranap/DetailSBAR.vue'  

export default {
  extends: GlobalVue,  
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  components:{
    DetailSBAR,
  },
  data() {
    return {
      openDetailSBAR: false,
      dataSBAR: {},
    }
  },
  mounted() {
    console.log(this.isParent)
    console.log("test")
  },
  methods: {
    getUserInput(type){
      let text = ''
      if(type == 'DOKTER'){
        text = 'Dokter'
      }else if(type == 'PPALAB'){
        text = 'Laboratorium'
      }else if(type == 'PPARADIO'){
        text = 'Radiologi'
      }else if(type == 'PPAFISIO'){
        text = 'Fisioterapi'
      }else if(type == 'PPAFARMASI'){
        text = 'Farmasi'
      }else if(type == 'DIETISIEN'){
        text = 'Dietisien'
      }else if(type == 'PERAWAT'){
        text = 'Perawat'
      }else {
        text = null
      }
      return text
    },
    checkTindakLanjut(data){
      let TL = []
      if(data.arancpd_is_resep == 'Y'){
        TL.push('Resep')
      }
      if(data.arancpd_is_lab == 'Y'){
        TL.push('Laboratorium')
      }
      if(data.arancpd_is_radiologi == 'Y'){
        TL.push('Radiologi')
      }
      if(data.arancpd_is_fisio == 'Y'){
        TL.push('Fisioterapi')
      }
      if(data.arancpd_is_operasi == 'Y'){
        TL.push('Tindakan Operasi')
      }
      if(data.arancpd_is_konsultasi == 'Y'){
        TL.push('Konsultasi')
      }
      if(data.arancpd_is_dietisien == 'Y'){
        TL.push('Konsultasi Dietisien')
      }
      if(data.arancpd_is_raber == 'Y'){
        TL.push('Rawat Bersama')
      }
      if(data.arancpd_is_alih_rawat == 'Y'){
        TL.push('Alih Rawat')
      }
      if(data.arancpd_is_pindah_ruang == 'Y'){
        TL.push('Pindah Ruang')
      }
      if (data.arancpd_is_rencana_operasi == 'Y') {
        TL.push('Rencanakan Operasi')
      }
      if (data.arancpd_is_stop_raber == 'Y') {
        TL.push('Stop Rawat Bersama')
      }
      if(data.arancpd_is_perawatan == 'Y'){
        TL.push('Masih dalam Perawatan')
      }
      if(data.arancpd_is_done_perawat == 'Y'){
        TL.push('Selesai Perawatan')
      }
      return TL.join(", ")
    },
    
    defineAnat(anat){
      if(anat == 'eye'){
        return "Mata"
      }else if(anat == 'front-eye'){
        return "Mata Depan"
      }else if(anat == 'odontogram'){
        return "Odontogram"
      }else if(anat == 'nervousSystem'){
        return "Syaraf"
      }else if(anat == 'heart'){
        return "Jantung"
      }else if(anat == 'ear'){
        return "Telinga"
      }else if(anat == 'nose'){
        return "Hidung"
      }else if(anat == 'throat'){
        return "Tenggorokkan"
      }else if(anat == 'lung'){
        return "Paru-Paru"
      }else if(anat == 'joint'){
        return "Sendi"
      }else{
        return "Tubuh"
      }
    },

    toDetailSBAR(v){
      let data = {
        type: "detail-sbar",
        id: v.arancp_id
      }
      this.loadingOverlay = true
      Gen.apiRest(
          "/do/"+'RanapCPPT',
          {data: data}, 
          "POST"
      ).then(res=>{
        this.openDetailSBAR = true        
        this.loadingOverlay = false
        this.dataSBAR = res.data
      })
    },
    
    salinData(row){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda yakin untuk menyalin CPPT ini?',
        text: 'Data akan berubah sesuai dengan CPPT yang Anda pilih.',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          let data = {
            type: "salin-riwayat",
            id: this.isParent.row.arancp_id,
            data: row
          }
          this.$swal({
            html: 'Menyalin Data...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(res=> {
            let resp = res.data
            this.isParent.modalCPPTRiwayat = false
            this.isParent.row.arancpd_subjektif = resp.row.arancpd_subjektif
            this.isParent.row.arancpd_objektif = resp.row.arancpd_objektif
            this.isParent.row.arancpd_status_lokalis = resp.row.arancpd_status_lokalis
            this.isParent.row.arancpd_asesmen = resp.row.arancpd_asesmen
            this.isParent.row.arancpd_diagnosa_text = resp.row.arancpd_diagnosa_text
            this.isParent.row.arancpd_tindakan_text = resp.row.arancpd_tindakan_text
            this.isParent.row.arancpd_inform_consent_id = resp.row.arancpd_inform_consent_id
            this.isParent.row.arancpd_perencanaan = resp.row.arancpd_perencanaan

            this.isParent.dataICD10 = resp.dataICD10
            this.isParent.dataICD9 = resp.dataICD9

            this.$nextTick(() => {
              this.$swal().close()
              
              this.$swal({
                title: `Data Berhasil Disalin`,
                icon: 'success',
              })
            })

          }).catch(err => {
            this.loadingOverlay = false
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
      
    },
  },

}
</script>