<template>
  <div class="card shadow-0">
    
    <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
      <b-tab title="Pemeriksaan Radiologi">
        <div class="card-body p-3">
          <table class="table table-bordered mb-3">
            <thead>
              <tr class="table-light">
                <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Prioritas Pemeriksaan</h4>
                    <p v-if="rowData.arantr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                    <p v-else>Non CITO</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pasien Sedang Hamil?</h4>
                    <p>{{rowData.arantr_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                    <p>{{rowData.arantr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row g-3">
            <template v-for="(v,k) in rowData.arantr_data">
              <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">{{
                      getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                  </div>
                  <div class="card-body py-2">
                    <template v-for="v1,k1 in (v.data||[])">
                      <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle font-weight-semibold">{{v1.text||"-"}}
                          <span class="align-middle" v-if="v1.notes">, {{v1.notes}}</span>
                          </span>
                        
                          <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                          <i class="icon-file-eye2"></i>
                          </a>
                      </div>
                    </template>
                    
                  </div>
                </div>
              </div>
            </template>
            <div class="col-md-4 col-lg-3" v-if="rowData.arantr_hasil_lainnya">
              <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                </div>
                <div class="card-body py-2">
                    <div class="req-list">
                        <div>
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle">{{rowData.arantr_hasil_lainnya||"-"}}</span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
          </div>

          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-bordered table-striped table-sm patient-table">
                  <thead>
                    <tr>
                      <th>Hasil Pemeriksaan </th>
                      <th>Hasil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (rowData.arantr_hasil||[])" :key="k+'hasil'">
                      <td>{{v.value}}</td>
                      <td>
                          <div class="result_tab form-group mb-0">
                          <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                          
                          <a class="btn_view ml-2" v-b-tooltip.hover title="Unduh Hasil Bacaan" :href="'javascript:;'" @click="downloadReportBacaanDokter(rowData.arantr_aranr_id,v)"><i class="icon-file-download"></i></a>
                          </div>
                          
                      </td>
                    </tr>
                    <tr v-if="!(rowData.arantr_hasil||[]).length">
                      <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Transfer Intra">
        <div class="form-underlying card-body p-3">
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>S</h2>
                  </div>
                  <strong class="ml-2">Situation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra1">
              <table class="table table-bordered">
                <tr>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>Nama</h4>
                      <p>{{isParent.row.ap_fullname||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{isParent.row.ap_code||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>NIK</h4>
                      <p>{{isParent.row.ap_nik||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Lahir</h4>
                      <p>{{isParent.row.ap_dob | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>DPJP</h4>
                      <p>{{isParent.rowReg.bu_full_name||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jam Datang</h4>
                      <p>{{rowTransferIntra.arantir_jam_datang||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosa</h4>
                      <p>{{rowTransferIntra.arantir_diagnosa||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Kondisi Saat Ini</h4>
                      <p>{{rowTransferIntra.arantir_kondisi_saat_ini||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4></h4>
                      <p></p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Dari Ruang</h4>
                      <p>{{rowTransferIntra.arantir_dari_ruang||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Ke Ruang</h4>
                      <p>{{rowTransferIntra.arantir_ke_ruang||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jam Pindah</h4>
                      <p>{{jamPindah||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>B</h2>
                  </div>
                  <strong class="ml-2">Background</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra2">
              <div class="row">
                <div class="col-md-8">
                <div class="row">
                
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Keluhan Utama</label>
                        <p>{{rowTransferIntra.arantir_keluhan_utama||"-"}}</p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Riwayat Penyakit</label>
                        <p>{{rowTransferIntra.arantir_riwayat_penyakit||"-"}}</p>
                    </div>
                    </div>

                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Intervensi yang Telah Dilakukan</label>
                        <p>{{rowTransferIntra.arantir_background_intervensi||"-"}}</p>
                    </div>  
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                        <p>{{rowTransferIntra.arantir_riwayat_pembedahan||"-"}}</p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Obat-obatan yang Diberikan</label>
                        <p>{{rowTransferIntra.arantir_obat_obatan_yang_diberikan||"-"}}</p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Cairan Parenteral yang Diberikan</label>
                        <p>{{rowTransferIntra.arantir_cairan_parental_yang_diberikan||"-"}}</p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Riwayat Alergi</label>
                        <p>{{rowTransferIntra.arantir_riwayat_alergi||"-"}}</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-md-4">
                <div class="form-group">
                    <label for="">Tindakan Invasif</label>
                    <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                        <th width="48">#</th>
                        <th>Tindakan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (rowTransferIntra.arantir_tindakan_invasif||[])" :key="k">
                        <td>{{k+1}}</td>
                        <td>
                            {{rowTransferIntra.arantir_tindakan_invasif[k]||"-"}}
                        </td>
                        </tr>

                        <tr v-if="!(rowTransferIntra.arantir_tindakan_invasif||[]).length">
                        <td colspan="3" class="text-center">
                            Tidak ada Data
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>A</h2>
                  </div>
                  <strong class="ml-2">Assessment</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra3">
              <div class="card border shadow-0">
                <div class="bg-light card-header py-2">
                  <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Skala Nyeri</h4>
                          <p>{{rowTransferIntra.arantir_skala_nyeri_value||0}} 
                              <span v-if="rowTransferIntra.arantir_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                              <span v-else-if="rowTransferIntra.arantir_skala_nyeri_value >= 1 && rowTransferIntra.arantir_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                              <span v-else-if="rowTransferIntra.arantir_skala_nyeri_value >= 4 && rowTransferIntra.arantir_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                              <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                          <br />(Numeric Rating Scale)</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Tingkat Kesadaran</h4>
                          <p>{{rowTransferIntra.arantir_tingkat_kesadaran_value||0}} 
                              <span v-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                              <span v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 12 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                              <span v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 10 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                              <span v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 7 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                              <span v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 5 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 4 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                              <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                          <br />(GCS)</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Risiko Jatuh</h4>
                          <p>{{rowTransferIntra.arantir_resiko_jatuh_value||0}} 
                              <span v-if="rowTransferIntra.arantir_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                              <span v-else-if="rowTransferIntra.arantir_resiko_jatuh_value >= 7 && rowTransferIntra.arantir_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                              <span v-else-if="rowTransferIntra.arantir_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                          <br />
                            <span v-if="isParent.row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                            <span v-else-if="isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                            <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                          </p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Tekanan Darah</h4>
                          <p>{{rowTransferIntra.arantir_tekanan_darah_min||"-"}}/{{rowTransferIntra.arantir_tekanan_darah_max||"-"}} mmHG</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Nadi</h4>
                          <p>{{rowTransferIntra.arantir_nadi||"-"}}x per menit</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Suhu</h4>
                          <p>{{rowTransferIntra.arantir_suhu||"-"}} &deg;C</p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Respiration Rate</h4>
                          <p>{{rowTransferIntra.arantir_respiration||"-"}}x per menit</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>SpO2</h4>
                          <p>{{rowTransferIntra.arantir_spo2||"-"}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4></h4>
                          <p></p>
                          </div>
                      </td>
                      </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="">Radiologi</label>
                    <p>{{rowTransferIntra.arantir_radiologi||"-"}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>R</h2>
                  </div>
                  <strong class="ml-2">Recommendation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra4">
              <div class="row">
                <div class="col-md-6">
                <div class="form-group">
                    <label for="">Tindakan yang Perlu Dilanjutkan</label>
                    <p>{{rowTransferIntra.arantir_tindakan||"-"}}</p>
                </div>
                </div>
                <div class="col-md-6">
                <div class="form-group">
                    <label for="">Usulan Kolaborasi</label>
                    <p>{{rowTransferIntra.arantir_usulan_kolab||"-"}}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan</h4>
                  <p>{{rowIC.tindakan}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.aranic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.aranic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.aranic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.aranic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.aranic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.aranic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.aranic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.aranic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},
      openModalICDetail: false,
      rowIC: {},
      rowTransferIntra: {},
      mrValidationTransferIntra: {}
    }
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  mounted() {
    this.apiGetForm()
  },
  methods: {
    
    downloadReportBacaanDokter(id,v){
      let data = {exptype: 'xlsx', type: "export", id: id, row: v, pasienId: this.isParent.row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadReportBacaanradiologiDataRanap'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false

              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-Hasil-Bacaan-Dokter-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false

              alert('Not downloaded')
          }
      })
    },
    apiGetForm(params = {}, page = 1){
      this.loadingOverlay = true
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = 'RanapPasienTindakanRadiologi'+'/'+this.isParent.rowReg.aranr_id+'/'+this.isParent.rowEdit.arantr_id      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          console.log(this.rowData)
      }).catch(()=>{
          this.loadingOverlay = false
      })

      Gen.apiRest(
        "/get/"+url+'?master=1', 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      })
    },

    selectedRadioInput(){
      let data = []
      for(let i = 0; i < (this.rowData.arantr_data||[]).length; i++){
        for(let j = 0; j < (this.rowData.arantr_data[i]['data']||[]).length; j++){  
            if(this.rowData.arantr_data[i]['data'][j]['selected']){
              data.push(this.rowData.arantr_data[i]['data'][j]['text'])
            }
        }
      }
      if(this.rowData.arantr_hasil_lainnya){
        data.push(this.rowData.arantr_hasil_lainnya)
      }
      return data.join(", ")
    },
    
    isShowHeadRadiologi(j){
      let isData = 0
      for(let k = 0; k < (this.rowData.arantr_data[j]['data']||[]).length; k++){  
        if(this.rowData.arantr_data[j]['data'][k]['selected']){
          isData += 1
        }
      }
      return isData
    },
    
    isShowRadiologi(j,k){
      let isData = 0
      if(this.rowData.arantr_data[j]['data'][k]['selected']){
        isData += 1
      }
      return isData
    },    
    
    getConfigDynamic(master,value){
      let text = ''
      if(value){
        let index = (master||[]).findIndex(x => x.value == value)
        if(index !== -1){
            text = master[index]['text']
        }
      }
      return text
    },

    
        
    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapPasienTindakanRadiologi', {
            data: {
                type: 'get-data-ic-single',
                id: v.inform_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true               
        })
    },

  }
  
}

</script>