<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Ceklis Keamanan Anestesi</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive mb-2">
            <table class="table table-bordered mt-2">
              <tbody>
                <tr>
                  <td class="table-info">
                    Listrik
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_listrik||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_listrik||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td class="table-info">
                    Gas Medis
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_gas_medis||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_gas_medis||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td class="table-info">
                    Mesin Anestesi
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_mesin_anestesi||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_mesin_anestesi||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>


              <tbody>
                <tr>
                  <td class="table-info">Manajemen Jalan Nafas</td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_manajemen_jalan_nafas||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_manajemen_jalan_nafas||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td class="table-info">Pemantauan</td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_pemantauan||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_pemantauan||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td class="table-info">Lain-lain</td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_lainnya||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_lainnya||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td class="table-info">Obat-obat</td>
                </tr>
                <tr>
                  <td>
                    <ul v-if="(dataCeklistKeselamatanAnestesi.aranca_obat||[]).length">
                      <li v-for="(v,k) in (dataCeklistKeselamatanAnestesi.aranca_obat||[])" :key="k">
                        {{v||"-"}}
                      </li>
                    </ul>
                    <span v-else>Tidak ada data</span>
                  </td>
                </tr>
              </tbody>


            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  computed: {
    dataCeklistKeselamatanAnestesi(){
      return this.$parent.dataOperasi.dataCeklistKeselamatanAnestesi
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
  }
}

</script>