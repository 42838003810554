<template>
<div class="dokterTindakLanjut card">
  <div class="card-header bg-info">
    <h6 class="card-title font-weight-semibold">Selesai Perawatan</h6>
  </div>
  <div class="card-body p-3">
    <div>
      <b-form-group>
        <b-form-radio-group v-model="isParent.rowSP.type" :options="isParent.Config.mr.selesaiPerawatan" name="radio-options"></b-form-radio-group>
        <VValidate :name="`Tindak Lanjut`" v-model="isParent.rowSP.type" :rules="{required: 1}" />
      </b-form-group>
    </div>
  </div>
  <div class="soapForm"></div>
  <div class="soapForm"></div>
  <div class="card-body soapForm" v-if="isParent.rowSP.type.slugify() == 'meninggal'">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label for="stepNotes">Tanggal Meninggal <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <div class="input-group">
                  <datepicker input-class="form-control transparent" placeholder="Tanggal Meninggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.rowSP.date_of_death">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate :name="`Tanggal Meninggal`" v-model="isParent.rowSP.date_of_death" :rules="{required: 1}" />
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group>
              <label>Waktu Meninggal<span class="text-danger">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.rowSP.time_of_death">
                </vue-timepicker>
              </div>

              <VValidate :name="`Waktu Meninggal`" :value="
                  Object.keys(isParent.rowSP.time_of_death||{}).length ||
                  (
                    Object.keys(isParent.rowSP.time_of_death||{}).length && 
                    isParent.rowSP.meniggal_time.HH &&
                    isParent.rowSP.time_of_death.mm
                  ) ? true : null" :rules="{required: 1}" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body soapForm" v-if="isParent.rowSP.type.slugify() == 'rujuk'">
    <div class="row">
      <div class="col-md-6 col-xl-4">
        <div class="form-group mb-2">
          <label>Alasan Merujuk<small class="txt_mandatory">*</small></label>
          <div class="clearfix"></div>
          <v-select placeholder="Alasan Rujuk" v-model="isParent.rowSP.alasan_rujuk" :options="Config.mr.alasanRujuk" label="text" :clearable="true" :reduce="v=>v.value">
          </v-select>
          <VValidate :name="'Alasan Rujuk'" v-model="isParent.rowSP.alasan_rujuk" :rules="{required : 1}" />
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="form-group" v-if="isParent.rowSP.alasan_rujuk == 'Klinis'">
          <label>Klinis<small class="txt_mandatory">*</small></label>
          <textarea name="alasanKlinis" id="alasanKlinis" rows="2" class="form-control" v-model="isParent.rowSP.alasan_rujuk_text"></textarea>

          <VValidate name="Klinis" v-model="isParent.rowSP.alasan_rujuk_text" :rules="{ required: 1 }" />
        </div>
        <div class="form-group" v-else-if="isParent.rowSP.alasan_rujuk == 'Non Klinis'">
          <label>Non Klinis<small class="txt_mandatory">*</small></label>
          <textarea name="alasanNonKlinis" id="alasanNonKlinis" rows="2" class="form-control" v-model="isParent.rowSP.alasan_rujuk_text"></textarea>

          <VValidate name="Non Klinis" v-model="isParent.rowSP.alasan_rujuk_text" :rules="{ required: 1 }" />
        </div>
        <div class="form-group" v-else>
          <label>Lain-lain<small class="txt_mandatory">*</small></label>
          <textarea name="alasanLain" id="alasanLain" rows="2" class="form-control" v-model="isParent.rowSP.alasan_rujuk_text"></textarea>

          <VValidate name="Lain-lain" v-model="isParent.rowSP.alasan_rujuk_text" :rules="{ required: 1 }" />
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-4">
        <div class="mb-3">
          <label>Pilih Faskes Rujukan<small class="txt_mandatory">*</small></label>
          <!--
          <b-form-input type="text" v-model="isParent.rowSP.faskes_rujuk_sisrute_text" placeholder="Nama Faskes Rujukan" />
          -->

          <!-- <v-select placeholder="Pilih Faskes Rujukan" @input="selectPoliRujukan($event)" v-model="isParent.rowSP.faskes_rujuk" :options="isParent.mFaskes" label="text" :clearable="true" :reduce="v=>v.value"></v-select> -->

          <v-select class="diagnosa-section select-paging" placeholder="Cari Nama Faskes" v-model="isParent.rowSP.faskes_rujuk_sisrute" @input="inpMasterFaskesSisrute($event)" @search="getMasterFaskesSisrute($event)" :options="mFaskesSisrute" label="NAMA" value="KODE" :clearable="true" :reduce="v=>v.KODE">
            <template slot="no-options">
              <span></span>
            </template>
          </v-select>
          
          <!-- <VValidate :name="'Faskes'" v-model="isParent.rowSP.faskes_rujuk_sisrute" :rules="{required : 1}" /> -->
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label>Pilih Poli</label>
          <div class="clearfix"></div>
          <v-select placeholder="Pilih Poli" v-model="isParent.rowSP.poli" :options="isParent.mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

          <!-- <VValidate :name="`Poli`" v-model="isParent.rowSP.poli" :rules="{required: 1}" /> -->
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label for="rujukNamaDokter">Nama Dokter<small class="txt_mandatory">*</small></label>
          <b-form-input type="text" v-model="isParent.rowSP.dokter" placeholder="Nama Dokter" />
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Peralatan Medis yang Terpasang<small class="txt_mandatory">*</small></label>
                <b-form-radio-group :options="Config.mr.yesNoOptV2" v-model="isParent.rowSP.peralatan_is_terpasang" />

                <VValidate :name="'Peralatan Medis yang Terpasang'" v-model="isParent.rowSP.peralatan_is_terpasang" :rules="{required : 1}" />
              </div>

              <div class="form-group col-md-5 mt-3" v-if="isParent.rowSP.peralatan_is_terpasang == 'Y'">
                <v-select multiple class="vs__multiple" placeholder="Pilih Media Terpasang" v-model="isParent.rowSP.peralatan_yang_terpasang" :options="Config.mr.mediaTerpasang" label="text" :reduce="v=>v.value">
                </v-select>

                <VValidate :name="'Pilih Media Terpasang'" v-model="isParent.rowSP.peralatan_yang_terpasang" :rules="{required : 1}" />
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label> Perawatan Pasien yang dibutuhkan</label>
          <textarea rows="3" cols="3" class="form-control" placeholder="" spellcheck="false" v-model="isParent.rowSP.perawatan_yang_diperlukan"></textarea>
          <!--
          <VValidate name="Perawatan Pasien yang dibutuhkan" v-model="isParent.rowSP.perawatan_yang_diperlukan" :rules="{ required: 1 }" />
          -->
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
// import { stringSimilarity } from "string-similarity-js"

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  components: {
    VueTimepicker,
    Datepicker
  },
  data() {
    return {
      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,

      openFaskes: false,
      listFaskesBPJS: [],
      mFaskesSisrute: [],
      faskesSearch: null
    }
  },
  computed: {
    isParent() {
      return this.$parent.$parent
    },
    selectedFaskesInput() {
      let data = []
      for (let i = 0; i < (this.listFaskesBPJS || []).length; i++) {
        if (this.listFaskesBPJS[i]['selected']) {
          data.push(this.listFaskesBPJS[i])
        }
      }
      return data
    },
  },
  mounted(){
    setTimeout(() => {     
      this.getMasterFaskesSisrute(this.isParent.rowSP.faskes_rujuk_sisrute||"A")
    }, 1000)
  },
  methods: {
    getMasterFaskesSisrute: _.debounce(function (e) {
      if (e) {
        let data = {
          search: e,
          type: 'select-faskes-sisrute'
        }
        Gen.apiRest(
          "/do/" + 'RanapCPPTDokter', {
            data: data
          }, "POST"
        ).then(res => {
          this.mFaskesSisrute = res.data
        })
      }
    }, 1000),

    inpMasterFaskesSisrute(e) {
      if (e) {
        let idx = this.mFaskesSisrute.findIndex(x => x.KODE == e)
        this.isParent.rowSP.faskes_rujuk_sisrute = this.mFaskesSisrute[idx]['KODE']
        this.isParent.rowSP.faskes_rujuk_sisrute_text = this.mFaskesSisrute[idx]['NAMA']
      }
    },
    selectPoliRujukan(e) {
      let data = {
        type: 'get-by-poli-fakes',
        id: e
      }
      Gen.apiRest(
        "/do/" + 'RekamMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.mPoliRujukan = res.data.mPoliRujukan
      })
    },
    searchFaskesData() {
      this.$parent.loadingOverlay = true
      let data = {
        search: this.faskesSearch,
        type: 'search-faskes-data'
      }
      Gen.apiRest(
        "/do/" + 'RekamMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.$parent.loadingOverlay = false
        this.listFaskesBPJS = res.data
      })
    },
  },

}
</script>
