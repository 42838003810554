<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Pemantauan Durante Operasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Penempatan Pasien </h4>
                      <p>{{dataDurante.arando_penempatan_pasien||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Pengaturan Posisi Operasi</h4>
                      <p v-if="dataDurante.arando_posisi_operasi != 'Lain-Lain'">{{dataDurante.arando_posisi_operasi||"-"}}</p>
                      <p v-else>{{dataDurante.arando_posisi_operasi_text||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table  table-sm mt-2  table-bordered">
              <thead>
                <tr>
                  <th colspan="3">Preparasi Operasi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="req-list">
                      <div v-for="(v,k) in dataDurante.arando_prep_operasi" :key="k">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">{{v}}
                        </span>
                      </div>
                      <span v-if="!(dataDurante.arando_prep_operasi||[]).length">Tidak ada Data</span>
                    </div>
                  </td>

                  <td>
                    <div class="req-list">
                      <h6>Desinfektan:</h6>
                      <template v-if="dataDurante.arando_prep_is_desinfektan == 'Y'">
                        <div v-for="(v,k) in dataDurante.arando_prep_desinfektan" :key="k">
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle">{{v}}
                          </span>
                        </div>
                        <span v-if="!(dataDurante.arando_prep_desinfektan||[]).length">Tidak ada Data</span>
                      </template>
                      <span v-else>Tidak ada Data</span>
                    </div>
                  </td>

                  <td>
                    <div class="req-list">
                      <h6>Penutup Luka:</h6>
                      <template v-if="dataDurante.arando_prep_is_penutup_luka == 'Y'">
                        <div v-for="(v,k) in dataDurante.arando_prep_penutup_luka" :key="k">
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle">{{v}}
                          </span>
                        </div>
                        <span v-if="!(dataDurante.arando_prep_penutup_luka||[]).length">Tidak ada Data</span>
                      </template>
                      <span v-else>Tidak ada Data</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="req-list">
                      <h6>Penyeritilan instrument
                        menggunakan:</h6>
                      <template v-if="dataDurante.arando_is_prep_penyeritan == 'Y'">
                        <div v-for="(v,k) in dataDurante.arando_prep_penyeritan" :key="k">
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle">{{v}}
                          </span>
                        </div>
                        <span v-if="!(dataDurante.arando_prep_penyeritan||[]).length">Tidak ada Data</span>
                      </template>
                      <span v-else>Tidak ada Data</span>
                    </div>
                  </td>
                  <td>
                    <div class="req-list">
                      <h6>Klasifikasi:</h6>
                      
                      <template v-if="dataDurante.arando_is_prep_klarifikasi == 'Y'">
                        <div v-for="(v,k) in dataDurante.arando_prep_klarifikasi" :key="k">
                          <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <span class="align-middle">{{v}}
                          </span>
                        </div>
                        <span v-if="!(dataDurante.arando_prep_klarifikasi||[]).length">Tidak ada Data</span>
                      </template>
                      <span v-else>Tidak ada Data</span>

                    </div>
                  </td>

                  <td>
                    <div class="result_tab">
                      <h4>Perhitungan Instrument Dasar </h4>
                      <p>{{dataDurante.arando_prep_instrumen_dasar||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table  table-sm mt-2  table-bordered">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Pemakaian Alat</th>
                  <th>Aksi</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> 1</td>
                  <td> ET</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_et_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_et_aksi == 'Y'">
                    <div class="result_tab mt-2">
                      <h4>Tanggal</h4>
                      <p>{{dataDurante.arando_et_tanggal |  moment("DD MMMM YYYY")}}
                        {{dataDurante.arando_et_jam}}
                      </p>
                    </div>
                    <div v-for="(v,k) in dataDurante.arando_et_ya" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">{{v}} 
                      <span v-if="v == 'No. ET'">
                        {{dataDurante.arando_et_ya_text||"-"}}
                      </span>
                      </span>
                    </div>
                    <span v-if="!(dataDurante.arando_et_ya||[]).length">Tidak ada Data</span>
                  </td>
                  <td v-else> - </td>
                </tr>
                <tr>
                  <td> 2</td>
                  <td>Diatermi</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_diatermi_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_diatermi_aksi == 'Y'">
                    <div v-for="(v,k) in dataDurante.arando_diatermi_ya" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">{{v}} 
                      </span>                      
                    </div>
                    <span v-if="!(dataDurante.arando_diatermi_ya||[]).length">Tidak ada Data</span>
                    <template v-if="dataDurante.arando_diatermi_lokasi == 'Y'">
                      <span>Lokasi : </span>
                      <div v-for="(v,k) in dataDurante.arando_diatermi_lokasi_data" :key="k">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">{{v}} 
                        </span>                      
                      </div>
                    </template>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td> 3</td>
                  <td>Section Pump</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_section_pump_aksi)||"-"}}</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td> 4</td>
                  <td>Infus</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_infus_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_infus_aksi == 'Y'">
                    <div class="req-list" v-if="dataDurante.arando_is_needle_no == 'Y'">
                      <h6>Needle No:</h6>
                      <div>
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">
                          {{dataDurante.arando_needle_no||"-"}}
                        </span>
                      </div>
                    </div>

                    <div class="req-list">
                      <h6>Jenis Cairan: {{ dataDurante.arando_is_cairan == 'Y' ? 'Ya' : 'Tidak'}}</h6>
                      <span classs="align-middle">{{dataDurante.arando_jumlah_total||"-"}}</span>
                      <!--
                      <div v-if="dataDurante.arando_is_kolaid =='Y'">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">
                          Koloid (Albumin 5 % )
                        </span>
                      </div>

                      <div v-if="dataDurante.arando_is_kristaloid == 'Y'">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">
                          Kristaloid (Rl , NaCI, Dekstrose 5%)
                        </span>
                      </div>

                      <div v-if="dataDurante.arando_is_jumlah_total == 'Y'">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span class="align-middle">
                          Jumlah total :: {{dataDurante.arando_jumlah_total||"-"}} cc
                        </span>
                      </div>

                      <div class="result_tab mt-2" v-if="dataDurante.arando_is_tanggal == 'Y'">
                        <h4>Tanggal</h4>
                        <p>{{dataDurante.arando_tanggal |  moment("DD MMMM YYYY")}}
                          {{dataDurante.arando_jam}}
                        </p>
                      </div>
                      -->

                    </div>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Transfusi Darah</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_transfusi_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_transfusi_aksi == 'Y'">
                    <table class="table  table-sm mt-2  table-bordered">
                      <thead>
                        <tr>
                          <th>Keterangan</th>
                          <th>Kal</th>
                          <th>Masuk Jam</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="dataDurante.arando_is_informed_consent == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Informed Consent Tranfusi Darah
                              </span>
                            </div>
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        
                        <tr v-if="dataDurante.arando_whole_blood_is == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Whole Blood
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_whole_blood_kolf||"-"}}</td>
                          <td>{{dataDurante.arando_whole_blood_masuk_jam||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_packaged_cell_is == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Packed Cell DTC
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_packaged_cell_kolf||"-"}}</td>
                          <td>{{dataDurante.arando_packaged_cell_masuk_jam||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_packaged_ffp_is == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                FFP
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_packaged_ffp_kolf||"-"}}</td>
                          <td>{{dataDurante.arando_packaged_ffp_masuk_jam||"-"}}</td>
                        </tr>
                        <tr v-if="dataDurante.arando_packaged_tc_is == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                TC
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_packaged_tc_kolf||"-"}}</td>
                          <td>{{dataDurante.arando_packaged_tc_masuk_jam||"-"}}</td>
                        </tr>
                        <tr v-if="dataDurante.arando_ceklist_transfusi == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Checklist Transfusi
                              </span>
                            </div>
                          </td>
                          <td>-</td>
                          <td> - </td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                  <td v-else> - </td>
                </tr>


                <tr>
                  <td>6</td>
                  <td>DC</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_dc_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_dc_aksi == 'Y'">
                    
                    <div class="result_tab mt-2" v-if="dataDurante.arando_is_tanggal == 'Y'">
                      <h4>Tanggal</h4>
                      <p>{{dataDurante.arando_dc_tanggal |  moment("DD MMMM YYYY")}}
                        {{dataDurante.arando_dc_jam}}
                      </p>
                    </div>

                    <table class="table  table-sm mt-2  table-bordered">
                      <thead>
                        <tr>
                          <th>Keterangan</th>
                          <th>Kal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="dataDurante.arando_dc_no_foley_cateter == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                No Foley Cateter
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_dc_no_foley_cateter_text ||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_dc_jenis_cateter == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Jenis Cateter
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_dc_jenis_cateter_text ||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_dc_jumlah_urine == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Jumlah Urine
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_dc_jumlah_urine_text||"-"}}</td>
                        </tr>
                        <tr v-if="dataDurante.arando_dc_warna_urine == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Warna Urine
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_dc_warna_urine_text||"-"}}</td>
                        </tr>
                        <tr v-if="dataDurante.arando_dc_balon == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Balon
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_dc_balon_text||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_dc_kesulitan_pemasangan == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Kesulitan Pemasangan
                              </span>
                            </div>
                          </td>
                          <td>-</td>
                        </tr>

                        <tr v-if="dataDurante.arando_dc_urine_bag == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Urine bag
                              </span>
                            </div>
                          </td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Troniquest</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_tq_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_tq_aksi == 'Y'">
                    <table class="table  table-sm mt-2  table-bordered">
                      <thead>
                        <tr>
                          <th>Keterangan</th>
                          <th>Jam Masuk</th>
                          <th>Jam Keluar</th>
                          <th>Tekanan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="dataDurante.arando_tq_lengan_kanan == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Lengan Kanan
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_tq_lengan_kanan_jam_mulai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_lengan_kanan_jam_selesai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_lengan_kanan_tekanan||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_tq_lengan_kiri == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Lengan Kiri
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_tq_lengan_kiri_jam_mulai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_lengan_kiri_jam_selesai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_lengan_kiri_tekanan||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_tq_paha_kanan == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Paha Kanan
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_tq_paha_kanan_jam_mulai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_paha_kanan_jam_selesai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_paha_kanan_tekanan||"-"}}</td>
                        </tr>

                        <tr v-if="dataDurante.arando_tq_paha_kiri == 'Y'">
                          <td>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                Paha Kiri
                              </span>
                            </div>
                          </td>
                          <td>{{dataDurante.arando_tq_paha_kiri_jam_mulai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_paha_kiri_jam_selesai||"-"}}</td>
                          <td>{{dataDurante.arando_tq_paha_kiri_tekanan||"-"}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Drain</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_drain_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_drain_aksi == 'Y'">
                    <div v-for="(v,k) in (dataDurante.arando_drain||[])" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        {{v}}
                      </span>
                    </div>
                    <span>{{dataDurante.arando_drain_text||"-"}}</span>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>Irigasi Luka</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_irigasi_luka_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_irigasi_luka_aksi == 'Y'">
                    <div v-for="(v,k) in (dataDurante.arando_irigasi||[])" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        {{v}}
                      </span>
                    </div>
                    <span>{{dataDurante.arando_irigasi_lainnya||"-"}}</span>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Pemakaian cairan irigasi</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_cairan_irigasi_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_cairan_irigasi_aksi == 'Y'">
                    <div v-for="(v,k) in (dataDurante.arando_cairan_irigasi_data||[])" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        {{v}}
                      </span>
                    </div>
                    <span>{{dataDurante.arando_cairan_irigasi_text||"-"}}</span>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>Balutan</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_balutan_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_balutan_aksi == 'Y'">
                    <div v-for="(v,k) in (dataDurante.arando_balutan_data||[])" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        {{v}}
                      </span>
                    </div>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Specimen untuk Pemeriksaan </td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_specimen_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_specimen_aksi == 'Y'">
                    <div v-for="(v,k) in (dataDurante.arando_specimen_data||[])" :key="k">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        {{v}}
                      </span>
                    </div>
                    <span>{{dataDurante.arando_specimen_text||"-"}}</span>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>Specimen untuk dibawa pasien</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_specimen_pasien_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_specimen_pasien_aksi == 'Y'">
                    <div v-if="dataDurante.arando_specimen_pasien_total == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        Jumlah total jaringan / cairan {{dataDurante.arando_specimen_pasien_total_value||"-"}}
                      </span>
                    </div>
                    
                    <div v-if="dataDurante.arando_specimen_pasien_jaringan == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                       Jenis dari jaringan {{dataDurante.arando_specimen_pasien_jaringan_value||"-"}}
                      </span>
                    </div>

                    <div v-if="dataDurante.arando_specimen_pasien_keterangan == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                       Keterangan : {{dataDurante.arando_specimen_pasien_keterangan_value||"-"}}
                      </span>
                    </div>
                  </td>
                  <td v-else> - </td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Specimen untuk dibuang sebagai limbah/ dihancurkan</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,dataDurante.arando_specimen_buangan_aksi)||"-"}}</td>
                  <td v-if="dataDurante.arando_specimen_buangan_aksi == 'Y'">
                    <div v-if="dataDurante.arando_specimen_buangan_total == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                        Jumlah total jaringan / cairan {{dataDurante.arando_specimen_buangan_total_value||"-"}}
                      </span>
                    </div>
                    
                    <div v-if="dataDurante.arando_specimen_buangan_jaringan == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                       Jenis dari jaringan {{dataDurante.arando_specimen_buangan_jaringan_value||"-"}}
                      </span>
                    </div>

                    <div v-if="dataDurante.arando_specimen_buangan_keterangan == 'Y'">
                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <span class="align-middle">
                       Keterangan : {{dataDurante.arando_specimen_buangan_keterangan_value||"-"}}
                      </span>
                    </div>
                  </td>
                  <td v-else> - </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                    <td>
                    <div class="result_tab">
                        <h4>Tekanan Darah</h4>
                        <p v-if="dataDurante.arando_ttv_tekanan_darah_min || dataDurante.arando_ttv_tekanan_darah_max">{{dataDurante.arando_ttv_tekanan_darah_min}} / {{dataDurante.arando_ttv_tekanan_darah_max}} mmHG</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>Nadi</h4>
                        <p v-if="dataDurante.arando_ttv_nadi">{{dataDurante.arando_ttv_nadi||"-"}}/mnt</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>Gula Darah</h4>
                        <p v-if="dataDurante.arando_ttv_gula_darah">{{dataDurante.arando_ttv_gula_darah||"-"}}mg/dL</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                    <div class="result_tab">
                        <h4>Pernafasan</h4>
                        <p v-if="dataDurante.arando_ttv_pernafasan">{{dataDurante.arando_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>SPO2</h4>
                        <p v-if="dataDurante.arando_ttv_spo2">{{dataDurante.arando_ttv_spo2||"-"}}%</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>Suhu</h4>
                        <p v-if="dataDurante.arando_ttv_suhu">{{dataDurante.arando_ttv_suhu||"-"}}C</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                    <div class="result_tab">
                        <h4>Berat Badan</h4>
                        <p v-if="dataDurante.arando_ttv_weight">{{dataDurante.arando_ttv_weight||"-"}}kg</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>Tinggi</h4>
                        <p v-if="dataDurante.arando_ttv_height">{{dataDurante.arando_ttv_height||"-"}}Cm</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td>
                    <div class="result_tab">
                        <h4>Lingkar Kepala</h4>
                        <p v-if="dataDurante.arando_ttv_lingkar_kepala">{{dataDurante.arando_ttv_lingkar_kepala||"-"}}cm</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                      <div class="result_tab">
                          <h4>Ukuran Pupil</h4>
                          <p v-if="dataDurante.arando_ttv_ukuran_pupil">{{dataDurante.arando_ttv_ukuran_pupil||"-"}}mm/Ki</p>
                          <p v-else> - </p>
                      </div>
                    </td>
                    <td v-if="dataPasien.ap_usia >= 1">
                    <div class="result_tab">
                        <h4>BMI</h4>
                        <p v-if="dataDurante.arando_ttv_bmi">{{dataDurante.arando_ttv_bmi}}m2</p>
                        <p v-else> - </p>
                    </div>
                    </td>
                    <td v-if="dataPasien.ap_usia <= 15">
                        <div class="result_tab">
                        <h4>Luas Permukaan Tubuh Anak</h4>
                        <p v-if="dataDurante.arando_ttv_luas_permukaan_anak">{{dataDurante.arando_ttv_luas_permukaan_anak}}kg/m2</p>
                        <p v-else> - </p>
                        </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="row">
          <!-- Skala Nyeri jika pasien berusia lebih dari sama dengan 6 tahun -->
          <div class="col-12" v-if="dataPasien.ap_usia > 6">
            <div class="row align-items-center">
              <div class="col-md">
                <div>
                  <label for="">Skala Nyeri</label>
                    <div class="pain-scale no-pain" v-if="dataDurante.arando_skala_nyeri_value == 0">
                      <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                      <strong>Tidak Ada Nyeri</strong>
                    </div>
                    <div class="pain-scale mild-pain" v-if="dataDurante.arando_skala_nyeri_value >= 1 && dataDurante.arando_skala_nyeri_value <= 3">
                        <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Sedikit Nyeri</strong>
                    </div>
                    <div class="pain-scale moderate-pain" v-if="dataDurante.arando_skala_nyeri_value >= 4 && dataDurante.arando_skala_nyeri_value <= 5">
                        <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Agak Mengganggu</strong>
                    </div>
                    <div class="pain-scale severe-pain" v-if="dataDurante.arando_skala_nyeri_value >= 6 && dataDurante.arando_skala_nyeri_value <= 7">
                        <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Mengganggu Aktivitas</strong>
                    </div>
                    <div class="pain-scale worst-pain" v-if="dataDurante.arando_skala_nyeri_value >= 8 && dataDurante.arando_skala_nyeri_value <= 9">
                        <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Sangat Mengganggu</strong>
                    </div>
                    <div class="pain-scale worst-pain" v-if="dataDurante.arando_skala_nyeri_value >= 10">
                        <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Tak Tertahankan</strong>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
          <div class="col-12" v-else>
            <div class="form-group">
              <label for="kidPainScale">Skala Nyeri<strong class="text-danger">*</strong></label>
              <table class="table table-bordered pain-scale-table">
                <thead>
                  <tr>
                    <th>Pengkajian</th>
                    <th>Hasil</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in dataDurante.arando_skala_nyeri" :key="k+'akn'">
                    <th class="table-warning text-center"><img :src="assetLocal(v.img)"
                      style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                      {{v.name}}</th>
                    <td>
                      {{getConfigDynamic(v.configVal,v.value)||"-"}}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="table-info">
                    <td colspan="4" class="text-info-700">
                        <span>NILAI SKALA NYERI: {{dataDurante.arando_skala_nyeri_value||"-"}}</span>
                        <span v-if="dataDurante.arando_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                        <span v-else-if="dataDurante.arando_skala_nyeri_value >= 1 && dataDurante.arando_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                        <span v-else-if="dataDurante.arando_skala_nyeri_value >= 4 && dataDurante.arando_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  computed: {
    dataOperasi(){
      return this.$parent.dataOperasi.dataOperasi
    },
    dataDurante(){
      return this.$parent.dataOperasi.dataDurante
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
  }
}

</script>