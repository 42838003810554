<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Rencana Pembedahan</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>Tanggal Operasi </h4>
                      <p>{{dataOperasi.arano_jadwal_operasi | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>Dokter Operator</h4>
                      <p>{{dataOperasi.dokter_operator||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>Rencana Tindakan</h4>
                      <p>{{dataPraInduksi.aranpio_rencana_tindakan||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosa Awal</h4>
                      <p>{{dataOperasi.diagnosa||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Dokter Anestesi</h4>
                      <p>{{dataOperasi.dokter_anestesi||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Sifat Operasi</h4>
                      <p>{{dataPraInduksi.aranpio_sifat_operasi||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tekanan Darah</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_tekanan_darah_min && dataPraInduksi.aranpio_ttv_tekanan_darah_max">{{dataPraInduksi.aranpio_ttv_tekanan_darah_min||"-"}}/
                      {{dataPraInduksi.aranpio_ttv_tekanan_darah_max||"-"}} mmHG</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nadi</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_nadi">{{dataPraInduksi.aranpio_ttv_nadi||"-"}}x/mnt</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Pernafasan</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_pernafasan"> {{dataPraInduksi.aranpio_ttv_pernafasan}}/mnt - Reguler</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>

                  <td>
                    <div class="result_tab">
                      <h4>SPO2</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_spo2">{{dataPraInduksi.aranpio_ttv_spo2}}%</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Suhu</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_suhu">{{dataPraInduksi.aranpio_ttv_suhu||"-"}}C</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Berat Badan</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_weight">{{dataPraInduksi.aranpio_ttv_weight||"-"}}kg</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>

                  <td>
                    <div class="result_tab">
                      <h4>Tinggi Badan</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_height">{{dataPraInduksi.aranpio_ttv_height||"-"}}Cm</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Status Mental</h4>
                      <p v-if="dataPraInduksi.aranpio_ttv_status_mental">{{dataPraInduksi.aranpio_ttv_status_mental||"-"}}Cm</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>ASA</h4>
                      <p>{{getConfigDynamic(Config.mr.mAsaPraInduksi,dataPraInduksi.aranpio_ttv_asa)||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Riwayat Penyakit Keluarga & Anestesi</h6>
        </div>
        <div class="card-body p-3">
          <div class="req-list">
            <div v-for="(v,k) in (dataPraInduksi.aranpio_riwayat||[])" :key="k">
              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
              <span class="align-middle" v-if="v == 'Lainnya'">
                {{dataPraInduksi.aranpio_riwayat_lainnya||"-"}}
              </span>
              <span class="align-middle" v-else>
                {{v||"-"}}
              </span>
            </div>
            <span v-if="!(dataPraInduksi.aranpio_riwayat||[]).length">Tidak ada Riwayat</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 mb-2">
      <div class="card">
        <div class="card-header bg_head_panel" @click="keadaanUmum = !keadaanUmum">
        <h6 class="card-title font-weight-semibold" 
        >Keadaan Umum</h6>
        </div>
        <div class="card-body p-3" v-if="keadaanUmum">
        <table class="table table-bordered">
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Kepala</h4>
                <p v-if="dataPraInduksi.aranpio_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_kepala == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_kepala_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Mata</h4>
                <p v-if="dataPraInduksi.aranpio_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_mata == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_mata_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Hidung</h4>
                <p v-if="dataPraInduksi.aranpio_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_hidung == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_hidung_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Rambut</h4>
                <p v-if="dataPraInduksi.aranpio_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_rambut == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_rambut_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Bibir</h4>
                <p v-if="dataPraInduksi.aranpio_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_bibir == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_bibir_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Gigi Geligi</h4>
                <p v-if="dataPraInduksi.aranpio_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_gigi == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_gigi_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Lidah</h4>
                <p v-if="dataPraInduksi.aranpio_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_lidah == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_lidah_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Langit-Langit</h4>
                <p v-if="dataPraInduksi.aranpio_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_langit == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_langit_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Leher</h4>
                <p v-if="dataPraInduksi.aranpio_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_leher == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_leher_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Tenggorokan</h4>
                <p v-if="dataPraInduksi.aranpio_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_tenggorokan_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Tonsil</h4>
                <p v-if="dataPraInduksi.aranpio_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_tonsil_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Dada</h4>
                <p v-if="dataPraInduksi.aranpio_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_dada == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_dada_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Payudara</h4>
                <p v-if="dataPraInduksi.aranpio_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_payudara == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_payudara_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Punggung</h4>
                <p v-if="dataPraInduksi.aranpio_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_punggung == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_punggung_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Perut</h4>
                <p v-if="dataPraInduksi.aranpio_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_perut == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_perut_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Genital</h4>
                <p v-if="dataPraInduksi.aranpio_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_genital == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_genital_text||"-"}}</p>
                </div>
            </td>
            
            <td width="33%">
                <div class="result_tab">
                <h4>Anus</h4>
                <p v-if="dataPraInduksi.aranpio_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_anus == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_anus_text||"-"}}</p>
                </div>
            </td>
            
            <td width="33%">
                <div class="result_tab">
                <h4>Lengan Atas</h4>
                <p v-if="dataPraInduksi.aranpio_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_lengan_atas_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Lengan Bawah</h4>
                <p v-if="dataPraInduksi.aranpio_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_lengan_bawah_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Jari Tangan</h4>
                <p v-if="dataPraInduksi.aranpio_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_jari_tangan_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Kuku Tangan</h4>
                <p v-if="dataPraInduksi.aranpio_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_kuku == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_kuku_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Sendi Tangan</h4>
                <p v-if="dataPraInduksi.aranpio_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_sendi_tangan_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Tungkai Atas</h4>
                <p v-if="dataPraInduksi.aranpio_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_tungkai_atas_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Tungkai Bawah</h4>
                <p v-if="dataPraInduksi.aranpio_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_tungkai_bawah_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <td width="33%">
                <div class="result_tab">
                <h4>Jari Kaki</h4>
                <p v-if="dataPraInduksi.aranpio_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_jari_kaki_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Kuku Kaki</h4>
                <p v-if="dataPraInduksi.aranpio_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_kuku_kaki_text||"-"}}</p>
                </div>
            </td>
            <td width="33%">
                <div class="result_tab">
                <h4>Sendi Kaki</h4>
                <p v-if="dataPraInduksi.aranpio_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                <p v-else-if="dataPraInduksi.aranpio_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                <p v-else>{{dataPraInduksi.aranpio_vs_persendian_kaki_text||"-"}}</p>
                </div>
            </td>
            </tr>
            <tr>
                <td width="33%" colspan="3">
                    <div class="result_tab">
                    <h4>Saraf</h4>
                    <p v-if="dataPraInduksi.aranpio_vs_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                    <p v-else-if="dataPraInduksi.aranpio_vs_saraf == 'TP'">Tidak Diperiksa</p>
                    <p v-else>
                        <table class="table table-bordered">
                            <tr>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Refleks Fisiologis</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_r_fisiologis||"-"}}</p>
                                    </div>
                                </td>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Refleks Patologis</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_r_patologis||"-"}}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Babinski</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_babinski||"-"}}</p>
                                    </div>
                                </td>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Lasegue</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_lasegue||"-"}}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Bregard</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_bregard||"-"}}</p>
                                    </div>
                                </td>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Sicard</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_sicard||"-"}}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Kaku Duduk</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_kaku_duduk||"-"}}</p>
                                    </div>
                                </td>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Kernig Sign</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_kernig_sign||"-"}}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Brudzinski 1</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_brudzinski||"-"}}</p>
                                    </div>
                                </td>
                                <td width="50%">
                                    <div class="result_tab">
                                    <h4>Brudzinski 2</h4>
                                    <p>{{dataPraInduksi.aranpio_vs_saraf_brudzinski2||"-"}}</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </p>
                    </div>
                </td>
            </tr>
        </table>
        </div>
      </div>
    </div>

    <div class="col-6 mb-2" v-if="Object.keys(dataPenunjang||{}).length">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
        </div>
        <div class="card-body p-3">
            <div class="row">
                <div class="col-md-12">
                    <h6>Hasil Pemeriksaan Lab</h6>
                    <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                        <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Hasil</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                            <td>{{v.value}}</td>
                            <td>
                                <div class="result_tab form-group mb-0">
                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-12 mt-3">
                    <h6>Hasil Pemeriksaan Radiologi</h6>
                    <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                        <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Hasil</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                            <td>{{v.value}}</td>
                            <td>
                                <div class="result_tab form-group mb-0">
                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <h6>Hasil Pemeriksaan Lab Dokter</h6>
                    <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                        <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Hasil</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                            <td>{{v.value}}</td>
                            <td>
                                <div class="result_tab form-group mb-0">
                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-12 mt-3">
                    <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                    <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                        <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Hasil</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                            <td>{{v.value}}</td>
                            <td>
                                <div class="result_tab form-group mb-0">
                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  
    <div class="col-6 mb-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Problem dan Konsultasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <div class="result_tab">
                <p>{{dataPraInduksi.aranpio_problem_konsultasi||"-"}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 mb-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Premedikasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <p v-if="dataPraInduksi.aranpio_premedikasi_tanggal && dataPraInduksi.aranpio_premedikasi_jam">Tanggal Premedikasi : 
              {{dataPraInduksi.aranpio_premedikasi_tanggal|moment("DD MMMM YYYY")}} 
              {{dataPraInduksi.aranpio_premedikasi_jam}}
              </p>
              <table class="table table-bordered table-striped table-sm patient-table">
                <thead>
                  <tr>
                    <th>No </th>
                    <th>Nama Obat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (dataPraInduksi.aranpio_premedikasi_obat||[])" :key="k">
                    <td>{{k+1}}</td>
                    <td>{{v.nama||"-"}}</td>
                  </tr>
                  <tr>
                    <td colspan="99" class="text-center">Tidak ada data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-6 mb-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Persiapan Khusus</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-12">
              <div class="result_tab">
                <p>{{dataPraInduksi.aranpio_persiapan_khusus||"-"}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data(){
    return {
      activeTab: 1,
      keadaanUmum: false
    }
  },
  computed: {
    dataPraInduksi(){
      return this.$parent.dataOperasi.dataPraInduksi
    },
    dataPenunjang(){
      return this.$parent.dataOperasi.dataPenunjang
    },
    dataOperasi(){
      return this.$parent.dataOperasi.dataOperasi
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  },
}

</script>