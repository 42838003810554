<template>
<div class="content-wrapper overflow-visible">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

  <div class="content">
    <validation-observer ref="VFormCPPT">
      <b-form @submit.prevent="doSubmit" class="card">
        <div class="card-header">
          <h6 class="card-title font-weight-semibold">Tambah CPPT Dokter</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label class="" for="">Tanggal</label>
                <div class="input-group">
                  <datepicker v-model="row.arancpd_tanggal" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate name="Tanggal" v-model="row.arancpd_tanggal" :rules="toValidate(mrValidation.arancpd_tanggal)" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="" for="">Jam</label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                  <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancpd_jam">
                  </vue-timepicker>
                </div>
                <VValidate name="Jam" v-model="row.arancpd_jam" :rules="toValidate(mrValidation.arancpd_jam)" />
              </div>
            </div>
            <div class="col-md-6">
              <a href="javascript:;" @click="openRiwayatCPPT()" class="btn btn-icon alpha-indigo text-indigo-800 border-indigo float-right" v-b-tooltip.hover :title="'Pilih CPPT Dari Riwayat'">
                <i class="fas fa-receipt"></i>
                Pilih CPPT Dari Riwayat
              </a>
            </div>
          </div>
          <div class="card mb-0">
            <div class="card-header p-0">
              <a href="javascript:;" @click="showS = !showS" class="btn btn-block text-left" data-toggle="collapse">
                <div class="label_code">
                  <h2>S</h2>
                </div>
                <span class="ml-2">Subjektif</span>
              </a>
            </div>
            <div :class="showS ? 'collapse show' : 'collapse'" id="dokSubjektif">
              <div class="wrap_line mb-0">
                <div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Subjektif<small class="txt_mandatory">*</small></label>
                        <b-form-textarea v-model="row.arancpd_subjektif" name="Subjective" id="Subjective" rows="3" class="form-control"></b-form-textarea>
                        <VValidate name="Subjective" v-model="row.arancpd_subjektif" :rules="toValidate(mrValidation.arancpd_subjektif)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-0 mt-2">
            <div class="card-header p-0">
              <a href="javascript:;" @click="showO = !showO" data-toggle="collapse" class="btn btn-block text-left">
                <div class="label_code">
                  <h2>O</h2>
                </div>
                <span class="ml-2">Objektif</span>
              </a>
            </div>
            <div :class="showO ? 'collapse show' : 'collapse'" id="dokObjektif">
              <div class="wrap_line mb-0">
                <div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Objektif<small class="txt_mandatory">*</small></label>
                        <b-form-textarea v-model="row.arancpd_objektif" name="Objektif" id="Objektif" rows="3" class="form-control"></b-form-textarea>
                        <VValidate name="Objektif" v-model="row.arancpd_objektif" :rules="toValidate(mrValidation.arancpd_objektif)" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                        </div>
                        <div class="card-body p-3 pb-5">
                          <div class="row gx-2">
                            <div class="col-lg-4">
                              <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in row.arancpd_status_lokalis||[]" :key="k+'nav'">
                                  <a href="javascript:;" data-toggle="tab" :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <span>{{v.name||"(Anatomi Baru)"}}</span>
                                      <div>
                                        <i class="icon-chevron-right"></i>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li class="nav-item border-top">
                                  <a href="javascript:;" @click="addAnatomi" class="nav-link border-top bg-light">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <span><i class="icon-plus2 mr-2"></i>Tambah Anatomi</span>
                                    </div>
                                  </a>
                                </li>
                                <li class="nav-item border-top">
                                  <a href="javascript:;" @click="deleteAnatomi(activeAnat)" class="nav-link border-top bg-light">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <span><i class="icon-trash mr-2"></i>Hapus Anatomi</span>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-8">
                              <div class="tab-content">
                                <div v-for="(v,k) in row.arancpd_status_lokalis||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                  <div class="form-group">
                                    <label for="">Jenis Anatomi<strong class="text-danger">*</strong></label>
                                    <div class="input-group flex-nowrap">
                                      <v-select class="flex-fill" placeholder="Pilih Status Lokalis" @input="selectStatusLokalis($event,k)" v-model="v.type" :disabled="!v.isEdit" :options="getStatusLokalis(row.arancpd_status_lokalis,k)" label="text" :reduce="v=>v.value">
                                      </v-select>

                                      <div class="input-group-append">
                                        <button @click="editAnatomi(k)" type="button" class="btn btn-info btn-labeled btn-labeled-left">
                                          <b><i class="icon-pencil"></i></b>
                                          Edit
                                        </button>
                                      </div>

                                      <div class="input-group-append">
                                        <button @click="saveAnatomi(k)" type="button" class="btn btn-success btn-labeled btn-labeled-left">
                                          <b><i class="icon-checkmark"></i></b>
                                          Simpan
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                    <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                                      <div class="body_wrapper">
                                        <img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
                                      </div>
                                      <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'" :style="`top:${v1.y}%;left:${v1.x}%`">
                                        <a href="javascript:;" @click="openAnat(k1,k)" class="ai-point" v-b-tooltip.hover :html="true" :title="v1.value"></a>
                                        <div class="anatomy-info-input" :class="{
                                      'd-block': v1.isOpen,
                                      'reversed': v1.isReversed
                                    }" :style="v1.isReversed ? 'transform: translateX('+v1.reverseCount+')' : ''" @click.stop.prevent>
                                          <textarea v-model="v1.value" name="anatomyInput" id="anatomyInput" rows="2" class="form-control form-control-sm"></textarea>
                                          <small class="text-muted"><i class="icon-info22"></i> Keluhan
                                            harus diisi</small>

                                          <div class="mt-2 text-right">
                                            <a href="javascript:;" class="btn btn-sm btn-danger" @click.prevent.stop="removeAnat(k1,k)">Hapus</a>
                                            <a href="javascript:;" @click.prevent.stop="closeAnat(k1,k)" class="btn btn-sm btn-success">Simpan</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span v-if="!(row.arancpd_status_lokalis||[]).length">Belum Ada Data Anatomi
                                  (Klik Tambah Untuk Menambahkan)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-0 mt-2">
            <div class="card-header p-0">
              <a href="javascript:;" @click="showA = !showA" data-toggle="collapse" class="btn btn-block text-left">
                <div class="label_code">
                  <h2>A</h2>
                </div>
                <span class="ml-2">Asesmen</span>
              </a>
            </div>
            <div :class="showA ? 'collapse show' : 'collapse'" id="dokAsesmen">
              <div class="wrap_line mb-0">
                <div>
                  <div class="row">
                    <!--
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Asesmen</label>
                        <b-form-textarea v-model="row.arancpd_asesmen" name="Asesmen" id="Asesmen" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>
                    -->

                    <div class="col-md-8">
                      <div class="card border shadow-0">
                        <div class="bg-light card-header py-2">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                            <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                              <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                            </a>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa" v-model="inputICD10" @search="filterICD10($event,0)" @input="selectICD10(inputICD10)" :options="mICD10" label="text" :clearable="true" :reduce="v=>v.value">

                                  <template slot="selected-option">
                                    <span v-b-tooltip.hover.right :title="inputICD10Name">
                                      {{inputICD10Name||"-"}}
                                    </span>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                    <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                  </template>
                                  <template #list-footer v-if="(mICD10||[]).length >= 1">
                                    <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                        <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{mICD10[0].currentPage}} dari
                                          {{ceilData(mICD10[0].totalRows/5)}}</small>
                                        <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                    </li>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                          <thead>
                            <tr>
                              <th>Item ICD-10 dipilih</th>
                              <th width="64">Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                              <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                              <td>
                                <a href="javascript:;" @click="removedataICD10(v.aranci10d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                  <i class="icon-trash-alt"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>
                        <div class="card-body pt-2">
                          <div class="form-group mb-0">
                            <label for="inputManualDiagnose">
                              Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                              <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                            </label>
                            <b-textarea v-model="row.arancpd_diagnosa_text" name="" id="" rows="3" class="form-control"></b-textarea>

                            <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="row.arancpd_diagnosa_text" :rules="{required:1, min:2, max:512}" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-0 mt-2">
            <div class="card-header p-0">
              <a href="javascript:;" @click="showP = !showP" data-toggle="collapse" class="btn btn-block text-left">
                <div class="label_code">
                  <h2>P</h2>
                </div>
                <span class="ml-2">Perencanaan</span>
              </a>
            </div>
            <div :class="showP ? 'collapse show' : 'collapse'" id="dokPerencanaan">
              <div class="wrap_line mb-0">
                <div>
                  <div class="row g-2">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Perencanaan<small class="txt_mandatory">*</small></label>
                        <b-form-textarea v-model="row.arancpd_perencanaan" name="Asesmen" id="Asesmen" rows="3" class="form-control"></b-form-textarea>
                        <VValidate name="Perencanaan" v-model="row.arancpd_perencanaan" :rules="toValidate(mrValidation.arancpd_perencanaan)" />
                      </div>
                    </div>
                    
                    <div class="col-md-8">
                      <div class="card border shadow-0">
                        <div class="bg-light card-header py-2">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                            <a href="javascript:;" v-if="riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                              <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                            </a>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan" v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)" :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">

                                  <template slot="selected-option">
                                    <span v-b-tooltip.hover.right :title="inputICD9Name">
                                      {{inputICD9Name||"-"}}
                                    </span>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                    <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                  </template>
                                  <template #list-footer v-if="(mICD9||[]).length >= 1">
                                    <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{mICD9[0].currentPage}} dari
                                          {{ceilData(mICD9[0].totalRows/5)}}</small>
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                    </li>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                          <thead>
                            <tr>
                              <th>Item ICD-9 dipilih</th>
                              <th width="64">Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                              <td>
                                <span>{{v.mi9_name||"-"}}<span class="font-weight-semibold" v-if="v.mi9_code">[{{v.mi9_code||"-"}}]</span></span>
                                <div v-if="v.mi9_informed_consent"><small class="font-weight-medium text-info-800">Tindakan memerlukan informed consent</small></div>
                              </td>
                              <td>
                                <a href="javascript:;" @click="removedataICD9(v.aranci9d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Data ICD 9">
                                  <i class="icon-trash-alt"></i>
                                </a>

                                <a href="javascript:;" @click="openInformConsent(v)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo" v-b-tooltip.hover :title="v.aranci9d_informed_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                                  <i class="icon-file-check2"></i>
                                </a>

                                <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.aranci9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                                  <i class="icon-file-eye2"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                        <div class="card-body pt-2">
                          <div class="form-group mb-0">
                            <label for="inputManualDiagnose">
                              Tindakan Lainnya
                              <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                            </label>
                            <b-textarea v-model="row.arancpd_tindakan_text" name="" id="" rows="3" class="form-control"></b-textarea>

                            <a href="javascript:;" v-if="row.arancpd_tindakan_text" @click="openInformConsentForm" class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i>
                              {{row.arancpd_inform_consent_id ? 'Edit Informed Consent' : 'Tambah Informed Consent'}}
                            </a>

                            <a href="javascript:;" @click="openInformConsentDetailForm" v-if="row.arancpd_inform_consent_id" class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> Lihat Inform Consent</a>

                            <a href="javascript:;" v-if="row.arancpd_inform_consent_id" @click="removeICDForm()" class="btn btn-sm mr-2 mt-2 btn-icon alpha-danger text-danger-800 border-danger"><i class="icon-trash-alt"></i> Hapus Inform Consent</a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <div class="form-group">
                          <label for="opsiTindakLanjut">Tindak Lanjut<strong class="text-danger">*</strong></label>
                          <div id="opsiTindakLanjut">
                            <div class="row g-2">

                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_resep') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_resep" value="Y" unchecked-value="N">
                                  Resep
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_lab') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_lab" value="Y" unchecked-value="N">
                                  Lab
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_radiologi') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_radiologi" value="Y" unchecked-value="N">
                                  Radiologi
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_fisio') != -1 && (user.mpo_id == 1 || user.levelId == 1)">
                                <b-form-checkbox v-model="row.arancpd_is_fisio" value="Y" unchecked-value="N">
                                  Fisioterapi
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_operasi') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_operasi" value="Y" unchecked-value="N">
                                  Operasi
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_konsultasi') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_konsultasi" value="Y" unchecked-value="N">
                                  Konsultasi
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_dietisien') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_dietisien" value="Y" unchecked-value="N">
                                  Konsultasikan ke Dietisien
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_raber') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_raber" value="Y" unchecked-value="N">
                                  Rawat Bersama
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_alih_rawat') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_alih_rawat" value="Y" unchecked-value="N">
                                  Alih Rawat
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_pindah_ruang') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_pindah_ruang" value="Y" unchecked-value="N">
                                  Pindah Ruang
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_rencana_operasi') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_rencana_operasi" value="Y" unchecked-value="N">
                                  Rencanakan Operasi
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_stop_raber') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_stop_raber" value="Y" unchecked-value="N">
                                  Lepas Rawat Bersama
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_perawatan') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_perawatan" value="Y" unchecked-value="N">
                                  Masih dalam Perawatan
                                </b-form-checkbox>
                              </div>
                              <div class="col-auto" v-if="showTindakLanjut.indexOf('is_done_perawatan') != -1">
                                <b-form-checkbox v-model="row.arancpd_is_done_perawatan" value="Y" unchecked-value="N">
                                  Selesai Perawatan
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="row.arancpd_is_resep == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Resep">
                          <ResepDokter />
                        </div>
                        <div v-if="row.arancpd_is_lab == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Laboratorium">
                          <Laboratorium />
                        </div>
                        <div v-if="row.arancpd_is_radiologi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Radiologi">
                          <Radiologi />
                        </div>
                        <div v-if="row.arancpd_is_fisio == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Fisioterapi">
                          <Fisioterapi />
                        </div>
                        <div v-if="row.arancpd_is_operasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Operasi">
                          <Operasi />
                        </div>
                        <div v-if="row.arancpd_is_konsultasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Konsultasi">
                          <Konsultasi />
                        </div>
                        <div v-if="row.arancpd_is_dietisien == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Dietisien">
                          <Dietisien />
                        </div>
                        <div v-if="row.arancpd_is_raber == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Raber">
                          <Raber />
                        </div>
                        <div v-if="row.arancpd_is_alih_rawat == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                          <AlihRawat />
                        </div>
                        <div v-if="row.arancpd_is_pindah_ruang == 'Y'" class="dokterTindakLanjut card" id="tlOpt-PindahRuang">
                          <PindahRuang />
                        </div>
                        <div v-if="row.arancpd_is_rencana_operasi == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                          <RencanaOperasi />
                        </div>
                        <div v-if="row.arancpd_is_done_perawatan == 'Y'" class="dokterTindakLanjut card" id="tlOpt-Alih">
                          <SelesaiPerawatan />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
        <div class="floating-action">
          <button type="button" class="btn btn-lg btn-info btn-icon shadow-lg"
            v-b-tooltip.hover @click="toModalResPenunjang()" title="Lihat Tindakan Penunjang" data-placement="left"
          >
            <i class="fas fa-list-check"></i>
          </button>
        </div>
      </b-form>
    </validation-observer>
  </div>

  <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
    <div class="modal-body p-0">
      <div class="mb-3">
        <div class="row">
          <div class="col-md-5 col-lg-4">
            <div class="d-flex w-100">
              <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                <input v-model="searchICD10" @input="searchICD10Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                <div class="form-control-feedback">
                  <i class="icon-search4 text-indigo"></i>
                </div>
              </div>
              <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-sm patient-table">
          <thead>
            <tr>
              <th width="64">#</th>
              <th>No. Registrasi</th>
              <th>Tanggal</th>
              <th>Dokter</th>
              <th>ICD10</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(v,k) in riwayatICD10">
              <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                <td v-if="v.isHead" :rowspan="v.rowspan">
                  <div class="custom-control custom-control-inline custom-checkbox">
                    <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                    <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                  </div>

                </td>

                <td v-if="v.isHead" :rowspan="v.rowspan">
                  <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                </td>
                <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                    <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
              </tr>
            </template>
            <tr v-if="!(riwayatICD10||[]).length">
              <td colspan="99" class="text-center">Tidak ada data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer p-0 mt-3">
      <div class="text-right">
        <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
        <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
          Pilihan</button>
      </div>
    </div>
  </b-modal>

  <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
    <div class="modal-body p-0">
      <div class="mb-3">
        <div class="row">
          <div class="col-md-5 col-lg-4">
            <div class="d-flex w-100">
              <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                <input v-model="searchICD9" @input="searchICD9Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                <div class="form-control-feedback">
                  <i class="icon-search4 text-indigo"></i>
                </div>
              </div>
              <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-sm patient-table">
          <thead>
            <tr>
              <th width="64">#</th>
              <th>No. Registrasi</th>
              <th>Tanggal</th>
              <th>Dokter</th>
              <th>ICD9</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(v,k) in riwayatICD9">

              <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                <td v-if="v.isHead" :rowspan="v.rowspan">
                  <div class="custom-control custom-control-inline custom-checkbox">
                    <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                    <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                  </div>

                </td>

                <td v-if="v.isHead" :rowspan="v.rowspan">
                  <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                </td>
                <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                    <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
              </tr>
            </template>
            <tr v-if="!(riwayatICD9||[]).length">
              <td colspan="99" class="text-center">Tidak ada data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer p-0 mt-3">
      <div class="text-right">
        <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
        <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
          Pilihan</button>
      </div>
    </div>
  </b-modal>

  <validation-observer ref="VFormInformconsent">
    <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td class="input-cell">
              <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div>
                <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                <div>
                  <b-form-radio-group :options="Config.mr.pasienSetuju" v-model="rowIC.aranic_is_pasien_setuju" />
                  <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                </div>
              </div>
            </td>
            <td width="50%">
              <div>
                <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                <div>
                  <b-form-radio-group :options="Config.mr.persetujuanTindakan" v-model="rowIC.aranic_persetujuan_tindakan" />
                  <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>
  </validation-observer>

  <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
    <table class="table table-bordered table-sm table-input">
      <thead>
        <tr>
          <td colspan="3">
            <div class="result_tab">
              <h4>Nama Tindakan / ICD-9-CM</h4>
              <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
            </div>
          </td>
        </tr>
        <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
            {{rowIC.aranic_diagnosis||"-"}}
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
            {{rowIC.aranic_tata_cara||"-"}}
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
            {{rowIC.aranic_tujuan||"-"}}
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
            {{rowIC.aranic_risiko||"-"}}
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
            {{rowIC.aranic_kompilasi||"-"}}
          </td>
        </tr>
        <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
            {{rowIC.aranic_prognosis||"-"}}
          </td>
        </tr>
        <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
            {{rowIC.aranic_alternatif||"-"}}
          </td>
        </tr>
        <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
            {{rowIC.aranic_lainnya||"-"}}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td width="50%" colspan="2">
            <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
            </div>
          </td>
          <td width="50%">
            <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </b-modal>

  <b-modal hide-footer v-model="toModalPenunjang" :title="'Tindakan Pada Pasien'" size="xl">
    <TindakanPenunjang :rowData="row" :rowReg="rowReg"/>
  </b-modal>

  <b-modal hide-footer v-model="modalCPPTRiwayat" :title="'Pilih CPPT Dari Riwayat'" size="xl">
    <DataRiwayat /> 
  </b-modal>

</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'

import ResepDokter from './ResepDokter.vue'
import Laboratorium from './Laboratorium.vue'
import Radiologi from './Radiologi.vue'
import Fisioterapi from './Fisioterapi.vue'
import Operasi from './Operasi.vue'
import Konsultasi from './Konsultasi.vue'
import Raber from './Raber.vue'
import AlihRawat from './AlihRawat.vue'
import Dietisien from './Dietisien.vue'
import PindahRuang from './PindahRuang.vue'
import SelesaiPerawatan from './SelesaiPerawatan.vue'
import RencanaOperasi from './RencanaOperasi.vue'
import DataRiwayat from './DataRiwayat.vue'

import 'vue2-timepicker/dist/VueTimepicker.css'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import TindakanPenunjang from '@/components/Ranap/TindakanPenunjang.vue'

export default {
  extends: GlobalVue,
  components: {
    Datepicker,
    VueTimepicker,
    ResepDokter,
    Laboratorium,
    Radiologi,
    Fisioterapi,
    Operasi,
    Konsultasi,
    Raber,
    AlihRawat,
    Dietisien,
    PatientInfo,
    PindahRuang,
    SelesaiPerawatan,
    RencanaOperasi,
    TindakanPenunjang,
    DataRiwayat
  },
  data() {
    return {
      rowReg: {},
      activeAnat: 0,
      showS: true,
      showO: true,
      showA: true,
      showP: true,

      resepDokter: [],
      dataRMDokter: {},
      mRacikan: [],
      riwayatObat: [],
      mObatPreferensi: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mDokter: [],
      mObat: [],

      dataICD9: [],
      dataICD10: [],
      riwayatICD9: [],
      riwayatICD10: [],
      mICD9: [],
      mICD10: [],
      mFaskes: [],
      mPoli: [],
      openriwayatICD10: false,
      openriwayatICD9: false,
      selectedICD9: '',
      selectedICD10: '',
      searchICD10: '',
      searchICD9: '',
      inputICD9: null,
      inputICD10: null,
      inputICD9Name: null,
      inputICD10Name: null,
      searchICD10Name: null,
      searchICD9Name: null,
      showTindakLanjut: [],
      listCPPTRiwayat: [],

      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },
      rowSP: {
        type: ''
      },

      openModalIC: false,
      openModalICDetail: false,

      patientData: {},
      loading: {
        patientInfo: false,
      },
      mJenisRawatInap: [
        {
          "text": "Obsgyn",
          "value": 1
        },
        {
          "text": "Umum",
          "value": 3
        },
        {
          "text": "Perawatan Kritis",
          "value": 4
        }
      ],
      mRanapBangsal: [],
      mRanapKelas: [],
      toModalPenunjang: false,
      modalCPPTRiwayat: false
    }
  },
  computed: {
    selectedOperasiInput() {
      let data = []
      for (let i = 0; i < (this.row.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (this.row.arancptlo_tindakan[i]['data'] || []).length; j++) {
          if (this.row.arancptlo_tindakan[i]['data'][j]['selected']) {
            data.push(this.row.arancptlo_tindakan[i]['data'][j]['text'])
          }
        }
      }
      return data.join(", ")
    },
    
    selectedOperasiInputId() {
      let data = []
      for (let i = 0; i < (this.row.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (this.row.arancptlo_tindakan[i]['data'] || []).length; j++) {
          if (this.row.arancptlo_tindakan[i]['data'][j]['selected']) {
            data.push(this.row.arancptlo_tindakan[i]['data'][j])
          }
        }
      }
      return data
    },
    
    selectedOperasiInformConsent() {
      let data = []
      for (let i = 0; i < (this.row.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (this.row.arancptlo_tindakan[i]['data'] || []).length; j++) {
          if (this.row.arancptlo_tindakan[i]['data'][j]['selected']) {
            data.push(this.row.arancptlo_tindakan[i]['data'][j]['inform_consent'])
          }
        }
      }
      return data
    },

    validateICOperasi() {
      let isHaveIC = 0
      for (let i = 0; i < (this.selectedOperasiInputData || []).length; i++) {
        if (this.selectedOperasiInputData[i]['inform_consent']) {
          isHaveIC = isHaveIC + 1
        }
      }
      return isHaveIC == (this.selectedOperasiInputData || []).length
    },

    selectedOperasiInputData() {
      let data = []
      for (let i = 0; i < (this.row.arancptlo_tindakan || []).length; i++) {
        for (let j = 0; j < (this.row.arancptlo_tindakan[i]['data'] || []).length; j++) {
          if (this.row.arancptlo_tindakan[i]['data'][j]['selected']) {
            data.push(this.row.arancptlo_tindakan[i]['data'][j])
          }
        }
      }
      return data
    },
    
    isHaveTindakLanjut() {
      let is = false
      if (
        this.row.arancpd_is_resep == 'Y' ||
        this.row.arancpd_is_lab == 'Y' ||
        this.row.arancpd_is_radiologi == 'Y' ||
        this.row.arancpd_is_fisio == 'Y' ||
        this.row.arancpd_is_operasi == 'Y' ||
        this.row.arancpd_is_konsultasi == 'Y' ||
        this.row.arancpd_is_dietisien == 'Y' ||
        this.row.arancpd_is_raber == 'Y' ||
        this.row.arancpd_is_alih_rawat == 'Y' ||
        this.row.arancpd_is_pindah_ruang == 'Y' ||
        this.row.arancpd_is_perawatan == 'Y' ||
        this.row.arancpd_is_done_perawatan == 'Y' ||
        this.row.arancpd_is_rencana_operasi == 'Y' || 
        this.row.arancpd_is_stop_raber == 'Y'
      ) {
        is = true
      }
      return is
    },
  },
  methods: {
    toModalResPenunjang(){
      this.toModalPenunjang = true
    },
    showTindakLanjutCPPT(){
      if(this.user.levelId != 1){
        if(this.user.id == this.rowReg.aranr_dokter_id){
          this.showTindakLanjut = [
            'is_resep', 'is_lab', 'is_radiologi', 'is_fisio', 'is_operasi', 'is_konsultasi', 'is_dietisien', 'is_raber', 'is_alih_rawat', 'is_pindah_ruang', 'is_perawatan', 'is_done_perawatan'
          ]
        } else if (this.row.arancp_konsul_id) {
          this.showTindakLanjut = [
            'is_resep', 'is_lab', 'is_radiologi', 'is_fisio', 'is_operasi', 'is_konsultasi', 'is_dietisien', 'is_perawatan'
          ]
        } else if (this.row.arancp_raber_id) {
          this.showTindakLanjut = [
            'is_resep', 'is_lab', 'is_radiologi', 'is_fisio', 'is_operasi', 'is_konsultasi', 'is_dietisien', 'is_pindah_ruang', 'is_perawatan', 'is_rencana_operasi','is_stop_raber'
          ]
        } else if (this.user.bu_is_dokter_jaga == 'Y' || this.user.bu_is_anestesi == 'Y') {
          this.showTindakLanjut = ['is_lab', 'is_radiologi', 'is_fisio', 'is_dietisien', 'is_resep', 'is_pindah_ruang', 'is_perawatan']
        } else {
          this.showTindakLanjut = [
            'is_resep','is_lab','is_radiologi','is_operasi','is_konsultasi','is_dietisien','is_raber','is_alih_rawat','is_fisio','is_pindah_ruang','is_perawatan','is_done_perawatan'
          ]
        } 
      } else {
        this.showTindakLanjut = [
          'is_resep', 'is_lab', 'is_radiologi', 'is_fisio', 'is_operasi', 'is_konsultasi', 'is_dietisien', 'is_raber', 'is_alih_rawat', 'is_pindah_ruang', 'is_perawatan', 'is_done_perawatan','is_rencana_operasi','is_stop_raber'
        ]
      }
    },

    back() {
      this.$router.push({name: 'RanapCPPT', params: {pageSlug: this.pageSlug}}).catch(() => {})
    },

    apiGet(params = {}, page = 1) {
      if (!this.pageSlug) {
        this.$router.push({
          name: 'Dashboard'
        }).catch(() => {})
      }

      if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.cpptNo ? this.modulePage + '/' + this.$route.params.pageSlug + '/' + this.$route.params.cpptNo : this.modulePage + '/' + this.$route.params.pageSlug

      Gen.apiRest(
        "/get/" + url, {
          params: Object.assign({
            page: page
          }, paramsQuery, params.query || {})
        }
      ).then(res => {
        this.loadingOverlay = false
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
        if (!this.isFound) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }
        this.showTindakLanjutCPPT()
      }).catch(() => {
        this.loadingOverlay = false
      })

      // master
      if (this.$route.params.cpptNo) {
        Gen.apiRest(
          "/get/" + url + '?master=1', {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
        })
      }
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },

    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      if (!data.isEdit) {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              ...data
            }
          },
          "POST"
        )
      }
    }, 1000),

    toValidate(val) {
      return {
        ...val
      }
    },

    doSubmit() {

      if (this.row.arancp_is_active == 'N') {
        return this.$swal({
          icon: 'error',
          title: 'Data Telah tidak Aktif',
          text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
        }).then(result => {
          if (result.value) {
            setTimeout(() => {
              let inv = []
              let el = document.querySelectorAll('.label_error')
              for (let i = 0; i < (el || []).length; i++) {
                if (el[i].style.display !== 'none') {
                  inv.push(el[i].id)
                }
              }
              if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 500)
          }
        })
      }

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                console.log(el)
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }

                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }

        if (!this.isHaveTindakLanjut) {
          return this.$swal({
            icon: 'error',
            title: 'Tidak dapat Lanjut',
            text: 'Tindak lanjut belum diisi'
          })
        }


        if (this.row.arancpd_is_operasi == 'Y') {
          if(!(this.selectedOperasiInputData||[]).length){
            return this.$swal({
              icon: 'error',
              title: 'Tidak dapat Lanjut',
              text: 'Tidak ada tindakan operasi dipilih'
            })
          }
          if (!this.validateICOperasi) {
            return this.$swal({
              icon: 'error',
              title: 'Tidak dapat Lanjut',
              text: 'Tindakan Operasi Memerlukan Inform Consent'
            })
          }
        }

        if (this.row.arancpd_is_pindah_ruang == 'Y' && this.row.aranctlpr_type) {
          let isFail = 0
          if(this.row.aranctlpr_type == "ICU"){
            if(!(this.row.aranctlpr_icu_ttv||[]).length && !(this.row.aranctlpr_icu_lab||[]).length && !(this.row.aranctlpr_icu_radiologi||[]).length && !(this.row.aranctlpr_icu_pemeriksaan_fisik||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.row.aranctlpr_type == "NICU"){
            if(!(this.row.aranctlpr_picu_ttv||[]).length && !(this.row.aranctlpr_picu_radiografi||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.row.aranctlpr_type == "AIRBORNE"){
            if(!this.row.aranctlpr_airborne){
              isFail = isFail + 1
            } 
          } else if(this.row.aranctlpr_type == "IMUNOSUPRESI"){
            if(!this.row.aranctlpr_imunosupresi){
              isFail = isFail + 1
            } 
          } else if(this.row.aranctlpr_type == "KICU"){
            if(!(this.row.aranctlpr_kicu_ttv||[]).length && !(this.row.aranctlpr_kicu_nilai_lab||[]).length){
              isFail = isFail + 1
            } 
          } else if(this.row.aranctlpr_type == "KNICU"){
            if(!(this.row.aranctlpr_knicu_kriteria_umum||[]).length && !(this.row.aranctlpr_knicu_ttv||[]).length){
              isFail = isFail + 1
            }
          } else if(this.row.aranctlpr_type == "KAIRBORNE"){
            if(!(this.row.aranctlpr_kairborne||[]).length){
              isFail = isFail + 1
            }
          } else if(this.row.aranctlpr_type == "KIMUNOSUPRESI"){
            if(!(this.row.aranctlpr_kimunosupresi||[]).length){
              isFail = isFail + 1
            }
          }

          if(isFail){
            return this.$swal({
              icon: 'error',
              title: 'Tidak dapat Lanjut',
              text: 'Kriteria Pasien Pindah Ruang Belum Diisi'
            })
          }
        }

        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              data.resepDokter = this.resepDokter
              data.dataICD9 = this.dataICD9
              data.dataICD10 = this.dataICD10
              data.tindakanOperasi = this.selectedOperasiInput
              data.tindakanOperasiId = this.selectedOperasiInputId
              data.tindakanIC = this.selectedOperasiInformConsent

              data.dataSP = this.rowSP

              this.loadingOverlay = true
              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: data
                },
                "POST"
              ).then(res => {
                this.loadingOverlay = false

                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(result => {
                  if (this.row.arancpd_is_done_perawatan == 'Y') {
                    this.$router.push({
                      name: 'RanapPasienSelesai'
                    }).catch(() => {})
                  } else {
                    this.$router.push({
                      name: 'RanapCPPT',
                      params: {
                        pageSlug: this.pageSlug
                      }
                    }).catch(() => {})
                  }
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }

      })
    },

    editAnatomi(k) {
      this.row.arancpd_status_lokalis[k]['isEdit'] = true
    },
    saveAnatomi(k) {
      this.row.arancpd_status_lokalis[k]['isEdit'] = false
    },
    changeTabAnat(k, isAdd = false) {
      this.activeAnat = k
      setTimeout(() => {
        if (isAdd) {
          this.setPosisionNyeri()
        }
      }, 100)
    },
    deleteAnatomi(key) {
      this.row.arancpd_status_lokalis.splice(key, 1)
      this.activeAnat = (this.row.arancpd_status_lokalis || []).length - 1
    },
    addAnatomi() {
      this.row.arancpd_status_lokalis = this.row.arancpd_status_lokalis ? this.row.arancpd_status_lokalis : []
      this.row.arancpd_status_lokalis.push({
        name: '',
        type: null,
        image: '',
        isEdit: true,
        data: [],
      })
      this.activeAnat = (this.row.arancpd_status_lokalis || []).length - 1
    },
    selectStatusLokalis(e, k) {
      let idx = this.Config.mr.configStatusLokalis.findIndex(x => x.value == e)
      if (idx !== -1) {
        let data = this.Config.mr.configStatusLokalis[idx]
        this.row.arancpd_status_lokalis[k]['name'] = data.text
        if (this.row.ap_gender == 2) {
          this.row.arancpd_status_lokalis[k]['image'] = data.image2
        } else {
          this.row.arancpd_status_lokalis[k]['image'] = data.image
        }
      }

      this.row.arancpd_status_lokalis[this.activeAnat]['data'] = []

      if (!$("#anatomiNyeri" + this.activeAnat).length) {
        this.changeTabAnat(k, true)
      } else {
        this.changeTabAnat(k)
      }
    },

    getStatusLokalis(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.type
      })
      return _.filter(this.Config.mr.configStatusLokalis, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    removeAnat(k, kp) {
      this.row.arancpd_status_lokalis[kp]['data'].splice(k, 1)
    },
    closeAnat(k, kp) {
      if (this.row.arancpd_status_lokalis[kp]['data'][k]['value']) {
        this.row.arancpd_status_lokalis[kp]['data'][k]['isOpen'] = false
        this.row.arancpd_status_lokalis[kp]['data'][k]['y'] += (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
      }
    },
    openAnat(k, kp) {
      this.row.arancpd_status_lokalis[kp]['data'][k]['y'] -= (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
      this.row.arancpd_status_lokalis[kp]['data'][k]['isOpen'] = true
    },
    setPosisionNyeri() {
      let posX = false
      let posY = false
      let self = this

      $("body").mousemove(function (e) {
        if (!$("body").hasClass("active")) $("body").addClass("active")
        if ($("#anatomiNyeri" + self.activeAnat).length) {
          posX = parseInt(e.pageX - ($("#anatomiNyeri" + self.activeAnat).offset().left + 8))
          posY = parseInt(e.pageY - ($("#anatomiNyeri" + self.activeAnat).offset().top + 52))
        }
      })

      $('#anatomiNyeri' + self.activeAnat).on('click', function (e) {
        e.stopPropagation()
        let index = self.row.arancpd_status_lokalis[self.activeAnat]['data'].findIndex(x => x.isOpen == true)

        if (self.row.arancpd_status_lokalis[self.activeAnat]['isEdit']) {
          if (index !== -1) {
            self.row.arancpd_status_lokalis[self.activeAnat]['data'].splice(index, 1)
          } else {
            self.row.arancpd_status_lokalis[self.activeAnat]['data'].push({
              x: (posX / $(this).width()) * 100,
              y: (posY / $(this).height()) * 100,
              value: '',
              isOpen: true,
              isReversed: posX > 300 ? true : false,
              reverseCount: `-111%`
              // reverseCount: `-${((posX - 300) / 2) - 16 + 300}px`
            })
          }
        }
      })
    },

    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + 'RanapCPPTDokter', {
            data: dataPost
          },
          "POST"
        )
      }
    }, 1000),

    // icd

    ceilData(value) {
      return Math.ceil(value)
    },

    selectICD9(e) {
      let index = this.mICD9.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD9Name = this.mICD9[index]['text']
        this.searchICD9Name = null
      } else {
        this.inputICD9Name = null
      }
      this.addNewICD9()
    },

    selectICD10(e) {
      let index = this.mICD10.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD10Name = this.mICD10[index]['text']
        this.searchICD10Name = null
      } else {
        this.inputICD10Name = null
      }
      this.addNewICD10()
    },

    filterICD10: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.dataICD10 || []).length; i++) {
        if (this.dataICD10[i]['aranci10d_icd_10']) {
          id.push(this.dataICD10[i]['aranci10d_icd_10'])
        }
      }

      this.searchICD10Name = e

      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'select-icd-10',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD10 = res.data.data
      })
    }, 10),

    changePageICD10(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-icd-10',
        page: page,
        search: this.searchICD10Name
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD10 = res.data.data
        this.getmICD10(this.dataICD10, k)
      })
    },

    filterICD9: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.dataICD9 || []).length; i++) {
        if (this.dataICD9[i]['aranci9d_icd_9']) {
          id.push(this.dataICD9[i]['aranci9d_icd_9'])
        }
      }

      this.searchICD9Name = e

      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'select-icd-9',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD9 = res.data.data
      })
    }, 10),

    changePageICD9(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-icd-9',
        page: page,
        search: this.searchICD9Name
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD9 = res.data.data
        this.getmICD9(this.dataICD9, k)
      })
    },

    addNewICD9() {
      if (this.inputICD9) {
        let data = {
          aranci9d_arancp_id: this.row.arancp_id,
          aranci9d_icd_9: this.inputICD9,
          type: 'add-icd-9'
        }
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.dataICD9.push(resp.row)
          this.inputICD9 = null
          this.rowIC.aranic_id = null
        })
      }
    },
    removedataICD9(id, k) {
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda yakin ingin menghapus tindakan ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          let data = {
            id: id,
            type: 'remove-icd-9'
          }
          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(() => {
            this.dataICD9.splice(k, 1)
          })
        }
      })
    },

    addNewICD10() {
      if (this.inputICD10) {
        let data = {
          aranci10d_arancp_id: this.row.arancp_id,
          aranci10d_icd_10: this.inputICD10,
          type: 'add-icd-10'
        }
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.dataICD10.push(resp.row)
          this.inputICD10 = null
        })
      }
    },
    removedataICD10(id, k) {
      let data = {
        id: id,
        type: 'remove-icd-10'
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(() => {
        this.dataICD10.splice(k, 1)
      })
    },

    getmICD9(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.api9_icd_9
      })
      return _.filter(this.mICD9, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    getmICD10(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.api10_icd_10
      })
      return _.filter(this.mICD10, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    searchICD10Data: _.debounce(function () {
      let data = {
        search: this.searchICD10,
        aranr_ap_id: this.rowReg.aranr_ap_id,
        type: 'search-icd-10'
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.riwayatICD10 = resp.data
      })
    }, 500),

    searchICD9Data: _.debounce(function () {
      let data = {
        search: this.searchICD9,
        aranr_ap_id: this.rowReg.aranr_ap_id,
        type: 'search-icd-9'
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.riwayatICD9 = resp.data
      })
    }, 500),

    saveICD10() {
      if (this.selectedICD10) {
        let data = {
          id: this.selectedICD10,
          arancp_id: this.row.arancp_id,
          type: 'save-riwayat-icd-10'
        }
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
        ).then(() => {
          this.openriwayatICD10 = false
          this.resetData()
        })
      }
    },

    saveICD9() {
      if (this.selectedICD9) {
        let data = {
          id: this.selectedICD9,
          arancp_id: this.row.arancp_id,
          type: 'save-riwayat-icd-9'
        }
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
        ).then(() => {
          this.openriwayatICD9 = false
          this.resetData()
        })
      }
    },

    resetData() {
      this.searchICD10 = '',
        this.searchICD9 = '',
        this.apiGet()
    },
    // icd

    // submit inform consent

    removeICDForm() {
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menghapus data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        this.loadingOverlay = true
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'remove-ic',
            id: this.row.arancpd_inform_consent_id
          }
        }, 'POST').then(res => {
          this.row.arancpd_inform_consent_id = null
          this.loadingOverlay = false
        })
      })
    },
    openInformConsentDetail(v) {
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'get-data-ic',
          id: v.aranci9d_informed_consent,
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row
        this.rowIC.mi9_name = v.mi9_name
        this.rowIC.mi9_code = v.mi9_code
        this.rowIC.id_icd = v.aranci9d_id
        this.openModalICDetail = true
      })
    },
    openInformConsent(v) {
      if (!v.aranci9d_informed_consent) {
        this.rowIC.aranic_persetujuan_tindakan = null
        this.rowIC.aranic_is_pasien_setuju = null

        if (v.mi9_informed_consent) {
          Gen.apiRest('/do/' + this.modulePage, {
            data: {
              type: 'autofill-ic',
              id: v.mi9_informed_consent,
            }
          }, 'POST').then(res => {
            this.rowIC = res.data.row
            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.aranci9d_id
            this.rowIC.aranic_id = null
          })
        } else {
          this.rowIC.aranic_diagnosis = null
          this.rowIC.aranic_id = null
          this.rowIC.aranic_dasar_diagnosis = null
          this.rowIC.aranic_tindakan_dokter = null
          this.rowIC.aranic_indikasi_tindakan = null
          this.rowIC.aranic_tata_cara = null
          this.rowIC.aranic_tujuan = null
          this.rowIC.aranic_risiko = null
          this.rowIC.aranic_kompilasi = null
          this.rowIC.aranic_prognosis = null
          this.rowIC.aranic_alternatif = null
          this.rowIC.aranic_lainnya = null
        }

      } else {
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'get-data-ic',
            id: v.aranci9d_informed_consent,
          }
        }, 'POST').then(res => {
          this.rowIC = res.data.row
          this.rowIC.mi9_name = v.mi9_name
          this.rowIC.mi9_code = v.mi9_code
          this.rowIC.id_icd = v.aranci9d_id
        })
        // console.log(edit)
      }

      this.rowIC.mi9_name = v.mi9_name
      this.rowIC.mi9_code = v.mi9_code
      this.rowIC.id_icd = v.aranci9d_id

      this.openModalIC = true
    },

    openInformConsentDetailForm(v) {
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'get-data-ic',
          id: this.row.arancpd_inform_consent_id,
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row
        this.rowIC.mi9_name = this.row.arancpd_tindakan_text
        this.rowIC.mi9_code = ''
        this.rowIC.id_assesment = this.row.arancpd_id
        this.openModalICDetail = true
      })
    },
    openInformConsentForm() {
      if (!this.row.arancpd_inform_consent_id) {
        this.rowIC.aranic_diagnosis = null
        this.rowIC.aranic_id = null
        this.rowIC.aranic_dasar_diagnosis = null
        this.rowIC.aranic_tindakan_dokter = null
        this.rowIC.aranic_indikasi_tindakan = null
        this.rowIC.aranic_tata_cara = null
        this.rowIC.aranic_tujuan = null
        this.rowIC.aranic_risiko = null
        this.rowIC.aranic_kompilasi = null
        this.rowIC.aranic_prognosis = null
        this.rowIC.aranic_alternatif = null
        this.rowIC.aranic_lainnya = null
        this.rowIC.mi9_name = this.row.arancpd_tindakan_text
        this.rowIC.mi9_code = ''
        this.rowIC.id_assesment = this.row.arancpd_id
      } else {
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'get-data-ic',
            id: this.row.arancpd_inform_consent_id,
          }
        }, 'POST').then(res => {
          this.rowIC = res.data.row
          this.rowIC.mi9_name = this.row.arancpd_tindakan_text
        })
      }

      this.openModalIC = true
    },

    submitInformConcent() {
      this.$refs['VFormInformconsent'].validate().then(success => {
        if (success) {
          this.openModalIC = false

          let data = this.rowIC
          data.type = 'submit-data-inform-consent'
          data.aranic_aranr_id = this.rowReg.aranr_id
          data.aranic_name = this.rowIC.mi9_name + (this.rowIC.mi9_code ? " (" + this.rowIC.mi9_code + ")" : "")

          this.loadingOverlay = true
          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(res => {
            this.apiGet()

            this.rowIC.aranic_id = null

            return this.$swal({
              title: "Data Informed Consent berhasil disimpan",
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          })
        }
      })
    },

    openRiwayatCPPT(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'get-data-riwayat',
          id_reg: this.rowReg.aranr_id,
          id: this.row.arancp_id,
        }
      }, 'POST').then(res => {
        this.loadingOverlay = false
        this.modalCPPTRiwayat = true
        this.listCPPTRiwayat = res.data.listCPPTRiwayat
      })
    }
    // submit inform consent
  },
  mounted() {
    this.apiGet()

    this.getPatientInfo()

    setTimeout(() => {
      this.setPosisionNyeri()
    }, 1500)

  },
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    advisData: {
      handler(v) {
        this.autoSaveAdvisData(v)
      },
      deep: true
    },
    'resepDokter': {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    },
  }
}
</script>
