<template>
  <div>
  
    <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 1" :class="activeTab == 1 ? 'nav-link active': 'nav-link'">Laporan Anestesi</a>
      </li>
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 2" :class="activeTab == 2 ? 'nav-link active': 'nav-link'">Laporan Operasi</a>
      </li>
    </ul>
    <div class="tab-content flex-tab overflow-hidden">
      <div :class="activeTab == 1 ? 'tab-pane fade show active':'tab-pane fade'">
        <div v-if="Object.keys(dataLaporanAnestesi||{}).length" class="modal-body p-0">
          <div class="card-body">
            <div class="mb-2">
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Tanggal</h4>
                      <p v-if="dataLaporanAnestesi.aranla_date">{{dataLaporanAnestesi.aranla_date | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Jenis Operasi</h4>
                      <p>{{getConfigDynamic(Config.mr.ranapJenisOperasi,dataLaporanAnestesi.aranla_jenis_operasi)||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th>Tindakan</th>
                    <th>Jam Mulai</th>
                    <th>Jam Selesai</th>
                    <th>Lama</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-secondary"><span class="font-weight-semibold">Anestesi</span></td>
                    <td>
                      <span v-if="dataLaporanAnestesi.aranla_anastesi_date_start">
                        {{dataLaporanAnestesi.aranla_anastesi_date_start | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_anastesi_jam_start}}
                      </span>
                      <span v-else> - </span>
                    </td>
                    <td>
                      <span v-if="dataLaporanAnestesi.aranla_anastesi_date_end">
                        {{dataLaporanAnestesi.aranla_anastesi_date_end | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_anastesi_jam_end}}
                      </span>
                      <span v-else> - </span>
                    </td>
                    <td>{{distanceAnestesi}}</td>
                  </tr>
                  <tr>
                    <td class="table-secondary"><span class="font-weight-semibold">Operasi</span></td>
                    <td>
                      <span v-if="dataLaporanAnestesi.aranla_operasi_date_start">
                        {{dataLaporanAnestesi.aranla_operasi_date_start | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_operasi_jam_start}}
                      </span>
                      <span v-else> - </span>
                    </td>
                    <td>
                      <span v-if="dataLaporanAnestesi.aranla_operasi_date_end">
                        {{dataLaporanAnestesi.aranla_operasi_date_end | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_operasi_jam_end}}
                      </span>
                      <span v-else> - </span>
                    </td>
                    <td>{{distanceOperasi}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-2">
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="33%" class="align-top">
                    <div class="result_tab">
                      <h4>Tindakan Operasi</h4>
                      <p><span class="text-uppercase">{{dataOperasi.arano_tindakan_operasi||"-"}}</span></p>
                    </div>
                  </td>
                  <td width="33%" class="align-top">
                    <div class="result_tab">
                      <div class="mb-1" v-for="(v,k) in (dataICD10LaporanAnestesiPraOperasi||[])" :key="k">
                      <h4>Diagnosis Pra Operasi #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                    </div>
                    <div class="result_tab">
                      <h4>Diagnosa Pra-Operasi <span v-if="(dataICD10LaporanAnestesiPraOperasi||[]).length">Lainnya</span> </h4>
                      <p>{{dataLaporanAnestesi.aranla_diagnosa_pra_operasi||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%" class="align-top">
                    <div class="result_tab">
                      <div class="mb-1" v-for="(v,k) in (dataICD10LaporanAnestesiPostOperasi||[])" :key="k">
                      <h4>Diagnosis Post Operasi #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                    </div>
                    <div class="result_tab">
                      <h4>Diagnosa Post-Operasi <span v-if="(dataICD10LaporanAnestesiPostOperasi||[]).length">Lainnya</span></h4>
                      <p>{{dataLaporanAnestesi.aranla_diagnosa_post_operasi||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Pra-Tindakan</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tekanan Darah</h4>
                        <p v-if="dataLaporanAnestesi.aranla_tekanan_darah_min && dataLaporanAnestesi.aranla_tekanan_darah_max">{{dataLaporanAnestesi.aranla_tekanan_darah_min}}
                        /{{dataLaporanAnestesi.aranla_tekanan_darah_max}}mmHG</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nadi</h4>
                        <p v-if="dataLaporanAnestesi.aranla_nadi">{{dataLaporanAnestesi.aranla_nadi||"-"}}x/mnt</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Hb</h4>
                        <p v-if="dataLaporanAnestesi.aranla_hb">{{dataLaporanAnestesi.aranla_hb||"-"}}mg/dL</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Pernafasan</h4>
                        <p v-if="dataLaporanAnestesi.aranla_pernafasan">{{dataLaporanAnestesi.aranla_pernafasan||"-"}}x/mnt</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Suhu</h4>
                        <p v-if="dataLaporanAnestesi.aranla_suhu">{{dataLaporanAnestesi.aranla_suhu||"-"}}&deg;C</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Gol. Darah</h4>
                        <p v-if="dataLaporanAnestesi.aranla_gol_darah">{{dataLaporanAnestesi.aranla_gol_darah||"-"}}</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>EKG</h4>
                        <p v-if="dataLaporanAnestesi.aranla_ekg">{{dataLaporanAnestesi.aranla_ekg||"-"}}</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>ASA</h4>
                        <p v-if="dataLaporanAnestesi.aranla_asa">{{dataLaporanAnestesi.aranla_asa||"-"}}</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Premedikasi</h6>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-4">
                    <table class="table table-bordered table-sm">
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Premedikasi</h4>
                            <p>{{dataLaporanAnestesi.aranla_premedikasi||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Dilakukan di</h4>
                            <p>{{dataLaporanAnestesi.aranla_dilakukan||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th colspan="2">Obat-obatan</th>
                        </tr>
                        <tr>
                          <th width="16">#</th>
                          <th>Nama Obat</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (dataLaporanAnestesi.aranla_obat||[])" :key="k+'obat'">
                          <td>{{k+1}}</td>
                          <td>{{v.nama||"-"}}</td>
                        </tr>
                        <tr>
                          <td colspan="99">Tidak ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-4">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th colspan="2">Oksigenasi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Durasi</h4>
                              <p v-if="dataLaporanAnestesi.aranla_durasi">{{dataLaporanAnestesi.aranla_durasi}} menit</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Pemberian</h4>
                              <p v-if="dataLaporanAnestesi.aranla_pemberian">{{dataLaporanAnestesi.aranla_pemberian}} L/menit</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Teknik Anestesi</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="align-top" colspan="3">
                        General Anestesi
                      </th>
                    </tr>
                  </thead>
                  
                  <tr>
                    <td class="align-top" width="33%">
                      <div class="result_tab">
                        <h4>IV</h4>
                        <ul v-if="(dataLaporanAnestesi.aranla_vi||[]).length">
                          <li v-for="(v,k) in (dataLaporanAnestesi.aranla_vi||[])" :key="k">
                            {{v}}
                          </li>
                        </ul>
                        <span v-if="!(dataLaporanAnestesi.aranla_vi||[]).length">Tidak ada data</span>
                        <ul v-if="(dataLaporanAnestesi.aranla_general_anestesi||[]).length">
                          <li v-for="(v,k) in (dataLaporanAnestesi.aranla_general_anestesi||[])" :key="k">
                            {{v}}
                          </li>
                        </ul>
                        <span v-if="!(dataLaporanAnestesi.aranla_general_anestesi_dengan||[]).length">Tidak ada data</span>
                        Dengan
                        <ul v-if="(dataLaporanAnestesi.aranla_general_anestesi_dengan||[]).length">
                          <li v-for="(v,k) in (dataLaporanAnestesi.aranla_general_anestesi_dengan||[])" :key="k">
                            {{v}}
                            <template v-if="(dataLaporanAnestesi.aranla_general_anestesi_dengan||[]).indexOf('Lainnya') != -1">
                              : {{dataLaporanAnestesi.aranla_general_anestesi_dengan_text||"-"}}
                            </template>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td class="align-top" width="33%">
                      <div class="result_tab">
                        <h4>Umum Inhalasi</h4>
                        <ul v-if="(dataLaporanAnestesi.aranla_umum_inhalasi||[]).length">
                          <li v-for="(v,k) in (dataLaporanAnestesi.aranla_umum_inhalasi||[])" :key="k">
                            {{v}}
                          </li>
                        </ul>
                        <span v-if="!(dataLaporanAnestesi.aranla_umum_inhalasi||[]).length">Tidak ada data</span>
                      </div>
                    </td>
                    
                    <td class="align-top" width="33%">
                      <div class="result_tab">
                        <h4>Masker dengan</h4>
                        <span>{{dataLaporanAnestesi.aranla_mask||"-"}}</span>
                      </div>
                    </td>
                  </tr>
                  <thead>
                    <tr>
                      <th class="align-top" colspan="3">
                        Regional Anestesi
                      </th>
                    </tr>
                  </thead>
                  
                  <tr>
                    <td class="align-top">
                      <div class="result_tab">
                        <h4>Regional</h4>
                        <ul v-if="(dataLaporanAnestesi.aranla_regional||[]).length">
                          <li v-for="(v,k) in (dataLaporanAnestesi.aranla_regional||[])" :key="k">
                            {{v}}
                          </li>
                        </ul>
                        <span v-if="!(dataLaporanAnestesi.aranla_regional||[]).length">Tidak ada data</span>
                      </div>
                    </td>
                    <td class="align-top">
                      <div class="result_tab">
                        <h4>Posisi Puncture</h4>
                        <p>{{dataLaporanAnestesi.aranla_posisi||"-"}}</p>
                      </div>
                    </td>
                    <td class="align-top">
                      <div class="result_tab">
                        <h4>Level</h4>
                        <p v-if="dataLaporanAnestesi.aranla_level">{{dataLaporanAnestesi.aranla_level||"-"}} - {{dataLaporanAnestesi.aranla_level_choice||"-"}}</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-top">
                      <div class="result_tab">
                        <h4>Obat</h4>
                        <p>{{dataLaporanAnestesi.aranla_obat_text||"-"}}</p>
                      </div>
                    </td>
                    <td class="align-top">
                      <div class="result_tab">
                        <h4>Volume</h4>
                        <p v-if="dataLaporanAnestesi.aranla_volume">{{dataLaporanAnestesi.aranla_volume||"-"}}cc</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Infus</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Tangan</h4>
                        <p>{{dataLaporanAnestesi.aranla_infus_tangan||"-"}}</p>
                      </div>
                    </td>
                    <td rowspan="2" colspan="2">
                      <div class="result_tab">
                        <h4>Lokasi</h4>
                        <p>{{dataLaporanAnestesi.aranla_lokasi||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Kaki</h4>
                        <p>{{dataLaporanAnestesi.aranla_infus_kaki||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Jarum Infus No.</h4>
                        <p>{{dataLaporanAnestesi.aranla_infus_no||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>CVC</h4>
                        <p>{{dataLaporanAnestesi.aranla_cvc||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Terpasang di</h4>
                        <p>{{dataLaporanAnestesi.aranla_terpasang_di||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Keterangan Lain</h4>
                        <p>{{dataLaporanAnestesi.aranla_keterangan_lain||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Komplikasi Akut Anestesi</h4>
                        <p>{{dataLaporanAnestesi.aranla_komplikasi||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Penanganan</h4>
                        <p>{{dataLaporanAnestesi.aranla_penanganan||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemantauan Status Fisiologis</h6>
                  </div>
                  <div class="card-body p-3">
                    <img :src="dataLaporanAnestesi.aranla_pemantauan_fisiologis_with_image ? dataLaporanAnestesi.aranla_pemantauan_fisiologis_with_image: assetLocal('img/pantauan-fisiologis-bg.png')" style="max-width: 100%;">

                    <span class="mt-2 d-block" v-if="dataLaporanAnestesi.aranla_pemantauan_fisiologis_tanggal && dataLaporanAnestesi.aranla_pemantauan_fisiologis_jam">Waktu Pemantauan : {{dataLaporanAnestesi.aranla_pemantauan_fisiologis_tanggal | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_pemantauan_fisiologis_jam}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Obat yang Diberikan</th>
                      </tr>
                      <tr>
                        <th>#</th>
                        <th>Nama Obat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (dataLaporanAnestesi.aranla_obat_yang_diberikan||[])" :key="k+'obat'">
                        <td>{{k+1}}</td>
                        <td>{{v.nama||"-"}}</td>
                      </tr>
                      <tr v-if="!(dataLaporanAnestesi.aranla_obat_yang_diberikan||[]).length">
                        <td class="text-center" colspan="2">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Maintenance</h4>
                          <div class="mt-1">
                            <div class="det-check-item" v-if="dataLaporanAnestesi.aranla_maintance == 'Y'"><i
                                class="icon-checkmark-circle text-success"></i><span><strong
                                  class="font-weight-semibold">N<sub>2</sub>O</strong>: 
                                  {{dataLaporanAnestesi.aranla_maintance_text||"-"}} lt /
                                menit</span></div>
                                
                            <div class="det-check-item" v-if="dataLaporanAnestesi.aranla_maintance_o2 == 'Y'"><i
                                class="icon-checkmark-circle text-success"></i><span><strong
                                  class="font-weight-semibold">O<sub>2</sub></strong>: 
                                  {{dataLaporanAnestesi.aranla_maintance_o2_text||"-"}} lt /
                                menit</span></div>

                                O<sub>2</sub>
                            <div class="det-check-item" v-if="dataLaporanAnestesi.aranla_sevoflurane == 'Y'"><i
                                class="icon-checkmark-circle text-success"></i><span><strong
                                  class="font-weight-semibold">Sevoflurane</strong>: {{dataLaporanAnestesi.aranla_sevoflurane_text}}% vol</span></div>
                            <div class="det-check-item" v-if="dataLaporanAnestesi.aranla_isoflurane == 'Y'"><i
                                class="icon-checkmark-circle text-success"></i><span><strong
                                  class="font-weight-semibold">Isoflurane</strong>: {{dataLaporanAnestesi.aranla_isoflurane_text}}% vol</span></div>
                                  
                            <div class="det-check-item" v-if="dataLaporanAnestesi.aranla_lainnya == 'Y'"><i
                                class="icon-checkmark-circle text-success"></i><span>{{dataLaporanAnestesi.aranla_lainnya_text}}</span></div>

                            <span v-if="dataLaporanAnestesi.aranla_maintance != 'Y' && dataLaporanAnestesi.aranla_sevoflurane != 'Y' && dataLaporanAnestesi.aranla_isoflurane != 'Y' && dataLaporanAnestesi.aranla_lainnya != 'Y'">Tidak ada data</span>
                          </div>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Posisi</h4>
                          <p>{{dataLaporanAnestesi.aranla_posisi||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="4">Cairan Masuk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>RL</h4>
                            <p v-if="dataLaporanAnestesi.aranla_cm_rl">{{dataLaporanAnestesi.aranla_cm_rl||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>NaCl</h4>
                            <p v-if="dataLaporanAnestesi.aranla_cm_nacl">{{dataLaporanAnestesi.aranla_cm_nacl||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Koloid</h4>
                            <p v-if="dataLaporanAnestesi.aranla_cm_kolaid">{{dataLaporanAnestesi.aranla_cm_kolaid||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Darah</h4>
                            <p v-if="dataLaporanAnestesi.aranla_cm_darah">{{dataLaporanAnestesi.aranla_cm_darah||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Cairan Keluar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Urin</h4>
                            <p v-if="dataLaporanAnestesi.aranla_ck_urin">{{dataLaporanAnestesi.aranla_ck_urin||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Pendarahan</h4>
                            <p v-if="dataLaporanAnestesi.aranla_ck_pendarahan">{{dataLaporanAnestesi.aranla_ck_pendarahan||"-"}}cc</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th colspan="4">Informasi Bayi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Bayi Lahir Jam</h4>
                      <p>{{dataLaporanAnestesi.aranla_bayi_lahir||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Jenis Kelamin</h4>
                      <p>{{getConfigDynamic(Config.mr.genderText,dataLaporanAnestesi.aranla_jenis_kelamin)||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Berat Badan</h4>
                      <p v-if="dataLaporanAnestesi.aranla_bb">{{dataLaporanAnestesi.aranla_bb||"-"}} gram</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>APGAR Score</h4>
                      <p v-if="dataLaporanAnestesi.aranla_apgar">{{dataLaporanAnestesi.aranla_apgar||"-"}}</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <table class="table table-bordered table-sm">
              <thead>
                <th colspan="6">Catatan Untuk di Ruangan (Berlaku 24 Jam Pasca Operasi)</th>
              </thead>
              <tbody>
                <tr>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Pengawasan <a href="javascript:;" data-popup="tooltip"
                      title="Total durasi pengawasan TD/Nadi/RR/Suhu tiap 1 atau 2 jam"><i
                      class="icon-info22"></i></a></h4>
                      <p v-if="dataLaporanAnestesi.aranla_pengawasan">{{dataLaporanAnestesi.aranla_pengawasan||"-"}} jam</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Bila terjadi kegawatan, menghubungi</h4>
                      <p>{{dataLaporanAnestesi.aranla_kegawatan||"-"}}</p>
                    </div>
                  </td>
                  <td colspan="2" width="33%"></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Program Cairan</h4>
                      <p>{{dataLaporanAnestesi.aranla_program_cairan||"-"}}</p>
                    </div>
                  </td>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Program Analgetik</h4>
                      <p>{{dataLaporanAnestesi.aranla_program_analgetik||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Jika terjadi mual, diberi</h4>
                      <p>{{dataLaporanAnestesi.aranla_jika_mual||"-"}}</p>
                    </div>
                  </td>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Jika menggigil, diberi</h4>
                      <p>{{dataLaporanAnestesi.aranla_jika_menggigil||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Jika tensi kurang dari <span class="font-weight-semibold text-info-800">
                      {{dataLaporanAnestesi.aranla_tekanan_darah_min_pengawasan||"-"}} /
                      {{dataLaporanAnestesi.aranla_tekanan_darah_max_pengawasan||"-"}} 
                      mmHG</span>,
                          lakukan:</h4>
                      <p>{{dataLaporanAnestesi.aranla_tensi_action||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Tanggal</h4>
                      <p>{{dataLaporanAnestesi.aranla_tanggal_pengawasan | moment("DD MMMM YYYY")}} {{dataLaporanAnestesi.aranla_jam_pengawasan}}</p>
                    </div>
                  </td>

                  <td colspan="2" rowspan="2" width="33%">
                    <div class="result_tab">
                      <h4>TTD & Nama Terang Dokter Anestesi</h4>
                      <p>{{dataLaporanAnestesi.aranla_nama_dokter_anestesi||"-"}}</p>
                      <div class="mt-1">
                        <img style="width:400px;height:200px;object-fit:contain;" :src="dataLaporanAnestesi.aranla_qr_dokter?uploader(dataLaporanAnestesi.aranla_qr_dokter):'https://placeholder.com/400x200'">
                      </div>
                    </div>
                  </td>
                  <td colspan="2" rowspan="2" width="33%">
                    <div class="result_tab">
                      <h4>TTD & Nama Terang Asisten Anestesi</h4>
                      <p>{{dataLaporanAnestesi.aranla_nama_asisten_anestesi||"-"}}</p>
                      <div class="mt-1">
                        <img style="width:400px;height:200px;object-fit:contain;" :src="dataLaporanAnestesi.aranla_qr_perawat?uploader(dataLaporanAnestesi.aranla_qr_perawat):'https://placeholder.com/400x200'">
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <button type="button" @click="unduhDokumenAnestesi" class="btn btn-primary float-right">Unduh Dokumen Laporan Anestesi<i class="icon-file-text3 ml-2"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <span class="text-center">Tidak ada data</span>
          </div>
        </div>
      </div>
      <div :class="activeTab == 2 ? 'tab-pane fade show active':'tab-pane fade'">
        <div v-if="Object.keys(dataLaporanOperasi||{}).length"  class="modal-body p-0">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Laporan Operasi</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Dokter Bedah </h4>
                          <p>{{dataOperasi.dokter_operator||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Asisten Perawat</h4>
                          <p>{{dataOperasi.perawat_asisten||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Perawat Instrumen </h4>
                          <p>{{dataOperasi.perawat_instrumen||"-"}}</p>
                        </div>
                      </td>

                      <td>
                        <div class="result_tab">
                          <h4>Dokter Anestesi </h4>
                          <p>{{dataOperasi.dokter_anestesi||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Perawat Anastesi</h4>
                          <p>{{dataOperasi.perawat_anestesi||"-"}}</p>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Jenis Anestesi </h4>
                          <p>{{dataOperasi.arano_jenis_new||dataOperasi.arano_jenis||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Diagnosa Pra Bedah</h4>
                          <p>{{dataOperasi.arano_diagnosa||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tindakan Operasi</h4>
                          <p>{{dataOperasi.arano_tindakan_operasi||"-"}}</p>
                        </div>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Intra Operasi</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Mulai operasi</h4>
                          <p v-if="dataLaporanOperasi.aranlo_start_operasi_tanggal">{{dataLaporanOperasi.aranlo_start_operasi_tanggal | moment("DD MMMM YYYY")}} {{dataLaporanOperasi.aranlo_start_operasi_jam}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Selesai Operasi </h4>
                          <p v-if="dataLaporanOperasi.aranlo_end_operasi_tanggal">{{dataLaporanOperasi.aranlo_end_operasi_tanggal | moment("DD MMMM YYYY")}} {{dataLaporanOperasi.aranlo_end_operasi_jam}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Desinfeksi kulit dengan </h4>
                          <p>{{dataLaporanOperasi.aranlo_desinfeksi_kulit||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jaringan yang dieksisi </h4>
                          <p>{{dataLaporanOperasi.aranlo_jaringan_dieksisi||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Cara Approach </h4>
                          <p>{{dataLaporanOperasi.aranlo_cara_approach||"-"}}</p>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Posisi Penderita </h4>
                          <p>{{dataLaporanOperasi.aranlo_posisi_penderita||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Durante Operationen </h4>
                          <p>{{dataLaporanOperasi.aranlo_durante||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>File Durante Operationen </h4>
                          <template v-if="dataLaporanOperasi.aranlo_durante_file">
                          <div class="card-body p-3"><img :src="dataLaporanOperasi.aranlo_durante_file" style="max-width: 100%;width: 160px;height: 160px;"><span class="mt-2 d-block" v-if="dataLaporanOperasi.aranlo_durante_update">Waktu Pemantauan : {{dataLaporanOperasi.aranlo_durante_update | moment("DD MMMM YYYY, HH:mm")}}</span></div>
                          </template>
                          <span v-else> - </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Post Operasi</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <div class="mb-1" v-for="(v,k) in (dataICD10LaporanOperasi||[])" :key="k">
                          <h4>Diagnosis Pasca Bedah #{{k+1}} (ICD10)</h4>
                          <p>{{v.text||"-"}}</p>
                          </div>
                        </div>
                        <div class="result_tab">
                          <h4>Diagnosa Pasca Bedah <span v-if="(dataICD10LaporanOperasi||[]).length">Lainnya</span></h4>
                          <p>{{dataLaporanOperasi.aranlo_diagnosa_pasca_bedah||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <div class="mb-1" v-for="(v,k) in (dataICD9LaporanOperasi||[])" :key="k">
                          <h4>Tindakan Operasi #{{k+1}} (ICD9)</h4>
                          <p>{{v.text||"-"}}</p>
                          </div>
                        </div>
                        <div class="result_tab">
                          <h4>Tindakan Operasi <span v-if="(dataICD9LaporanOperasi||[]).length">Lainnya</span></h4>
                          <p>{{dataLaporanOperasi.aranlo_diagnosa_jenis_operasi||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tindakan Operasi</h4>
                          <p>{{dataLaporanOperasi.aranlo_kegawatan_operasi||"-"}}</p>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Dikirim ke PA </h4>
                          <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataLaporanOperasi.aranlo_is_pa)||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Penggolongan Operasi</h4>
                          <ul v-if="(dataLaporanOperasi.aranlo_macam_operasi||[]).length">
                            <li v-for="(v,k) in (dataLaporanOperasi.aranlo_macam_operasi||[])" :key="k">
                              {{v}}
                            </li>
                          </ul>
                          <span v-if="!(dataLaporanOperasi.aranlo_macam_operasi||[]).length">Tidak ada data</span>

                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jumlah Perdarahan</h4>
                          <p>{{dataLaporanOperasi.aranlo_perdarahan||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Intruksi Pasca Bedah</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Intruksi Pasca Bedah</h4>
                          <p>{{dataLaporanOperasi.aranlo_intruksi_pasca_bedah||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Kontrol</h4>
                          <ul v-if="(dataLaporanOperasi.aranlo_kontrol||[]).length">
                            <li v-for="(v,k) in (dataLaporanOperasi.aranlo_kontrol||[])" :key="k">
                              {{v}}
                            </li>
                          </ul>
                          <span v-if="!(dataLaporanOperasi.aranlo_kontrol||[]).length">Tidak ada data</span>
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Puasa</h4>
                          <p>{{dataLaporanOperasi.aranlo_puasa||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Infus </h4>
                          <p>{{dataLaporanOperasi.aranlo_infus||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Antibiotika</h4>
                          <p>{{dataLaporanOperasi.aranlo_antibiotika||"-"}}</p>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <button type="button" @click="unduhDokumenOperasi" class="btn btn-primary float-right">Unduh Dokumen Laporan Operasi<i class="icon-file-text3 ml-2"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <span class="text-center">Tidak ada data</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  data(){
    return {
      activeTab: 1,
    }
  },
  computed: {
    dataOperasi(){
      return this.$parent.dataOperasi.dataOperasi
    },
    dataLaporanAnestesi(){
      return this.$parent.dataOperasi.dataLaporanAnestesi
    },
    dataLaporanOperasi(){
      return this.$parent.dataOperasi.dataLaporanOperasi
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    dataICD9LaporanOperasi(){
      return this.$parent.dataOperasi.dataICD9LaporanOperasi
    },
    dataICD10LaporanOperasi(){
      return this.$parent.dataOperasi.dataICD10LaporanOperasi
    },
    dataICD10LaporanAnestesiPraOperasi(){
      return this.$parent.dataOperasi.dataICD10LaporanAnestesiPraOperasi
    },
    dataICD10LaporanAnestesiPostOperasi(){
      return this.$parent.dataOperasi.dataICD10LaporanAnestesiPostOperasi
    },

    Config(){ return Config },
    
    distanceAnestesi(){
      let startDate = moment(moment(this.dataLaporanAnestesi.aranla_anastesi_date_start).format("YYYY-MM-DD")+' '+this.dataLaporanAnestesi.aranla_anastesi_jam_start)
      let endDate = moment(moment(this.dataLaporanAnestesi.aranla_anastesi_date_end).format("YYYY-MM-DD")+' '+this.dataLaporanAnestesi.aranla_anastesi_jam_end)
      let durationInHours = endDate.diff(startDate, 'hours')

      if(isNaN(durationInHours)){
        return 0 + ' Jam'
      }else{
        return durationInHours + ' Jam'
      }

    },
    distanceOperasi(){
      let startDate = moment(moment(this.dataLaporanAnestesi.aranla_operasi_date_start).format("YYYY-MM-DD")+' '+this.dataLaporanAnestesi.aranla_operasi_jam_start)
      let endDate = moment(moment(this.dataLaporanAnestesi.aranla_operasi_date_end).format("YYYY-MM-DD")+' '+this.dataLaporanAnestesi.aranla_operasi_jam_end)
      let durationInHours = endDate.diff(startDate, 'hours')

      if(isNaN(durationInHours)){
        return 0 + ' Jam'
      }else{
        return durationInHours + ' Jam'
      }

    }
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
    unduhDokumenOperasi(){
      let self = this
      let data = {exptype: 'xlsx', type: "export", id: this.dataOperasi.arano_id}
      // self.loadingOverlay = true
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadLaporanOperasi'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan_Operasi_${moment().format("YYYY-MM-DD")}.pdf`
              link.click() 
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    unduhDokumenAnestesi(){
      let self = this
      let data = {exptype: 'xlsx', type: "export", id: this.dataOperasi.arano_id}
      // self.loadingOverlay = true
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'laporanAnestesi'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan_Anestesi_${moment().format("YYYY-MM-DD")}.pdf`
              link.click() 
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
  }
}

</script>