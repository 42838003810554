<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Kajian Pra Operasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Subjektif </h4>
                      <p>{{dataPraOperasi.aranpop_subjektif||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Objektif</h4>
                      <p>{{dataPraOperasi.aranpop_objektif||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <div class="mb-1" v-for="(v,k) in (dataICD10KajianPraBedahPraOperasi||[])" :key="k">
                      <h4>Diagnosis Pra-Operasi #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                    </div>
                    <div class="result_tab">
                      <h4>Diagnosis Pra-Operasi <span v-if="(dataICD10KajianPraBedahPraOperasi||[]).length">Lainnya</span></h4>
                      <p>{{dataPraOperasi.aranpop_pra_operasi||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <div class="mb-1" v-for="(v,k) in (dataICD10KajianPraBedahOperasi||[])" :key="k">
                      <h4>Diagnosis Operasi #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                    </div>
                    <div class="result_tab">
                      <h4>Diagnosis Operasi <span v-if="(dataICD10KajianPraBedahOperasi||[]).length">Lainnya</span></h4>
                      <p>{{dataPraOperasi.aranpop_diagnosis_operasi||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Posisi Pasien </h4>
                      <p v-if="dataPraOperasi.aranpop_posisi_pasien != 'Lainnya'">{{dataPraOperasi.aranpop_posisi_pasien||"-"}}</p>
                      <p v-else>{{dataPraOperasi.aranpop_posisi_pasien_lainnya||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Estimasi Waktu</h4>
                      <p v-if="dataPraOperasi.aranpop_est && dataPraOperasi.aranpop_type_est">
                        {{dataPraOperasi.aranpop_est}} {{dataPraOperasi.aranpop_type_est}}
                      </p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Catatan Khusus</h4>
                      <p>{{dataPraOperasi.aranpop_catatan_khusus||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Lokasi Operasi pada Pasien</h6>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="">Tubuh (Depan - Belakang)</label>
                  <div class="op-loc" id="aranpop_loc_tubuh_depan">
                    <a :id="`aranpop_loc_tubuh_depan-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_tubuh_depan||[])" :key="k+'aranpop_loc_tubuh_depan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_tubuh_depan-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="dataPasien.ap_gender == 1 ? assetLocal('global_assets/images/operation-location/op-male-front-back.png') : assetLocal('global_assets/images/operation-location/op-female-front-back.png')" alt="">
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <label for="">Tubuh (Kanan - Kiri)</label>
                  <div class="op-loc" id="aranpop_loc_tubuh_samping">
                    <a :id="`aranpop_loc_tubuh_samping-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_tubuh_samping||[])" :key="k+'aranpop_loc_tubuh_samping'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_tubuh_samping-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="dataPasien.ap_gender == 1 ? assetLocal('global_assets/images/operation-location/op-male-right-left.png') : assetLocal('global_assets/images/operation-location/op-female-right-left.png')" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Kepala (Depan - Belakang)</label>
                  <div class="op-loc" id="aranpop_loc_kepala_depan">
                    <a :id="`aranpop_loc_kepala_depan-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_kepala_depan||[])" :key="k+'aranpop_loc_kepala_depan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_kepala_depan-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                  </div>
                  <img :src="assetLocal('global_assets/images/operation-location/op-head-front-back.png')" alt="">
                </div>

                <div class="col-md-6">
                  <label for="">Kepala (Kanan - Kiri)</label>
                  <div class="op-loc">
                    <a :id="`aranpop_loc_kepala_samping-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_kepala_samping||[])" :key="k+'aranpop_loc_kepala_samping'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_kepala_samping-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="assetLocal('global_assets/images/operation-location/op-head-left-right.png')" alt="">
                  </div>
                </div>

                <div class="col-md-6 mt-3">
                  <label for="">Punggung Tangan (Kiri - Kanan)</label>
                  <div class="op-loc">
                    <a :id="`aranpop_loc_punggung_tangan-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_punggung_tangan||[])" :key="k+'aranpop_loc_punggung_tangan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_punggung_tangan-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="assetLocal('global_assets/images/operation-location/op-backhand-left-right.png')" alt="">
                  </div>
                </div>

                <div class="col-md-6 mt-3">
                  <label for="">Telapak Tangan (Kiri - Kanan)</label>
                  <div class="op-loc">
                   <a :id="`aranpop_loc_telapak_tangan-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_telapak_tangan||[])" :key="k+'aranpop_loc_telapak_tangan'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_telapak_tangan-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="assetLocal('global_assets/images/operation-location/op-palm-left-right.png')" alt="">
                  </div>
                </div>

                <div class="col-md-6 mt-3">
                  <label for="">Punggung Kaki (Kiri - Kanan)</label>
                  <div class="op-loc">
                    <a :id="`aranpop_loc_punggung_kaki-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_punggung_kaki||[])" :key="k+'aranpop_loc_punggung_kaki'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_punggung_kaki-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                      
                    <img :src="assetLocal('global_assets/images/operation-location/op-instep-left-right.png')" alt="">
                  </div>
                </div>

                <div class="col-md-6 mt-3">
                  <label for="">Telapak Kaki (Kanan - Kiri)</label>
                  <div class="op-loc">
                    <a :id="`aranpop_loc_telapak_kaki-${k}`" v-for="(v,k) in (dataPraOperasi.aranpop_loc_telapak_kaki||[])" :key="k+'aranpop_loc_telapak_kaki'" class="op-loc-pointer" href="javascript:;"  :html="true" :style="`top:${v.y}%;left:${v.x}%`">
                      <b-popover v-if="v.value" :target="`aranpop_loc_telapak_kaki-${k}`" triggers="hover" placement="top">
                        <template>
                          <div class="text-center">
                            <span>{{v.value}}</span>
                          </div>
                        </template>
                      </b-popover>
                    </a>
                    <img :src="assetLocal('global_assets/images/operation-location/op-foot-right-left.png')" alt="">   
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Deskripsi Singkat
                            </h4>
                            <p>{{dataPraOperasi.aranpop_deskripsi_singkat||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  img {
    max-width: 100%;
  }
</style>

<script>

import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data(){
    return {
      activeTab: 1,
    }
  },
  computed: {
    dataPraOperasi(){
      return this.$parent.dataOperasi.dataPraOperasi
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    dataICD10KajianPraBedahPraOperasi(){
      return this.$parent.dataOperasi.dataICD10KajianPraBedahPraOperasi
    },
    dataICD10KajianPraBedahOperasi(){
      return this.$parent.dataOperasi.dataICD10KajianPraBedahOperasi
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  },
}

</script>