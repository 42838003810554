<template>
  <div>
    <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataPraAnestesiPerawat||{}).length || Object.keys(dataOperasi.dataPraAnestesiDokter||{}).length"><a href="javascript:;" @click="activeTab = 1" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Pra Anestesi </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataPraInduksi||{}).length"><a href="javascript:;" @click="activeTab = 8" :class="activeTab == 8 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Pra Induksi </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataPraOperasi||{}).length"><a href="javascript:;" @click="activeTab = 2" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Pra Operasi </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataCeklistKeselamatanPasien||{}).length"><a href="javascript:;" @click="activeTab = 3" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Checklist Keselamatan Pasien </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataCeklistKeselamatanAnestesi||{}).length"><a href="javascript:;" @click="activeTab = 4" :class="activeTab == 4 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Checklist Keselamatan Anestesi </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataLaporanAnestesi||{}).length || Object.keys(dataOperasi.dataLaporanOperasi||{}).length"><a href="javascript:;" @click="activeTab = 5" :class="activeTab == 5 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Laporan </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataDurante||{}).length"><a href="javascript:;" @click="activeTab = 6" :class="activeTab == 6 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Durante </a></li>
      <li class="nav-item" v-if="Object.keys(dataOperasi.dataRuangPulih||{}).length"><a href="javascript:;" @click="activeTab = 7" :class="activeTab == 7 ? 'nav-link active' : 'nav-link'" data-toggle="tab"> Ruang Pulih </a></li>
    </ul>
    
    <div class="tab-content flex-tab overflow-hidden">
      <div v-if="Object.keys(dataOperasi.dataPraAnestesiPerawat||{}).length || Object.keys(dataOperasi.dataPraAnestesiDokter||{}).length" :class="activeTab == 1 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <PraAnestesi/>
      </div>
      <div v-if="Object.keys(dataOperasi.dataPraInduksi||{}).length" :class="activeTab == 8 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <PraInduksi/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataPraOperasi||{}).length" :class="activeTab == 2 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <PraOperasi/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataCeklistKeselamatanPasien||{}).length" :class="activeTab == 3 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <ChecklistKeselamatanPasien/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataCeklistKeselamatanAnestesi||{}).length" :class="activeTab == 4 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <ChecklistKeselamatanAnestesi/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataLaporanAnestesi||{}).length || Object.keys(dataOperasi.dataLaporanOperasi||{}).length" :class="activeTab == 5 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <Laporan/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataDurante||{}).length" :class="activeTab == 6 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <Durante/>      
      </div>
      <div v-if="Object.keys(dataOperasi.dataRuangPulih||{}).length" :class="activeTab == 7 ? 'tab-pane h-100 fade show active':'tab-pane h-100 fade'">
        <RuangPulih/>      
      </div>
    </div>
  </div>
</template>

<script>

import PraAnestesi from '@/components/Ranap/OperasiCmp/PraAnestesi.vue'
import PraOperasi from '@/components/Ranap/OperasiCmp/PraOperasi.vue'
import ChecklistKeselamatanPasien from '@/components/Ranap/OperasiCmp/ChecklistKeselamatanPasien.vue'
import ChecklistKeselamatanAnestesi from '@/components/Ranap/OperasiCmp/ChecklistKeselamatanAnestesi.vue'
import Laporan from '@/components/Ranap/OperasiCmp/Laporan.vue'
import Durante from '@/components/Ranap/OperasiCmp/Durante.vue'
import RuangPulih from '@/components/Ranap/OperasiCmp/RuangPulih.vue'
import PraInduksi from '@/components/Ranap/OperasiCmp/PraInduksi.vue'

export default {
  data(){
    return {
      idKajian: null,
      activeTab: 1
    }
  },
  props: {
    dataOperasi: Object,
    operasiId: {
      type: Number,
      default: null
    },
  },
  components:{
    PraAnestesi,PraOperasi,ChecklistKeselamatanPasien,ChecklistKeselamatanAnestesi,Laporan,Durante,RuangPulih,PraInduksi
  }
}
</script>