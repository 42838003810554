<template>
  <div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>S</h2>
          </div>
          <span class="ml-2">Subjective</span>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Subjektif</h4>
                  <pre class="pre-input">{{rowSBAR.arancpd_subjektif||"-"}}</pre>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>O</h2>
          </div>
          <span class="ml-2">Objective</span>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Objektif</h4>
                  <pre class="pre-input">{{rowSBAR.arancpd_objektif||"-"}}</pre>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
          </div>
          <div class="card-body p-3">
            <div class="row gx-2" v-if="(rowSBAR.arancpd_status_lokalis||[]).length">
              <div class="col-lg-4">
                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                  <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in rowSBAR.arancpd_status_lokalis||[]" :key="k+'nav'">
                    <a href="javascript:;" data-toggle="tab" 
                    :class="activeAnat == k ? 'nav-link active':'nav-link'">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{v.name||"(Anatomi Baru)"}}</span>
                        <div>
                          <i class="icon-chevron-right"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-8">
                <div class="tab-content">
                  <div v-for="(v,k) in rowSBAR.arancpd_status_lokalis||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                      <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                        <div class="body_wrapper">
                          <img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
                        </div>
                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                          :style="`top:${v1.y}%;left:${v1.x}%`">
                          <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                            :title="v1.value"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-2" v-else>
              <div class="col-lg-12">
                <p class="text-center">Tidak ada Pemeriksaan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>A</h2>
          </div>
          <span class="ml-2">Assessment</span>
        </div>
      </div>
      <div class="card-body">
        <div class="card border shadow-0">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title font-weight-semibold mb-0">Diagnosa & Tindakan</h6>
          </div>

          <div class="card-body">
              <table class="table table-bordered">
              <tbody>
                  <tr v-for="(v,k) in dataICD10" :key="k+'diagnosaDok2'">
                  <td>
                      <div class="result_tab" v-if="!v.mi10_code">
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.auicd10d_diagnosis||"-"}}</p>
                      </div>
                      <div class="result_tab" v-else>
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Diagnosa Lainnya</h4>
                        <pre class="pre-input">{{rowSBAR.arancpd_diagnosa_text||"-"}}</pre>
                        </div>
                      </td>
                  </tr>
              </tbody>
              </table>
          </div>

          <div class="card-body">
              <table class="table table-bordered">
              <tbody>
                  <tr v-for="(v,k) in dataICD9" :key="k+'diagnosaDok'">
                  <td>
                      <div class="result_tab" v-if="!v.mi9_code">
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.auicd9d_tindakan||"-"}}</p>
                      </div>
                      <div class="result_tab" v-else>
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Tindakan Lainnya</h4>
                        <pre class="pre-input">{{rowSBAR.arancpd_tindakan_text||"-"}}</pre>
                        </div>
                      </td>
                  </tr>
              </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>P</h2>
          </div>
          <span class="ml-2">Planning</span>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <table class="table table-bordered table-sm">
            <tr>
              <td class="align-top" width="36%">
                <div class="result_tab">
                  <h4>Perencanaan</h4>
                  <pre class="pre-input">{{rowSBAR.arancpd_perencanaan||"-"}}</pre>
                </div>
              </td>
              <td class="align-top" width="64%">
                <div class="result_tab">
                  <h4 class="mb-1">Tindak Lanjut</h4>
                  <div class="row g-1">
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_resep == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Resep</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_lab == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Laboratorium</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_radiologi == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Radiologi</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_fisio == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Fisioterapi</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_operasi == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Tindakan Operasi</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_konsultasi == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Konsultasi</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_raber == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Rawat Bersama</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_alih_rawat == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Alih Rawat</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_pindah_ruang == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Pindah Ruang</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_rencana_operasi == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Rencana Operasi</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_stop_raber == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Stop Rawat Bersama</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_perawatan == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Masih Dalam Perawatan</span></div>
                    </div>
                    <div class="col-md-4" v-if="rowSBAR.arancpd_is_done_perawatan == 'Y'">
                      <div class="d-flex"><i class="icon-checkmark-circle text-success"></i><span class="ml-1 d-inline-block" style="line-height:1.25;">Selesai Perawatan</span></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        
        <div class="card" v-if="rowSBAR.arancpd_is_resep == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Resep</h6>
          </div>
          <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
              <tr>
                <th width="160">Jenis</th>
                <th width="320">Nama</th>
                <th width="150">Jumlah</th>
                <th width="210">Frekuensi</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (resepDokter||[])" :key="k">
                <td>{{getConfigDynamic(Config.mr.configResepRanap,v.aranrcp_jenis||"-")}}</td>
                <td>
                  <p v-if="v.aranrcp_item_id == 99999">
                  {{v.aranrcp_item_lainnya||"-"}}
                  </p>
                  <p v-else>
                  {{v.aranrcp_nama||"-"}}
                  </p>
                </td>
                <td>
                  <span>{{v.aranrcp_jumlah||"-"}} {{v.aranrcp_satuan||"-"}}</span>                  
                </td>
                <td>
                  <span v-if="v.aranrcp_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                  <span v-else>{{v.aranrcp_frekuensi_lainnya || "-"}}</span>
                </td>
                <td>
                  {{v.aranrcp_keterangan || "-"}}
                </td>
              </tr>
              <tr v-if="!(resepDokter||[]).length">
                <td colspan="99" class="text-center">Tidak ada Resep</td>
              </tr>
            </tbody>
          </table>
          <div class="card-body">
            <table class="table table-sm table-bordered">
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Catatan Resep</h4>
                    <p>{{rowSBAR.arancptlln_resep_catatan||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_lab == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Laboratorium</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered">
              <thead>
                <tr class="table-light">
                  <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Prioritas Pemeriksaan</h4>
                      <p><strong class="text-danger">
                      {{getConfigDynamic(Config.mr.configPrioritasPemeriksaan,rowSBAR.arancptll_prioritas)||"-"}}
                      </strong></p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Apakah pasien harus berpuasa?</h4>
                      <p>{{rowSBAR.arancptll_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Catatan Permintaan</h4>
                      <p>{{rowSBAR.arancptll_catatan_permintaan||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <h6 class="card-title font-weight-semibold">Pemeriksaan Laboratorium</h6>
            <div class="req-list-resume">
                <template v-for="(v,k) in rowSBAR.arancptll_data">
                  <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                  <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">
                          {{v.head||"-"}}</h6>
                      </div>
                      <div class="card-body py-2">
                      <template v-for="v1,k1 in (v.dubData||[])">
                          <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                          :key="k1+'labdatas'">
                          <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                          <template v-for="v2,k2 in (v1.data||[])">
                              <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                              <i
                                  class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                              <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                              </div>
                          </template>
                          </div>
                      </template>
                      </div>
                  </div>
                  </div>
                </template>

                <div class="col-md-4 col-lg-3" v-if="rowSBAR.arancptll_data_lainnya">
                    <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">Item
                        Pemeriksaan Lainnya</h6>
                    </div>
                    <div class="card-body py-2">
                        <div class="req-list">
                        <div>
                            <i
                            class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{rowSBAR.arancptll_data_lainnya||"-"}}</b>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
              
            </div>
          </div>
          <div class="card-body" v-if="rowSBAR.arancptll_is_transfusi == 'Y'">
            <h6 class="card-title font-weight-semibold">Tindakan Transfusi</h6>
            <div class="row g-3 mt-3">
              <div class="col-lg-12">
                  <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Transfusi</h6>
                  </div>
                  <div class="card-body py-2">

                    <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Indikasi Transfusi</h4>
                                  <p>{{rowSBAR.arancptll_tr_indikasi_transfusi||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Golongan Darah</h4>
                                  <p>{{rowSBAR.arancptll_tr_golongan_darah||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Jumlah Kantong</h4>
                                  <p>{{rowSBAR.arancptll_tr_jumlah_kantong||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Jenis Darah yang Diperlukan</h4>
                                  <p>{{rowSBAR.arancptll_tr_jenis_darah||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tanggal Diperlukan</h4>
                                  <p>{{rowSBAR.arancptll_tr_tanggal | moment("DD MMMM YYYY")}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Inform Consent</h4>
                                  <a href="javascript:;" @click="openInformConsentDetailSingle(rowSBAR,'arancptll_tr_inform_consent_id','Tindakan Transfusi')" v-if="rowSBAR.arancptll_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                    <i class="icon-file-eye2"></i>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Pasien Pernah Transfusi</h4>
                                  <p>{{getConfigDynamic(Config.mr.pernahTidakPernah,rowSBAR['arancptll_tr_pasien_transfusi'])||"-"}}</p>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="rowSBAR.arancptll_is_ph == 'Y'">
            <h6 class="card-title font-weight-semibold">Tindakan Phlebotomy</h6>
            <div class="row g-3 mt-3">
              <div class="col-lg-12">
                  <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Phleubotomy</h6>
                    </div>
                    <div class="card-body py-2">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tanggal dilakukan</h4>
                                  <p>{{rowSBAR.arancptll_ph_tanggal|moment('DD MMMM YYYY')}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Jumlah Kantong</h4>
                                  <p>{{rowSBAR.arancptll_ph_jml_kantong||0}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Inform Consent</h4>
                                    <a href="javascript:;" @click="openInformConsentDetailSingle(rowSBAR,'arancptll_ph_inform_consent_id','Tindakan Phlebotomy')" v-if="rowSBAR.arancptll_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                      <i class="icon-file-eye2"></i>
                                    </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="card-body" v-if="rowSBAR.arancptll_is_hiv == 'Y'">
            <h6 class="card-title font-weight-semibold">Pemeriksaan HIV</h6>
            <div class="row g-3 mt-3">
              <div class="col-lg-12">
                <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Pemeriksaan HIV</h6>
                  </div>
                  <div class="card-body py-2">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tanggal dilakukan</h4>
                                <p>{{rowSBAR.arancptll_hiv_date|moment('DD MMMM YYYY')}}</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_radiologi == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Radiologi</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered mb-3">
              <thead>
                <tr class="table-light">
                  <th colspan="4" class="text-uppercase">Permintaan Radiologi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Prioritas Pemeriksaan</h4>
                      <p><strong class="text-danger">
                      {{getConfigDynamic(Config.mr.configPrioritasPemeriksaan,rowSBAR.arancptlr_prioritas)||"-"}}
                      </strong></p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                      <p>{{rowSBAR.arancptlr_alergi == 'Y' ? "Ya": "Tidak"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="req-list-resume mb-3">
              <div class="row g-3">
                <template v-for="(v,k) in rowSBAR.arancptlr_data">
                    <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                    v-if="isShowHeadRadiologi(k)">
                    <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{
                getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                        </div>
                        <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                            <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                            <i
                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                            <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                            

                            <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                            </a>

                            </div>
                        </template>
                        </div>
                    </div>
                    </div>
                </template>
                <div class="col-md-4 col-lg-3" v-if="rowSBAR.arancptlr_data_lainnya">
                    <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                          <h6 class="card-title text-uppercase font-weight-semibold">Item
                          Pemeriksaan Lainnya</h6>
                      </div>
                      <div class="card-body py-2">
                          <div class="req-list">
                          <div>
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">{{rowSBAR.arancptlr_data_lainnya||"-"}}</b>
                          </div>
                          </div>
                      </div>
                    </div>
                </div>
                <span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_fisio == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Fisioterapi</h6>
          </div>
          <div class="card-body p-3">
            <div class="row g-2">
              <div class="col-md-4" v-if="(rowSBAR.arancptlf_elektroterapi||[]).length">
                <div class="card mb-0 shadow-0 border">
                  <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Elektroterapi</h6>
                  </div>
                  <div class="card-body py-2">
                    <div class="req-list">
                      <div v-for="(v,k) in (rowSBAR.arancptlf_elektroterapi||[])" :key="k">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span v-if="v=='LE'" class="align-middle">{{rowSBAR.arancptlf_elektroterapi_text||"-"}}</span>
                        <span v-else class="align-middle">{{v||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-if="(rowSBAR.arancptlf_mekanoterapi||[]).length">
                <div class="card mb-0 shadow-0 border">
                  <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Mekanoterapi</h6>
                  </div>
                  <div class="card-body py-2">
                    <div class="req-list">
                      <div v-for="(v,k) in (rowSBAR.arancptlf_mekanoterapi||[])" :key="k">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span v-if="v=='LM'" class="align-middle">{{rowSBAR.arancptlf_mekanoterapi_text||"-"}}</span>
                        <span v-else class="align-middle">{{v||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-if="(rowSBAR.arancptlf_latihan||[]).length">
                <div class="card mb-0 shadow-0 border">
                  <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Latihan</h6>
                  </div>
                  <div class="card-body py-2">
                    <div class="req-list">
                      <div v-for="(v,k) in (rowSBAR.arancptlf_latihan||[])" :key="k">
                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <span v-if="v=='LL'" class="align-middle">{{rowSBAR.arancptlf_latihan_text||"-"}}</span>
                        <span v-else class="align-middle">{{v||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12" v-if="!((rowSBAR.arancptlf_elektroterapi||[]).length && (rowSBAR.arancptlf_mekanoterapi||[]).length && (rowSBAR.arancptlf_latihan||[]).length)">
                <p>Tidak ada Data</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="card" v-if="rowSBAR.arancpd_is_operasi == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Operasi</h6>
          </div>
          <div class="card-body p-3">
            <div class="mb-3">
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Waktu Operasi</h4>
                      <p>{{rowSBAR.arancptlo_tanggal | moment("DD MMMM YYYY")}} {{rowSBAR.arancptlo_waktu}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan Anestesi</h4>
                      <p>{{rowSBAR.arancptlo_tindakan_anestesi||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <label for="">Tindakan Operasi</label>
              <div class="row g-2">
                <template v-for="(v,k) in rowSBAR.arancptlo_tindakan">
                    <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                    v-if="isShowHeadOperasi(k)">
                    <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                        </div>
                        <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                            <div v-if="isShowOperasi(k,k1)" :key="k1+'radiosubdatas'">
                            <i
                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                            <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                            
                            <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,v.head)" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                            </a>
                        
                            </div>
                        </template>
                        </div>
                    </div>
                    </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        
        <div class="card" v-if="rowSBAR.arancpd_is_konsultasi == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Konsultasi</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Nama Dokter</h4>
                    <p>{{rowSBAR.konsultasi_dokter||"-"}}</p>
                  </div>
                </td>
                <td width="75%">
                  <div class="result_tab">
                    <h4>Ikhtisar Klinik & Pemeriksaan Penunjang</h4>
                    <p>{{rowSBAR.arancptlln_konsultasi_ikhtisar||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        
        <div class="card" v-if="rowSBAR.arancpd_is_raber == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Rawat Bersama</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Nama Dokter</h4>
                    <p>{{rowSBAR.raber_dokter||"-"}}</p>
                  </div>
                </td>
                <td width="75%">
                  <div class="result_tab">
                    <h4>Ikhtisar Klinik & Pemeriksaan Penunjang</h4>
                    <p>{{rowSBAR.arancptlln_raber_ikhtisar||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_alih_rawat == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Alih Rawat</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Nama Dokter</h4>
                    <p>{{rowSBAR.alih_rawat_dokter||"-"}}</p>
                  </div>
                </td>
                <td width="75%">
                  <div class="result_tab">
                    <h4>Ikhtisar Klinik & Pemeriksaan Penunjang</h4>
                    <p>{{rowSBAR.arancptlln_alih_rawat_ikhtisar||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_pindah_ruang == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Permintaan Pindah Ruang</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Jenis Rawat Inap</h4>
                    <p>{{getConfigDynamic(Config.mr.mJenisRanapNormal,rowSBAR.aranctlpr_jenis_ranap)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Kelas</h4>
                    <p>{{rowSBAR.kelas||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Bangsal</h4>
                    <p>{{rowSBAR.bangsal||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Tipe Pemeriksaan</h4>
                    <p>{{getConfigDynamic(Config.mr.statusLanjutPindahRuangRanap, rowSBAR.aranctlpr_type)||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
            <table class="table table-bordered table-sm">
              <template v-if="rowSBAR.aranctlpr_type == 'ICU'">
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Tanda-tanda Vital</h4>
                      <ul v-if="(rowSBAR.aranctlpr_icu_ttv||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_icu_ttv||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Nilai Laboratorium</h4>
                      <ul v-if="(rowSBAR.aranctlpr_icu_lab||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_icu_lab||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Radiografi / Ultrasonografi</h4>
                      <ul v-if="(rowSBAR.aranctlpr_icu_radiologi||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_icu_radiologi||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Pemeriksaan Fisisk (Onset Akut )</h4>
                      <ul v-if="(rowSBAR.aranctlpr_icu_pemeriksaan_fisik||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_icu_pemeriksaan_fisik||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'NICU'">
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Tanda-tanda Vital</h4>
                      <ul v-if="(rowSBAR.aranctlpr_picu_ttv||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_picu_ttv||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Pemeriksaan Radiografi</h4>
                      <ul v-if="(rowSBAR.aranctlpr_picu_radiografi||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_picu_radiografi||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'AIRBORNE'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Kriteria Pasien Masuk Airborne</h4>
                      <strong class="text-uppercase">{{rowSBAR.aranctlpr_airborne||"-"}}</strong>
                      <template v-if="rowSBAR.aranctlpr_airborne == 'SUSPEK TB'">
                        <ul><li>Klinis</li><li>Radiologis</li></ul>
                      </template>
                      <p v-if="rowSBAR.aranctlpr_airborne == 'PENYAKIT LAIN'">
                        {{rowSBAR.aranctlpr_airborne_text||"-"}}
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'IMUNOSUPRESI'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Kriteria Pasien Masuk Imunosupresi</h4>
                      <strong class="text-uppercase">{{rowSBAR.aranctlpr_imunosupresi||"-"}}</strong>
                      <p v-if="rowSBAR.aranctlpr_imunosupresi == 'GANGGUAN IMUNOLOGIS LAIN'">
                        {{rowSBAR.aranctlpr_imunosupresi_text||"-"}}
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'KICU'">
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Tanda-tanda Vital</h4>
                      <ul v-if="(rowSBAR.aranctlpr_kicu_ttv||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_kicu_ttv||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Pemeriksaan Laboratorium</h4>
                      <ul v-if="(rowSBAR.aranctlpr_kicu_nilai_lab||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_kicu_nilai_lab||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'KNICU'">
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Kriteria umum</h4>
                      <ul v-if="(rowSBAR.aranctlpr_knicu_kriteria_umum||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_knicu_kriteria_umum||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Tanda-tanda Vital</h4>
                      <ul v-if="(rowSBAR.aranctlpr_knicu_ttv||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_knicu_ttv||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'KAIRBORNE'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Keluar Isolasi Airborne</h4>
                      <ul v-if="(rowSBAR.aranctlpr_kairborne||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_kairborne||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="rowSBAR.aranctlpr_type == 'KIMUNOSUPRESI'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Keluar Imunosupresi</h4>
                      <ul v-if="(rowSBAR.aranctlpr_kimunosupresi||[]).length">
                        <li v-for="(v,k) in (rowSBAR.aranctlpr_kimunosupresi||[])" :key="k">
                          {{v||"-"}}
                        </li>
                      </ul>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="card" v-if="rowSBAR.arancpd_is_rencana_operasi == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Rencana Operasi</h6>
          </div>
          <div class="card-body p-3">
            <div class="mb-3">
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Waktu Operasi</h4>
                      <p>{{rowSBAR.arancptlro_tanggal | moment("DD MMMM YYYY")}} {{rowSBAR.arancptlro_waktu}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tindakan Anestesi</h4>
                      <p>{{rowSBAR.arancptlro_tindakan_anestesi||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <label for="">Tindakan Operasi</label>
              <div class="row g-2">
                <template v-for="(v,k) in rowSBAR.arancptlro_tindakan">
                    <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                    v-if="isShowHeadRencanaOperasi(k)">
                    <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                        </div>
                        <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                            <div v-if="isShowRencanaOperasi(k,k1)" :key="k1+'radiosubdatas'">
                            <i
                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                            <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                            
                            <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,v.head)" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                            </a>
                        
                            </div>
                        </template>
                        </div>
                    </div>
                    </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        
        <div class="card" v-if="rowSBAR.arancpd_is_done_perawatan == 'Y'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Selesai Perawatan</h6>
          </div>
          <div class="card-body p-3">
            <div class="mb-3">
              <table class="table table-bordered table-sm">
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Tindak lanjut</h4>
                      <p>{{rowSBAR.arcptlsp_type||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <template v-if="Object.keys(rowSP||{}).length">
                  <template v-if="rowSBAR.arcptlsp_type == 'Meninggal'">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Meninggal</h4>
                          <p v-if="rowSP.date_of_death">{{rowSP.date_of_death | moment("DD MMMM YYYY") }}</p>
                          <span v-else> - </span>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jam Meninggal</h4>
                          <p>{{rowSP.time_of_death}}</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="rowSBAR.arcptlsp_type == 'Rujuk'">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Alasan Merujuk</h4>
                          <p>{{rowSP.alasan_merujuk||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>{{rowSP.alasan_merujuk||"-"}}</h4>
                          <p>{{rowSP.alasan_merujuk_text||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Peralatan Medis yang Terpasang</h4>
                          <p>{{rowSP.peralatan_is_terpasang == "Y" ? "Ya":"Tidak"}}</p>
                          <span v-for="(v,k) in (rowSP.peralatan_yang_terpasang||[])" :key="k">
                            {{v}}
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Faskes Rujukan</h4>
                          <p>{{rowSP.faskes_rujuk_sisrute_text||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Poli</h4>
                          <p>{{rowSP.nama_poli||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nama Dokter</h4>
                          <p>{{rowSP.dokter||"-"}}</p>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3">
                        <div class="result_tab">
                          <h4>Perawatan Pasien yang dibutuhkan</h4>
                          <p>{{rowSP.perawatan_yang_diperlukan||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
export default {
  props: {
    rowData: Object,
  },
  data(){
    return {
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {},
    }
  },
  computed: {
    rowSBAR(){
      return this.rowData.rowData
    },
    rowSP(){
      return this.rowData.rowSP
    },
    dataICD9(){
      return this.rowData.dataICD9
    },
    dataICD10(){
      return this.rowData.dataICD10
    },
    resepDokter(){
      return this.rowData.resepDokter
    },
    Config(){ return Config },
    
    selectedLabInput() {
      let data = []
      for (let ik = 0; ik < (this.rowSBAR.arancptll_data || []).length; ik++) {
        for (let jk = 0; jk < (this.rowSBAR.arancptll_data[ik]['dubData'] || []).length; jk++) {
          for (let kk = 0; kk < (this.rowSBAR.arancptll_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
            if (this.rowSBAR.arancptll_data[ik]['dubData'][jk]['data'][kk]['selected']) {
              data.push(this.rowSBAR.arancptll_data[ik]['dubData'][jk]['data'][kk]['text'])
            }
          }
        }
      }
      if (this.rowSBAR.arancptll_data_lainnya) {
        data.push(this.rowSBAR.arancptll_data_lainnya)
      }
      return data.join(", ")
    },
    selectedRadioInput() {
      let data = []
      for (let i = 0; i < (this.rowSBAR.arancptlr_data || []).length; i++) {
        for (let j = 0; j < (this.rowSBAR.arancptlr_data[i]['data'] || []).length; j++) {
            if (this.rowSBAR.arancptlr_data[i]['data'][j]['selected']) {
                data.push(this.rowSBAR.arancptlr_data[i]['data'][j]['text'])
            }
        }
      }
      if (this.rowSBAR.arancptlr_data_lainnya) {
        data.push(this.rowSBAR.arancptlr_data_lainnya)
      }
      return data.join(", ")
    }
  },
  methods: {  
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    changeTabAnat(k){
      this.activeAnat = k
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    openInformConsentDetailSingle(row,field,tindakan){
      Gen.apiRest('/do/' + 'RanapCPPTDokter', {
        data: {
            type: 'get-data-ic-single',
            id: row[field],
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row 
        this.rowIC.tindakan = tindakan
        this.rowIC.id_tindakan = row[field]
        this.rowIC.field = field
      
        this.openModalICDetail = true                   
      })
    },
    
    openInformConsentDetail(v,k1,k,kat){
      Gen.apiRest('/do/' + 'RanapCPPTDokter', {
          data: {
              type: 'get-data-ic-single',
              id: v.inform_consent,
          }
      }, 'POST').then(res => {
          this.rowIC = res.data.row 
          this.rowIC.tindakan = v.text  + "("+kat+")"
          this.rowIC.id_tindakan = v.inform_consent
            
          this.rowIC.k1 = k1
          this.rowIC.k = k

          this.openModalICDetail = true                   
      })
    },
    
    isShowHeadLab(i) {
      let isData = 0
      for (let j = 0; j < (this.rowSBAR.arancptll_data[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.rowSBAR.arancptll_data[i]['dubData'][j]['data'] || []).length; k++) {
              if (this.rowSBAR.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
                  isData += 1
              }
          }
      }
      return isData
    },
    isShowHeadSubHeadLab(i, j) {
      let isData = 0
      for (let k = 0; k < (this.rowSBAR.arancptll_data[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.rowSBAR.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
          }
      }
      return isData
    },
    isShowLab(i, j, k) {
      let isData = 0
      if (this.rowSBAR.arancptll_data[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
      }
      return isData
    },
    isShowHeadRadiologi(j) {
      let isData = 0
      for (let k = 0; k < (this.rowSBAR.arancptlr_data[j]['data'] || []).length; k++) {
        if (this.rowSBAR.arancptlr_data[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowRadiologi(j, k) {
        let isData = 0
        if (this.rowSBAR.arancptlr_data[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },

    isShowHeadOperasi(j) {
      let isData = 0
      for (let k = 0; k < (this.rowSBAR.arancptlo_tindakan[j]['data'] || []).length; k++) {
        if (this.rowSBAR.arancptlo_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowOperasi(j, k) {
        let isData = 0
        if (this.rowSBAR.arancptlo_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    isShowHeadRencanaOperasi(j) {
      let isData = 0
      for (let k = 0; k < (this.rowSBAR.arancptlro_tindakan[j]['data'] || []).length; k++) {
        if (this.rowSBAR.arancptlro_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowRencanaOperasi(j, k) {
        let isData = 0
        if (this.rowSBAR.arancptlro_tindakan[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },

  }

}
</script>