<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Pra Anestesi</h6>
        </div>
        <div class="card-header pb-0">
          <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
            <li class="nav-item"><a href="javascript:;" @click="activeTab = 1" :class="activeTab == 1 ? 'nav-link active': 'nav-link'">Kajian Pra Sedasi/Anestesi Perawat</a>
            </li>
            <li class="nav-item"><a href="javascript:;" @click="activeTab = 2" :class="activeTab == 2 ? 'nav-link active': 'nav-link'">Kajian Pra Sedasi/Anestesi Dokter</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mt-3">
              <div class="tab-content">
                <div :class="activeTab == 1 ? 'tab-pane fade show active':'tab-pane fade'">
                  <div v-if="Object.keys(dataPraAnestesiPerawat||{}).length" class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Kebiasaan</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Merokok</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_merokok)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_merokok == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_merokok_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alkohol</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_alkohol)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_alkohol == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_alkohol_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Kopi/Teh/Soda</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_kopi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_kopi == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_kopi_text||"-"}}</small>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Olahraga Rutin</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_is_olahraga)||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">PENGOBATAN : sebutkan dosis atau jumlah
                            per hari</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive mt-2">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Penggunaan aspirin rutin</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_aspirin_rutin)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_aspirin_rutin == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_aspirin_rutin_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Obat analgetik</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_anti_sakit)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_anti_sakit == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_anti_sakit_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Injeksi steroid tahun-tahun terakhir</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_injeksi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_injeksi == 'Y'">Sebanyak {{dataPraAnestesiPerawat.aranpap_injeksi_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Alergi</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="table-responsive" v-if="dataPraAnestesiPerawat.aranpap_has_alergi == 'Y'">
                                  <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                          <th><strong>Jenis</strong></th>
                                          <th><strong>Nama</strong></th>
                                        </tr>
                                        <tr v-for="(v,k) in (dataPraAnestesiPerawat.aranpap_alergi||[])" :key="k+'ob'">
                                          <td>
                                              <div class="result_tab">
                                              {{v.jenis||"-"}}
                                              </div>
                                          </td>
                                          <td>
                                              <div class="result_tab">
                                              {{v.name||"-"}}
                                              </div>
                                          </td>
                                        </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="form-row" v-else>
                                  <span>Tidak Ada Alergi</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="table-responsive mt-2">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alergi latek</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_alergi_latek)||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alergi plester</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_alergi_plester)||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Riwayat Keluarga</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Serangan jantung</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_jantung)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_jantung == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_jantung_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Hipertensi</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_hipertensi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_hipertensi == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_hipertensi_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Tuberkulose</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_tuberkulose)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_tuberkulose == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_tuberkulose_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Permasalahan dalam pembiusan</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_permasalahan_pembiusan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_permasalahan_pembiusan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_permasalahan_pembiusan_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Operasi jantung coroner</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_operasi_jantung)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_operasi_jantung == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_operasi_jantung_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Diabetes</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_diabetes)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_diabetes == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_diabetes_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Penyakit berat lainnya</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_penyakit_berat)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_penyakit_berat == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_penyakit_berat_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Perdarahan tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_pendarahan_tdk_normal)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_pendarahan_tdk_normal == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_pendarahan_tdk_normal_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Pembekuan darah tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_kel_pembekuan_tdk_normal)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_kel_pembekuan_tdk_normal == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_kel_pembekuan_tdk_normal_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Riwayat Penyakit Pasien
                          </h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Perdarahan yang tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_pendarahan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_pendarahan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_pendarahan_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Pembekuan darah tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_pembekuan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_pembekuan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_pembekuan_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sakit maag</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_maag)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_maag == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_maag_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Anemia</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_anemia)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_anemia == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_anemia_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Asma</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_asma)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_asma == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_asma_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sesak Nafas</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_sesak_nafas)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_sesak_nafas == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_sesak_nafas_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Diabetes</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_diabetes)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_diabetes == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_diabetes_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Pingsan</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_pingsan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_pingsan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_pingsan_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Serangan jantung/ nyeri dada</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_serangan_jantung)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_serangan_jantung == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_serangan_jantung_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Hepatitis/ sakit kuning</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_hepatitis)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_hepatitis == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_hepatitis_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Hipertensi</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_hipertensi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_hipertensi == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_hipertensi_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sumbatan jalan napas atau saat tidur mengorok</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Apakah pasien pernah mendapat transfusi darah</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan == 'Y'">{{dataPraAnestesiPerawat.aranpap_riwayat_pas_sumbatan_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Apakah pasien pernah diperiksa untuk diagnosa HIV?</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_diagnosa_hiv)||"-"}}</p>
                                      <small v-if="dataPraAnestesiPerawat.aranpap_diagnosa_hiv == 'Y'">{{dataPraAnestesiPerawat.aranpap_diagnosa_hiv_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Hasil pemeriksaan HIV</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.positifNegatifOpt,dataPraAnestesiPerawat.aranpap_hasil_hiv)||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>

                          <div class="mt-2">
                            <div class="table-responsive">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="4">Apakah pasien memakai:</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Lensa Kontak</h4>
                                        <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_lensa_kontak)||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Kacamata </h4>
                                        <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_kacamata)||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Alat bantu dengar </h4>
                                        <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_alat_bantu_dengar)||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Gigi Palsu </h4>
                                        <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_gigi_palsu)||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div v-if="dataPraAnestesiPerawat.aranpap_riwayat_operasi == 'Y'">
                            <h6 class="font-weight-semibold text-indigo mt-2 mb-2"></h6>
                            <div class="table-responsive">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="4">Riwayat operasi, tahun dan jenis operasi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Anestesi local-komplikasi/reaks</h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_anestesi_local_komplikasi||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Anestesi regional-komplikasi/reaksi </h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_anestesi_regional_komplikasi||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Anesthesia umum-komplikasi/reaksi </h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_anestesi_umum_komplikasi||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Tanggal terakhir kali periksa kesehatan ke dokter</h4>
                                        <p v-if="dataPraAnestesiPerawat.aranpap_tanggal_terakhir_diperiksa">{{dataPraAnestesiPerawat.aranpap_tanggal_terakhir_diperiksa|moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Dimana</h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_diperiksa_dimana||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Untuk Penyakit Gangguan Apa </h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_untuk_penyakit_apa||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-12" v-if="dataPasien.ap_gender == 2">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Khusus Pasien Perempuan</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-12">
                              <table class="table table-bordered  table-sm patient-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Jumlah Kehamilan</h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_jumlah_kehamilan||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Jumlah Anak </h4>
                                        <p>{{dataPraAnestesiPerawat.aranpap_jumlah_anak||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Menstruasi Terakhir </h4>
                                        <p v-if="dataPraAnestesiPerawat.aranpap_menstruasi_terakhir">{{dataPraAnestesiPerawat.aranpap_menstruasi_terakhir | moment("DD MMMM YYYY")}}</p>
                                        <p v-else> - </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Menyusui </h4>
                                        <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiPerawat.aranpap_menyususi)||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-12">
                      <span class="text-center">Tidak ada data</span>
                    </div>
                  </div>
                </div>
                <div :class="activeTab == 2 ? 'tab-pane fade show active':'tab-pane fade'">
                  <div v-if="Object.keys(dataPraAnestesiDokter||{}).length"  class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Pengkajian Sistem</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Hilangnya gigi</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_hilang_gigi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_hilang_gigi == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_hilang_gigi_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Masalah mobilisasi</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_mobilisasi)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_mobilisasi == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_mobilisasi_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Leher pendek</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_leher_pendek)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_leher_pendek == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_leher_pendek_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Batuk</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_batuk)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_batuk == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_batuk_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sesak Napas</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_sesak)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_sesak == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_sesak_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Baru saja menderita infeksi saluran napas atas</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_infeksi_saluran_atas)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_infeksi_saluran_atas == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_infeksi_saluran_atas_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Periode menstruasi tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_mens_tidak_normal)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_mens_tidak_normal == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_mens_tidak_normal_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sakit dada</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_sakit_dada)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_sakit_dada == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_sakit_dada_text||"-"}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Denyut jantung tidak normal</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_denyut_jantung)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_denyut_jantung == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_denyut_jantung_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Muntah</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_muntah)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_muntah == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_muntah_text||"-"}}</small>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Sulit kencing</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_sulit_kencing)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_sulit_kencing == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_sulit_kencing_text||"-"}}</small>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Kejang</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_kejang)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_kejang == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_kejang_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Sedang hamil</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_hamil)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_hamil == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_hamil_text||"-"}}</small>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Pingsan</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_pingsan)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_pingsan == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_pingsan_text||"-"}}</small>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Obesitas</h4>
                                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataPraAnestesiDokter.aranpa_ps_obesitas)||"-"}}</p>
                                      <small v-if="dataPraAnestesiDokter.aranpa_ps_obesitas == 'Y'">{{dataPraAnestesiDokter.aranpa_ps_obesitas_text||"-"}}</small>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Kesadaran</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_kesadaran||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Visus</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_visus||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Faring</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_faring||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Gigi Palsu</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_gigi_palsu||"-"}}</p>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Paru-paru</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_paru||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Jantung</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_jantung||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Abdomen</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_abdomen||"-"}}</p>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Ekstremitas</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_ekstremitas||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Neurologi (bila bisa diperiksa)</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_neuorologi||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colspan="3">
                                    <div class="result_tab">
                                      <h4>Keterangan</h4>
                                      <p>{{dataPraAnestesiDokter.aranpa_ku_keterangan_ttv||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tekanan Darah</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_tekanan_darah_min || dataPraAnestesiDokter.aranpa_ttv_tekanan_darah_max">{{dataPraAnestesiDokter.aranpa_ttv_tekanan_darah_min}} / {{dataPraAnestesiDokter.aranpa_ttv_tekanan_darah_max}} mmHG</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Nadi</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_nadi">{{dataPraAnestesiDokter.aranpa_ttv_nadi||"-"}}/mnt</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Gula Darah</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_gula_darah">{{dataPraAnestesiDokter.aranpa_ttv_gula_darah||"-"}}mg/dL</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Pernafasan</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_pernafasan">{{dataPraAnestesiDokter.aranpa_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>SPO2</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_spo2">{{dataPraAnestesiDokter.aranpa_ttv_spo2||"-"}}%</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Suhu</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_suhu">{{dataPraAnestesiDokter.aranpa_ttv_suhu||"-"}}C</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Berat Badan</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_weight">{{dataPraAnestesiDokter.aranpa_ttv_weight||"-"}}kg</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tinggi</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_height">{{dataPraAnestesiDokter.aranpa_ttv_height||"-"}}Cm</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Lingkar Kepala</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_lingkar_kepala">{{dataPraAnestesiDokter.aranpa_ttv_lingkar_kepala||"-"}}cm</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-if="dataPasien.ap_usia >= 1">
                                    <div class="result_tab">
                                        <h4>BMI</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_bmi">{{dataPraAnestesiDokter.aranpa_ttv_bmi}}m2</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td v-if="dataPasien.ap_usia <= 15">
                                        <div class="result_tab">
                                        <h4>Luas Permukaan Tubuh Anak</h4>
                                        <p v-if="dataPraAnestesiDokter.aranpa_ttv_luas_permukaan_anak">{{dataPraAnestesiDokter.aranpa_ttv_luas_permukaan_anak}}kg/m2</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

     
                    <div class="col-12" v-if="Object.keys(dataPenunjang||{}).length">
                        <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>Hasil Pemeriksaan Lab</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <h6>Hasil Pemeriksaan Radiologi</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <h6>Hasil Pemeriksaan Lab Dokter</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Diagnosis</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="req-list">
                            <h6>Klarifikasi berdasarkan ASA</h6>
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">
                                {{getConfigDynamic(Config.mr.mDiagnosisASA,dataPraAnestesiDokter.aranpa_ku_diagnosis_asa)||"-"}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-3">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Rekomendasi Tindakan Anetesi yang
                            Dipilih</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Tipe Anestesi</h4>
                                      <p>{{getConfigDynamic(Config.mr.mTipeAnestesi,dataPraAnestesiDokter.aranpa_rek_tipe_anestesi)||"-"}}</p>

                                      <template v-if="dataPraAnestesiDokter.aranpa_rek_tipe_anestesi == 'Anestesi Umum'">
                                        <small v-for="(v,k) in (dataPraAnestesiDokter.aranpa_rek_tipe_anestesi_umum||[])" :key="k" class="text-info font-weight-semibold">{{v||"-"}}</small>
                                      </template>
                                      <template v-if="dataPraAnestesiDokter.aranpa_rek_tipe_anestesi == 'Regional Anestesi'">
                                        <small v-for="(v,k) in (dataPraAnestesiDokter.aranpa_rek_tipe_anestesi||[])" :key="k" class="text-info font-weight-semibold">{{v||"-"}}</small>
                                      </template>

                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Puasa mulai</h4>
                                      <p v-if="dataPraAnestesiDokter.aranpa_puasa_mulai_date && dataPraAnestesiDokter.aranpa_puasa_mulai_jam">
                                        {{dataPraAnestesiDokter.aranpa_puasa_mulai_date | moment("DD MMMM YYYY")}}
                                        {{dataPraAnestesiDokter.aranpa_puasa_mulai_jam}}
                                      </p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Rencana tiba di UBS</h4>
                                      <p v-if="dataPraAnestesiDokter.aranpa_rencana_ubs_date && dataPraAnestesiDokter.aranpa_rencana_ubs_jam">
                                        {{dataPraAnestesiDokter.aranpa_rencana_ubs_date | moment("DD MMMM YYYY")}}
                                        {{dataPraAnestesiDokter.aranpa_rencana_ubs_jam}}
                                      </p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Rencana operasi</h4>
                                      <p v-if="dataPraAnestesiDokter.aranpa_rencana_operasi_date && dataPraAnestesiDokter.aranpa_rencana_operasi_jam">
                                        {{dataPraAnestesiDokter.aranpa_rencana_operasi_date | moment("DD MMMM YYYY")}}
                                        {{dataPraAnestesiDokter.aranpa_rencana_operasi_jam}}
                                      </p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-12">
                      <span class="text-center">Tidak ada data</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data(){
    return {
      activeTab: 1,
    }
  },
  computed: {
    dataPraAnestesiPerawat(){
      return this.$parent.dataOperasi.dataPraAnestesiPerawat
    },
    dataPraAnestesiDokter(){
      return this.$parent.dataOperasi.dataPraAnestesiDokter
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    dataPenunjang(){
      return this.$parent.dataOperasi.dataPenunjang
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  },
}

</script>