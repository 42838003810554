<template>
  <div>
    <div class="card-header bg_head_panel">
      <h6 class="card-title font-weight-semibold">Radiologi</h6>
    </div>
    <div class="card-body p-3">
      <div>
        <label for="">Prioritas Pemeriksaan <strong class="text-danger">*</strong></label>
          <div>
              <b-form-radio-group 
              :options="isParent.Config.mr.configPrioritasPemeriksaan"
              v-model="isParent.row['arancptlr_prioritas']" />
          </div>
          <VValidate :name="'Prioritas Pemeriksaan'"
              v-model="isParent.row.arancptlr_prioritas"
              :rules="toValidate(isParent.mrValidation.arancptlr_prioritas)" />
      </div>
    </div>
    <div class="card-body p-3">
      <div class="req-list-resume">
        <div class="row g-3">
        <template v-for="(v,k) in isParent.row.arancptlr_data">
            <div class="col-md-4 col-lg-3" :key="k+'radioData'"
            v-if="isShowHeadRadiologi(k)">
            <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                <h6 class="card-title text-uppercase font-weight-semibold">{{
        getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                </div>
                <div class="card-body py-2">
                <template v-for="v1,k1 in (v.data||[])">
                    <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                    <i
                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                    <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                    <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                    
                    <a href="javascript:;" @click="openInformConsent(v1,k1,k,getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head))" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                    <i class="icon-file-check2"></i>
                    </a>

                    <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                    </a>

                    </div>
                </template>
                </div>
            </div>
            </div>
        </template>
        <div class="col-md-4 col-lg-3" v-if="isParent.row.arancptlr_data_lainnya">
            <div class="card shadow-0 border">
            <div class="card-header py-2 bg-light">
                <h6 class="card-title text-uppercase font-weight-semibold">Item
                Pemeriksaan Lainnya</h6>
            </div>
            <div class="card-body py-2">
                <div class="req-list">
                <div>
                    <i
                    class="icon-checkmark-circle text-success align-middle mr-1"></i>
                    <b class="align-middle font-weight-semibold">{{isParent.row.arancptlr_data_lainnya||"-"}}</b>
                </div>
                </div>
            </div>
            </div>
        </div>
        <span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
        </div>
      </div>
      <div class="text-center mt-3">
        <a href="javascript:;" @click="doOpenRadiologi" class="btn alpha-blue"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
          Radiologi</a>
        <a href="javascript:;" @click="openTemplatePenunjangData" v-if="isParent.row.is_have_template_radio" class="btn alpha-success ml-1"><i class="fas fa-receipt mr-2"></i>Pilih Template Pemeriksaan Penunjang</a>
      </div>
    </div>
    <div class="card-body p-3">
      <div>
        <label for="">Pasien Memiliki Alergi Terhadap Bahan Kontras/Zat Lainnya? <strong class="text-danger">*</strong></label>
        <div>
          <b-form-radio-group 
          :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.arancptlr_alergi" />

          <VValidate :name="'Alergi Zat Lainnya'"
          v-model="isParent.row.arancptlr_alergi" :rules="toValidate(isParent.mrValidation.arancptlr_alergi)" />
        </div>
      </div>
    </div>

    <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in isParent.row.arancptlr_data" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(isParent.Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
          </li>
          </ul>
      </div>

      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
          <div v-for="(v,k) in isParent.row.arancptlr_data" :key="k+'radform'"
              :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
              <div class="row sc-row g-0">
              <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                  <div class="selection-control">
                  <b-form-checkbox @input="changeInpRadiologi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                  </b-form-checkbox>
                  <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                  </div>
              </div>
              </div>
          </div>
          </div>
          <div class="row ml-1 mt-2">
          <div class="col-md-4">
              <div class="form-group row">
              <label>Lainnya</label>
              <b-form-input v-model="isParent.row.arancptlr_data_lainnya" type="text" class="form-control" />
              </div>
          </div>
          </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedRadioInput">{{selectedRadioInput}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
          <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
    </b-modal>
    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>
        
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

    <!-- PENUNJANG -->
    <validation-observer ref="VFormTemplate">
    <b-modal v-model="openTemplatePenunjang" @ok.prevent="submitTemplatePenunjang" :title="'Template Penunjang'" size="md" ok-title="Pilih Template">
    <div class="row">
        <div class="col-md-12">
        <template v-if="templatePenunjang">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
            <tr>
                <th width="20"></th>
                <th>Nama</th>
                <th>Isi pemeriksaan</th>
                <th width="80">Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,k) in (templatePenunjang||[])" :key="k">
                <td>
                <b-form-checkbox :disabled="(v.mtp_is_lab == 'Y' && v.mtp_is_radio == 'Y') && isParent.row.arancpd_is_lab != 'Y'" v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                </td>
                <td>
                  {{v.mtp_name}}
                  <small v-if="(v.mtp_is_lab == 'Y' && v.mtp_is_radio == 'Y')" class="text-info d-block">*Silakan centang tindak lanjut laboratorium & Radiologi untuk bisa memilih template ini.</small>
                </td>
                <td>
                <span v-if="v.mtp_is_lab == 'Y'" class="badge bg-success mb-1 d-block">Laboratorium</span>
                <span v-if="v.mtp_is_radio == 'Y'" class="badge bg-primary mb-1 d-block">Radiologi</span>
                </td>
                <td>
                    <a @click="openDetailPenunjang(k,v.data_lab,v.data_radio)"
                                        href="javascript:;"
                                        title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
                                        ><i class="icon-eye"></i></a>
                </td>
            </tr>
            <tr v-if="!(templatePenunjang||[]).length">
                <td colspan="4" class="text-center">Tidak ada data</td>
            </tr>
            </tbody>
            </table>
        </template>
        <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
        </div>
    </div>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openDetailIsiPenunjang" @ok.prevent="selectOneTemplatePenunjang()" :title="'Detail Isi Template'" size="md" ok-title="Pilih Template">
    <div class="row">
        <div class="col-md-12">
        <table class="table table-bordered table-sm">
            <thead>
                <tr>
                <th>Nama Tindakan</th>
                <th>Tipe Tindakan</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in (dataDetailPenunjangLab||[])" :key="k+'lab'">
                <td>{{v||"-"}}</td>
                <td>
                <span class="badge bg-success mb-1">Laboratorium</span>
                </td>
                </tr>
                <tr v-for="(v,k) in (dataDetailPenunjangRadiologi||[])" :key="k+'rad'">
                <td>{{v||"-"}}</td>
                <td>
                <span class="badge bg-primary mb-1">Radiologi</span>
                </td>
                </tr>
                <tr v-if="!(dataDetailPenunjangLab||[]).length && !(dataDetailPenunjangRadiologi||[]).length">
                <td class="text-center">Tidak ada data</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
    </b-modal>

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  data() {
    return {
      openRadiologi: false,
      activeTabRadiologi: 0,

      openModalICDetail: false,
      openModalIC: false,
      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },

      templatePenunjang: false,
      openTemplatePenunjang: false,
      dataDetailPenunjangLab: [],
      dataDetailPenunjangRadiologi: [],
      openDetailIsiPenunjang: false,
      selectedDetailPenunjang: null,

    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    selectedRadioInput() {
      let data = []
      for (let i = 0; i < (this.isParent.row.arancptlr_data || []).length; i++) {
        for (let j = 0; j < (this.isParent.row.arancptlr_data[i]['data'] || []).length; j++) {
            if (this.isParent.row.arancptlr_data[i]['data'][j]['selected']) {
                data.push(this.isParent.row.arancptlr_data[i]['data'][j]['text'])
            }
        }
      }
      if (this.isParent.row.arancptlr_data_lainnya) {
        data.push(this.isParent.row.arancptlr_data_lainnya)
      }
      return data.join(", ")
    },
    

    selectedTemplateLab(){
      let selected = []
      for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
          if(this.templatePenunjang[i]['checked']){
          for (let j = 0; j < (this.templatePenunjang[i]['mtp_lab_selected_id']||[]).length; j++) {
              let dataSelected = this.templatePenunjang[i]['mtp_lab_selected_id'][j]
              selected.push(dataSelected)
          }
          }
      }
      return selected
    },
    selectedTemplateLabLainnya(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_lab_temp_lainnya']){
            let dataSelected = this.templatePenunjang[i]['mtp_lab_temp_lainnya']
            selected.push(dataSelected)
            }
        }
        return selected.join(", ")
    },
    selectedTemplateRadiologi(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked']){
                for (let j = 0; j < (this.templatePenunjang[i]['mtp_radio_selected_id']||[]).length; j++) {
                    let dataSelected = this.templatePenunjang[i]['mtp_radio_selected_id'][j]
                    selected.push(dataSelected)
                }
            }
        }
        return selected
    },
    selectedTemplateRadiologiLainnya(){
        let selected = []
        for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
            if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_radio_temp_lainnya']){
                let dataSelected = this.templatePenunjang[i]['mtp_radio_temp_lainnya']
                selected.push(dataSelected)
            }
        }
        return selected.join(", ")
    },
  },
  methods: {
    
    changeInpRadiologi(e,v,kU,k){
      if(!e && v.inform_consent){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Informconsent?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  this.loadingOverlay = true                        
                  let data = {
                      type: "remove-ic-single",
                      id: v.inform_consent
                  }
                  Gen.apiRest(
                      "/do/"+'RanapCPPTDokter', 
                      {
                          data: data
                      }, "POST"
                  ).then(()=>{
                      this.loadingOverlay = false                                            
                      this.isParent.row.arancptlr_data[kU]['data'][k]['inform_consent'] = null                    
                  })
              }
              else{
                  this.isParent.row.arancptlr_data[kU]['data'][k]['selected'] = true

              }
          })
      }
    },
    doOpenRadiologi() {
      this.openRadiologi = true
    },
    changeTabRadiologi(e) {
      this.activeTabRadiologi = e
    },
    toValidate(val){
        return {...val}
    },        
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index]['text']
            }
        }
        return text
    },
    
    isShowHeadRadiologi(j) {
      let isData = 0
      for (let k = 0; k < (this.isParent.row.arancptlr_data[j]['data'] || []).length; k++) {
        if (this.isParent.row.arancptlr_data[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowRadiologi(j, k) {
        let isData = 0
        if (this.isParent.row.arancptlr_data[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    resetRadiologi() {
        for (let i = 0; i < (this.isParent.row.arancptlr_data || []).length; i++) {
            for (let j = 0; j < (this.isParent.row.arancptlr_data[i]['data'] || []).length; j++) {
                this.isParent.row.arancptlr_data[i]['data'][j]['selected'] = false
            }
        }
    },


    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
                type: 'get-data-ic-single',
                id: v.inform_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true                   
        })
    },
    openInformConsent(v,k1,k,kat){
        if(!v.inform_consent){
        
            this.rowIC.aranic_is_pasien_setuju = null
            this.rowIC.aranic_persetujuan_tindakan = null

            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.rowIC.aranic_diagnosis = null
            this.rowIC.aranic_id = null
            this.rowIC.aranic_dasar_diagnosis = null
            this.rowIC.aranic_tindakan_dokter = null
            this.rowIC.aranic_indikasi_tindakan = null
            this.rowIC.aranic_tata_cara = null
            this.rowIC.aranic_tujuan = null
            this.rowIC.aranic_risiko = null
            this.rowIC.aranic_kompilasi = null
            this.rowIC.aranic_prognosis = null
            this.rowIC.aranic_alternatif = null
            this.rowIC.aranic_lainnya = null
  
        }else{  
            Gen.apiRest('/do/' + 'RanapCPPTDokter', {
                data: {
                    type: 'get-data-ic-single',
                    id: v.inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent   
                this.rowIC.k1 = k1
                this.rowIC.k = k         
            })
            // console.log(edit)
        }
        this.openModalIC = true
    },
    submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.aranic_aranr_id = this.isParent.rowReg.aranr_id
                data.aranic_name = this.rowIC.tindakan
                data.aranic_created_on = 'Radiologi'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RanapCPPTDokter', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.isParent.row['arancptlr_data'][this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                    this.loadingOverlay = false
                    this.isParent.autoSave(this.isParent.row)

                    this.rowIC.aranic_id = null
            
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },

    
    // radiologi
    selectOneTemplatePenunjang(){
      this.templatePenunjang[this.selectedDetailPenunjang]['checked'] = true
      this.submitTemplatePenunjang()
    },
    openTemplatePenunjangData(){
      let idRefTemplate = this.user.levelId == 1 ? this.isParent.rowReg.aranr_dokter_id : this.isParent.row.arancp_created_by
      this.templatePenunjang = false
      let data = {
          id_dokter: idRefTemplate,
          typeTemplate: "RADIO",
          type: 'get-template-penunjang'
      }
      Gen.apiRest(
      "/do/" + 'RanapCPPTDokter', {
          data: data
      },"POST").then(res => {
          this.openTemplatePenunjang = true
          this.templatePenunjang = res.data.data
      })
    },
    openDetailPenunjang(k,data_lab,data_radiologi){
      this.selectedDetailPenunjang = k
      this.dataDetailPenunjangLab = data_lab
      this.dataDetailPenunjangRadiologi = data_radiologi
      this.openDetailIsiPenunjang = true
    },
    submitTemplatePenunjang(){
      if(!(this.selectedTemplateLab||[]).length && !(this.selectedTemplateRadiologi||[]).length){
          return this.$swal({
              icon: 'error',
              title: 'Wajib Memilih Satu Item Template'
          })
      }

      this.openDetailIsiPenunjang = false

      this.$swal({
          html: 'Menyalin Template...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
              this.$swal.showLoading()
          }
      })
      
      let data = {
          selectedTemplateLabLainnya: this.selectedTemplateLabLainnya,
          selectedTemplateLab: this.selectedTemplateLab,
          selectedTemplateRadiologiLainnya: this.selectedTemplateRadiologiLainnya,
          selectedTemplateRadiologi: this.selectedTemplateRadiologi,
          dataMasterLab: this.isParent.row.arancptll_data,
          dataMasterRadiologi: this.isParent.row.arancptlr_data,
          type : 'replace-penunjang-from-template'
      }
      Gen.apiRest(
          "/do/" + 'RanapCPPTDokter', {
          data: data
          },
          "POST"
      ).then(res => {
          this.isParent.row.arancptll_data = res.data.data_lab
          this.isParent.row.arancptll_data_lainnya = res.data.data_lab_lainnya
          this.isParent.row.arancpd_is_lab = res.data.is_lab ? "Y" : this.isParent.row.arancpd_is_lab
          
          this.isParent.row.arancptlr_data = res.data.data_radiologi
          this.isParent.row.arancptlr_data_lainnya = res.data.data_radiologi_lainnya
          this.isParent.row.arancpd_is_radiologi = res.data.is_radiologi ? "Y" : this.isParent.row.arancpd_is_radiologi

          this.$nextTick(() => {
              this.$swal().close()
          })
          this.openTemplatePenunjang = false
      })
    },



  },
  watch: {
    selectedRadioInput(v){
      setTimeout(()=>{
          this.isParent.row.arancptlr_tindakan = v
      },250)
    }
  }
}

</script>
