<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Checklist Keselamatan Pasien</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="3">Sign In</th>
                </tr>
                <tr>
                  <th colspan="3" class="table-info">Sebelum induksi anesthesi <br> (dengan perawat dan dokter
                    anesthesi)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah pasien telah memberikan konfirmasi kebenaran indentitasnya,
                        lokasi operasi, prosedurnya dan telah memberikan persetujuan dalam lembar informed
                        concent? </h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_konfirmasi_identitas)||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah lokasi operasi sudah diberi tanda/marking?</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_lokasi_marking)||"-"}}</p>

                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah mesin dan obat anestesi telah dicek dan lengkap?</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_mesin_dicek)||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah pulse oximeter sudah terpasang dan berfungsi? </h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_pulse_oximeter)||"-"}}</p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Riwayat alergi yang diketahui </h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_riwayat_alergi)||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Resiko kesulitan pada jalan nafas atau resiko aspirasi?</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_resiko_kesulitan)||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Resiko kehilangan darah > 500 ml (7ml/kg BB pada anak)</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_kehilangan_darah)||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div>
                      <label for="">Dokter Operator</label><br/>
                      <img style="width:362px; height:145px; object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_qr_dokter_operator)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Dokter Anestesi</label><br/>
                      <img style="width:362px; height:145px; object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_qr_dokter_anestesi)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Perawat Sirkuler</label><br/>
                      <img style="width:362px; height:145px; object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_qr_perawat_sirkuler)"/>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="3">Time Out</th>
                </tr>
                <tr>
                  <th colspan="3" class="table-info">Sebelum insisi kulit <br>(dengan perawat, dokter
                    anesthesi dan dokter bedah )
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Seluruh anggota tim telah menyebutkan nama dan peran masing-masing </h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_seluruh_anggota_menyebutkan_nama)||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Pastikan nama pasien, nama prosedur dan dimana insisi akan dilakukan</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_pastikan_nama_pasien)||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Antibiotik profilaksis telah diberikan dalam 60 menit?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_antibiotik)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_antibiotik == 'Y'">
                        Oleh {{dataCeklistKeselamatanPasien.arancka_antibiotik_diterapkan||"-"}}
                      </small>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" class="table-info">ANTISIPASI KEJADIAN KRITIS</th>
                </tr>
                <tr>
                  <th colspan="3" class="table-warning">Operator</th>
                </tr>
                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Hal kritis atau langkah tak terduga apakah yang mungkin diambil?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_hal_kritis)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_hal_kritis == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_hal_kritis_text||"-"}}
                      </small>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Berapa estimasi lama operasi?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_estimasi_operasi)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_estimasi_operasi == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_estimasi_operasi_text||"-"}}
                      </small>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Antisipasi kehilangan darah yang dipersiapkan?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_antisipasi_kehilangan_darah)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_antisipasi_kehilangan_darah == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_antisipasi_kehilangan_darah_text||"-"}}
                      </small>
                    
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" class="table-warning">Tim Anestesi</th>
                </tr>
                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Adakah terdapat hal penting mengenai pasien yang perlu diperhatikan?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_hal_penting)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_hal_penting == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_hal_penting_text||"-"}}
                      </small>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th colspan="3" class="table-warning">Tim Keperawatan</th>
                </tr>

                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Sudahkah sterilitas dipastikan (termasuk hasil indikator)?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_sterilitas)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_sterilitas == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_sterilitas_text||"-"}}
                      </small>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Adakah masalah atau perhatian khusus mengenai peralatan?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_masalah)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_masalah == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_masalah_text||"-"}}
                      </small>
                    </div>
                  </td>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah implant sudah tersedia ?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_implant)||"-"}}</p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_implant == 'Y'">
                        {{dataCeklistKeselamatanPasien.arancka_implant_text||"-"}}
                      </small>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Hasil pemeriksaan imaging penting ditampilkan?</h4>
                      <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_imaging)||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div>
                      <label for="">Dokter Operator</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_to_qr_dokter_operator)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Dokter Anestesi</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_to_qr_dokter_anestesi)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Perawat Sirkuler</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_to_qr_perawat_sirkuler)"/>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="3">Sign Out</th>
                </tr>
                <tr>
                  <th colspan="3" class="table-info">Sebelum meninggalkan ruang operasi <br>(dengan perawat,
                    dokter anesthesi dan dokter bedah )
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>Tim Keperawatan secara lisan mengkonfirmasi di hadapan TIM: </h4>
                      <p v-if="dataCeklistKeselamatanPasien.arancka_so_nama_tindakan == 'Y'">Nama prosedur tindakan</p>
                      <p v-if="dataCeklistKeselamatanPasien.arancka_so_kelengkapan == 'Y'">Kelengkapan hitungan instrumen, spons dan jarum sudah sesuai</p>

                      <p v-if="dataCeklistKeselamatanPasien.arancka_so_nama_tindakan != 'Y' && dataCeklistKeselamatanPasien.arancka_so_kelengkapan == 'Y'">Tidak ada</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colspan="3">
                    <table class="table table-sm  table-bordered">
                      <thead>
                        <tr>
                          <th>Nama Barang</th>
                          <th>Hitungan Pertama</th>
                          <th colspan="3">Tambahan Selama Operasi</th>
                          <th>Jumlah</th>
                          <th>Hitungan Kedua</th>
                          <th>Hitungan Terakhir</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (dataCeklistKeselamatanPasien.arancka_so_data||[])" :key="k">
                          <td>{{v.jenis||"-"}}</td>
                          <td>{{v.hitungan_pertama||"-"}}</td>
                          <td>{{v.tambahan1||"-"}}</td>
                          <td>{{v.tambahan2||"-"}}</td>
                          <td>{{v.tambahan3||"-"}}</td>
                          <td>{{v.jumlah||"-"}}</td>
                          <td>{{v.hitungan_kedua||"-"}}</td> 
                          <td>{{v.hitungan_terakhir||"-"}}</td>                          
                        </tr>
                      </tbody>
                    </table>
                  </th>
                </tr>

                <tr>
                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Label spesimen (minimal terdapat asal jaringan, nama pasien, tanggal lahir dan nomer
                        RM) </h4>
                      <p>
                        {{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_so_label_spesimen)||"-"}}
                      </p>
                    </div>
                  </td>

                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Apakah terdapat permasalahan peralatan yang perlu disampaikan, </h4>
                      <p class="mb-0">
                        {{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_so_terdapat_permasalahan)||"-"}}
                      </p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_so_terdapat_permasalahan == 'Y'">{{dataCeklistKeselamatanPasien.arancka_so_terdapat_permasalahan_text||"-"}}</small>
                    </div>
                  </td>

                  <td style="width:33%;">
                    <div class="result_tab">
                      <h4>Kepada Operator, Dokter Anestesi dan Tim Keperawatan, Apakah terdapat pesan khusus
                        untuk pemulihan pasien?</h4>
                      <p class="mb-0">
                        {{getConfigDynamic(Config.mr.yesNoOptV2,dataCeklistKeselamatanPasien.arancka_so_pemulihan_masalah)||"-"}}
                      </p>
                      <small v-if="dataCeklistKeselamatanPasien.arancka_so_pemulihan_masalah == 'Y'">{{dataCeklistKeselamatanPasien.arancka_so_pemulihan_masalah_text||"-"}}</small>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div>
                      <label for="">Dokter Operator</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_so_qr_dokter_operator)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Dokter Anestesi</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_so_qr_dokter_anestesi)"/>
                    </div>
                  </td>
                  <td>
                    <div>
                      <label for="">Perawat Sirkuler</label><br/>
                      <img style="width:362px; height:145px;object-fit:contain;" :src="uploader(dataCeklistKeselamatanPasien.arancka_so_qr_perawat_sirkuler)"/>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  computed: {
    dataCeklistKeselamatanPasien(){
      return this.$parent.dataOperasi.dataCeklistKeselamatanPasien
    },
    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    Config(){ return Config },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
  }
}
</script>