<template>
  <div>
    <b-tabs @input="changeTab($event)" :value="activeTabs" nav-class="nav-tabs-bottom nav-justified mb-0">
      <b-tab title="Operasi">
        <table v-if="!loadingTable" class="table table-bordered table-sm patient-table mt-2">
          <thead>
            <tr>
              <th>No.</th>
              <th>Tindakan Operasi</th>
              <th>Tindakan Anestesi</th>
              <th>Diagnosa</th>
              <th>Tanggal Operasi</th>
              <th>Dokter Operator</th>
              <th>Dokter Anestesi</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (dataOperasi||[])" :key="k">
              <td>
                <span>{{k+1}}</span>
              </td>
              <td>{{v.arano_tindakan_operasi||"-"}}
              </td>
              <td>{{v.arano_jenis_new||v.arano_jenis||"-"}}
              </td>
              <td>{{v.arano_diagnosa||"-"}}
              </td>
              <td>
                {{v.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}} {{v.arano_jam_operasi_start}} s/d
                {{v.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}} {{v.arano_jam_operasi_end}}
              </td>
              <td>
                <span v-if="!(v.dokter_operator||[]).length"> - </span>
                <ul v-else>
                  <li v-for="(vk,kk) in (v.dokter_operator||[])" :key="kk+'s'">{{vk}}</li>
                </ul>
              </td>
              <td>
                {{v.dokter_anestesi||"-"}}
              </td>
              <td>
                <a href="javascript:;" @click="openFormOperasi(v)" data-toggle="modal" class="btn btn-icon alpha-blue border-blue" data-popup="tooltip" title="" data-original-title="Lihat Detail">
                  <i class="icon-file-eye"></i>
                </a>
              </td>
            </tr>
            <tr v-if="!(dataOperasi||[]).length">
              <td colspan="99" class="text-center">Tidak ada Data</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-center mt-4">Loading ... </p>
      </b-tab>
      <b-tab title="Laboratorium">
        <table v-if="!loadingTable" class="table table-bordered table-sm patient-table mt-2">
          <thead>
            <tr>
              <th>No.</th>
              <th>Nama & No. RM</th>
              <th>Diagnosa</th>
              <th>Jenis Permintaan</th>
              <th>Tindakan</th>
              <th>Tanggal Tindakan</th>
              <th>Status</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(v,k) in (dataTindakanLab||[])" >
              <tr :class="v.arantl_have_nilai_kritis_lab == 'Y'?'kritis-animate':''" :key="k">
                <td>
                  <span>{{k+1}}</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" class="font-weight-semibold border-bottom">{{rowData.ap_fullname||"-"}}</a>
                    <small>{{rowData.ap_code}}</small>
                    <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                  </div>
                </td>
                <td>
                  {{v.arantl_diagnosa||"-"}}
                </td>
                <td>
                  <ul style="padding-left:10px;">
                    <li v-if="v.arantl_is_lab == 'Y'">Periksa Lab</li>
                    <li v-if="v.arantl_is_transfusi == 'Y'">Transfusi</li>
                    <li v-if="v.arantl_is_ph == 'Y'">Phlebotomy</li>
                    <li v-if="v.arantl_is_hiv == 'Y'">HIV</li>
                  </ul>
                </td>
                <td>
                  {{v.arantl_tindakan||"-"}}
                </td>
                <td>
                  <span v-if="v.arantl_submit_date">
                    {{v.arantl_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                  </span>
                  <span v-else> - </span>
      
                  <small class="font-weight-semibold" v-if="v.input_by"> dibuat oleh {{v.input_by}}</small>
                </td>
                <td>
                  <template v-if="v.arantl_is_draft == 'Y'">
                    <span class="badge bg-warning mb-1">Draft </span>
                  </template>
                  <template v-else>
                    <div v-if="v.arantl_is_lab == 'Y'" v-b-tooltip.hover title="Status Laboratorium">
                      <span v-if="v.arantl_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                      <span v-else-if="v.arantl_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                      <span v-else class="badge bg-success mb-1">Selesai </span>
                    </div>
                    <div v-if="v.arantl_is_transfusi == 'Y'" v-b-tooltip.hover title="Status Transfusi">
                      <span v-if="v.arantl_tr_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                      <span v-else-if="v.arantl_tr_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                      <span v-else class="badge bg-success mb-1">Selesai </span>
                    </div>
                    <div v-if="v.arantl_is_ph == 'Y'" v-b-tooltip.hover title="Status Phlebotomy">
                      <span v-if="v.arantl_ph_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                      <span v-else-if="v.arantl_ph_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                      <span v-else class="badge bg-success mb-1">Selesai </span>
                    </div>
                    <div v-if="v.arantl_is_hiv == 'Y'" v-b-tooltip.hover title="Status HIV">
                      <span v-if="v.arantl_hiv_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                      <span v-else-if="v.arantl_hiv_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                      <span v-else class="badge bg-success mb-1">Selesai </span>

                      <span v-if="v.arantl_hiv_approved == 'N' && v.arantl_hiv_status != 'WAITING'" class="badge bg-danger mb-1">Pemeriksaan HIV ditolak</span>
                      <span v-else-if="v.arantl_hiv_approved == 'Y' && v.arantl_hiv_status != 'WAITING'" class="badge bg-success mb-1">Pemeriksaan HIV disetujui </span>
                    </div>

                    <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status == 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan</span>
                    
                    <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status != 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan Sementara</span>

                  <span v-if="v.arantl_have_nilai_kritis_lab == 'Y'" class="badge bg-danger mb-1">Kritis </span>

                  </template>
                </td>
                <td>
                  <a href="javascript:;" @click="toModal(v,'xl','Detail Tindakan Lab','DetailTindakanLab')" class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" 
                  v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

                  <a href="javascript:;" @click="toModal(v,'lg','Pemberian dan Pengembalian','RiwayatPengembalian')" v-if="v.arantl_is_done_ph  == 'Y'" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Riwayat Pemberian dan Pengembalian Darah"><i class="fa-solid fa-receipt text-info-800"></i></a>
                </td>
              </tr>
            </template>
            <tr v-if="!(dataTindakanLab||[]).length">
              <td colspan="99" class="text-center">Tidak ada Data</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-center mt-4">Loading ... </p>
      </b-tab>
      <b-tab title="Radiologi">
        <table v-if="!loadingTable" class="table table-bordered table-sm patient-table mt-2">
          <thead>
            <tr>
              <th>No.</th>
              <th>Nama & No. RM</th>
              <th>Diagnosa</th>
              <th>Tindakan</th>
              <th>Tanggal Tindakan</th>
              <th>Status</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(v,k) in (dataTindakanRadiologi||[])" >
              <tr :class="v.arantr_have_nilai_kritis_radio == 'Y'?'kritis-animate':''" :key="k">
                <td>
                  <span>{{k+1}}</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" class="font-weight-semibold border-bottom">{{rowData.ap_fullname||"-"}}</a>
                    <small>{{rowData.ap_code}}</small>
                    <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                  </div>
                </td>
                <td>
                  {{v.arantr_diagnosa||"-"}}
                </td>
                <td>
                  {{v.arantr_tindakan||"-"}}
                </td>
                <td>
                  <span v-if="v.arantr_submit_date">
                    {{v.arantr_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                  </span>
                  <span v-else> - </span>
                  
                  <small class="font-weight-semibold" v-if="v.input_by"> dibuat oleh {{v.input_by}}</small>
                </td>
                <td>
                  <template v-if="v.arantr_is_draft == 'Y'">
                    <span class="badge bg-warning mb-1">Draft </span>
                  </template>
                  <template v-else>
                    <div v-b-tooltip.hover title="Status Radiologi">
                      <span v-if="v.arantr_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                      <span v-else-if="v.arantr_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                      <span v-else class="badge bg-success mb-1">Selesai </span>
                    </div>
                  </template>
        
                  <div v-if="v.arantr_is_have_transfer_intra != 'Y'" class="badge bg-warning mb-1">Isi Form Transfer Intra</div>
                  
                  <div v-if="v.arantr_is_menunggu_hasil == 'Y'" class="badge bg-warning mb-1">Hasil Pemeriksaan Belum Keluar</div>
                  
                  <span v-if="v.arantr_have_nilai_kritis_radio == 'Y'" class="badge bg-danger mb-1">Kritis </span>
                </td>
                <td>
                  <a href="javascript:;" @click="toModal(v,'xl','Detail Tindakan Radiologi','DetailTindakanRadiologi')"
                  class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>
                </td>
              </tr>
            </template>
            <tr v-if="!(dataTindakanRadiologi||[]).length">
              <td colspan="99" class="text-center">Tidak ada Data</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-center mt-4">Loading ... </p>
      </b-tab>
      <b-tab title="Fisioterapi">
        <table v-if="!loadingTable" class="table table-bordered table-sm patient-table mt-2">
          <thead>
            <tr>
              <th>No.</th>
              <th>Nama & No. RM</th>
              <th>Diagnosa</th>
              <th style="width:35%">Tindakan</th>
              <th>Tanggal Tindakan</th>
              <th>PPA</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (dataTindakanFisioterapi||[])" :key="k">
              <td>
                <span>{{k+1}}</span>
              </td>
              <td>
                <div class="d-flex flex-column align-items-start">
                  <a href="javascript:;" class="font-weight-semibold border-bottom">{{rowData.ap_fullname||"-"}}</a>
                  <small>{{rowData.ap_code}}</small>
                  <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </div>
              </td>
              <td>
                {{v.arantf_diagnosa||"-"}}
              </td>
              <td>
                
                <div v-if="(v.arantf_elektroterapi||[]).length">
                  <span class="font-weight-semibold">Elektroterapi</span>
                  <ul class="mb-0 pl-3">
                    <li v-for="(v1,k1) in (v.arantf_elektroterapi||[])" :key="k1+'elektroterapi'">
                        <span v-if="v1=='LE'" class="align-middle">{{v.arancptlf_elektroterapi_text||"-"}}</span>
                        <span v-else class="align-middle">{{v1||"-"}}</span>
                    </li>
                  </ul>
                </div>

                <div class="mt-2" v-if="(v.arantf_mekanoterapi||[]).length">
                  <span class="font-weight-semibold">Mekanoterapi</span>
                  <ul class="mb-0 pl-3">
                    <li v-for="(v1,k1) in (v.arantf_mekanoterapi||[])" :key="k1+'mekanoterapi'">
                      <span v-if="v1=='LM'" class="align-middle">{{v.arantf_mekanoterapi_text||"-"}}</span>
                      <span v-else class="align-middle">{{v1||"-"}}</span>
                    </li>
                  </ul>
                </div>

                <div class="mt-2" v-if="(v.arantf_latihan||[]).length">
                  <span class="font-weight-semibold">Latihan</span>
                  <ul class="mb-0 pl-3">
                    <li v-for="(v1,k1) in (v.arantf_latihan||[])" :key="k1+'latihan'">
                      <span v-if="v1=='LL'" class="align-middle">{{v.arantf_latihan_text||"-"}}</span>
                      <span v-else class="align-middle">{{v1||"-"}}</span>
                    </li>
                  </ul>
                </div>

                <span v-if="!((v.arantf_elektroterapi||[]).length + (v.arantf_mekanoterapi||[]).length + (v.arantf_latihan||[]).length)">Tidak ada Tindakan</span>
              </td>
              <td>
                <span v-if="v.arantf_submit_date">
                  {{v.arantf_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                </span>
                <span v-else> - </span>
              </td>
              <td>
                {{v.arantf_verif_by_name||"-"}}
              </td>
              <td>
                <template v-if="v.arantf_is_draft == 'Y'">
                  <span class="badge bg-warning mb-1">Draft </span>
                </template>
                <template v-else>
                  <div v-b-tooltip.hover title="Status Fisioterapi">
                    <span v-if="v.arantf_status == 'WAITING'" class="badge bg-warning mb-1">Menunggu </span>
                    <span v-else-if="v.arantf_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                    <span v-else class="badge bg-success mb-1">Selesai </span>
                  </div>
                </template>
              </td>
            </tr>
            <tr v-if="!(dataTindakanFisioterapi||[]).length">
              <td colspan="99" class="text-center">Tidak ada Data</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-center mt-4">Loading ... </p>
      </b-tab>
    </b-tabs>

    
    <b-modal v-model="isOpenModalOperasi" :title="'Detail Operasi'" size="xl" ok-only ok-title="Tutup">
      <Operasi :dataOperasi="operasiData" :operasiId="idOperasi"/>
    </b-modal>

    
    <b-modal
      v-model="openModal"
      :size="modalConfig.size"
      no-close-on-backdrop
      hide-footer
      :title="modalConfig.title" 
    >
      <DetailTindakanLab v-if="isModal == 'DetailTindakanLab'" /> 
      <DetailTindakanRadiologi v-if="isModal == 'DetailTindakanRadiologi'" />
      <RiwayatPengembalian v-if="isModal == 'RiwayatPengembalian'" />
    </b-modal>
  </div>
</template>


<script>
import Gen from '@/libs/Gen.js'
const _ = global._
import Operasi from '@/components/Ranap/Operasi.vue'
import DetailTindakanLab from '@/components/Ranap/DetailTindakanLab.vue'
import DetailTindakanRadiologi from '@/components/Ranap/DetailTindakanRadiologi.vue'
import RiwayatPengembalian from '@/components/Ranap/RiwayatPengembalian.vue'

export default {
  data(){
    return {
      dataOperasi: [],
      dataTindakanLab: [],
      dataTindakanRadiologi: [],
      dataTindakanFisioterapi: [],
      activeTabs: 0,
      loadingTable: false,

      // operasi
      isOpenModalOperasi: false,
      operasiData: {},
      idOperasi: null,
      modalConfig: {
        title: '',
        size: 'sm'
      },
      isModal: '',
      rowEdit: {},
      openModal: false,
      
    }
  },
  computed: {
    row(){
      return this.rowData
    }
  },
  components:{
    Operasi,DetailTindakanLab,DetailTindakanRadiologi,RiwayatPengembalian
  },
  props: {
    rowData: Object,
    rowReg: Object,
  },
  methods: {
    openFormOperasi(v){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RanapPasienOperasi', {
          data: {
            type: "get-detail-operasi",
            id_operasi: v.arano_id,
            id_pasien: this.row.ap_id
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Ditemukan'
          })
        }
        this.loadingOverlay = false
        this.isOpenModalOperasi = true
        this.idOperasi = v.arano_id
        this.operasiData = resp.dataOperasi
      })
    },
    toModal(row,size,title,isModal){
      this.rowEdit = row
      this.modalConfig = {
        title: title,
        size: size
      }
      this.isModal = isModal
      this.openModal = true
      
    },
    changeTab(e){
      this.activeTabs = e
    },
    getData(tabIndex){
      let typeData = ''
      if(tabIndex == 1){
        typeData = 'lab'
      }else if(tabIndex == 2){
        typeData = 'radiologi'
      }else if(tabIndex == 3){
        typeData = 'fisio'        
      }else{
        typeData = 'operasi'
      }
      this.loadingTable = true
    
      Gen.apiRest(
        "/do/" + 'RanapCPPT', {
          data: {
            type: "get-tindakan-penunjang",
            id_reg: this.rowReg.aranr_id,
            typeData: typeData
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
        this.loadingTable = false
      })

    }
  },
  mounted() {
    this.getData(this.activeTabs)
  },
  watch:{
    'activeTabs'(v){
      this.getData(v)
    }
  }
}
</script>
