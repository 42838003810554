<template>
  <div>
    <div class="card-header bg_head_panel">
      <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-4">
          <div class="wrap_line">
            <h3>Elektroterapi</h3>
            <b-form-checkbox-group
                class="checkbox-block"
                v-model="isParent.row.arancptlf_elektroterapi"
                :options="mTindakanElektroterapi"
                name="Elektroterapi"
            ></b-form-checkbox-group>
            
            <template v-if="isParent.row.arancptlf_elektroterapi.indexOf('LE') != -1">
              <b-form-group>
                <b-form-input placeholder="Tindakan Lainnya" type="text" class="form-control" v-model="isParent.row.arancptlf_elektroterapi_text"/>
                <VValidate 
                  name="Elektroterapi Lainnya" 
                  v-model="isParent.row.arancptlf_elektroterapi_text" 
                  :rules="{required: 1}"
                />
              </b-form-group>
            </template>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wrap_line">
            <h3>MekanoTerapi</h3>
            <b-form-checkbox-group
                class="checkbox-block"
                v-model="isParent.row.arancptlf_mekanoterapi"
                :options="mTindakanMekanoTerapi"
                name="MekanoTerapi"
            ></b-form-checkbox-group>
            
            <template v-if="isParent.row.arancptlf_mekanoterapi.indexOf('LM') != -1">
              <b-form-group>
                <b-form-input placeholder="Tindakan Lainnya" type="text" class="form-control" v-model="isParent.row.arancptlf_mekanoterapi_text"/>
                <VValidate 
                  name="Mekanoterapi Lainnya" 
                  v-model="isParent.row.arancptlf_mekanoterapi_text" 
                  :rules="{required: 1}"
                />
              </b-form-group>
            </template>

          </div>
        </div>
        <div class="col-md-4">
          <div class="wrap_line">
            <h3>Latihan-latihan</h3>
            <b-form-checkbox-group
                class="checkbox-block"
                v-model="isParent.row.arancptlf_latihan"
                :options="mTindakanLatihan"
                name="Latihan-latihan"
            ></b-form-checkbox-group>
            
            <template v-if="isParent.row.arancptlf_latihan.indexOf('LL') != -1">
              <b-form-group>
                <b-form-input placeholder="Tindakan Lainnya" type="text" class="form-control" v-model="isParent.row.arancptlf_latihan_text"/>
                <VValidate 
                  name="Latihan-Latihan Lainnya" 
                  v-model="isParent.row.arancptlf_latihan_text" 
                  :rules="{required: 1}"
                />
              </b-form-group>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    mTindakanElektroterapi(){
      return [
        ... this.isParent.Config.mr.statusElektroterapi,
        {"text" : "Lainnya", "value": "LE"}
      ]
    },
    mTindakanMekanoTerapi(){
      return [
        ... this.isParent.Config.mr.statusMekanoTerapi,
        {"text" : "Lainnya", "value": "LM"}
      ]
    },
    mTindakanLatihan(){
      return [
        ... this.isParent.Config.mr.statusLatihan,
        {"text" : "Lainnya", "value": "LL"}
      ]
    },
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
  },     
}

</script>
