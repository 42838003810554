<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Catatan Ruang Pemulihan</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td style="width:50%;">
                    <div class="result_tab">
                      <h4>Diagnosa Masuk </h4>
                      <p>{{dataRuangPulih.arancrp_diagnosa_masuk||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:50%;">
                    <div class="result_tab">
                      <h4>Tindakan medis yang sudah dilakukan</h4>
                      <p>{{dataRuangPulih.arancrp_tindakan_medis||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-md-12 mt-2">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Masuk RR Jam</h4>
                        <p>{{dataRuangPulih.arancrp_masuk||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jalan Nafas </h4>
                        <p>{{dataRuangPulih.arancrp_jalan_nafas||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Pernafasan</h4>
                        <p>{{dataRuangPulih.arancrp_pernafasan||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bila Spontan</h4>
                        <p>{{dataRuangPulih.arancrp_bila_spontan||"-"}}</p>
                      </div>
                    </td>

                    <td>
                      <div class="result_tab">
                        <h4>Kesadaran</h4>
                        <p>{{dataRuangPulih.arancrp_kesadaran||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--
            <div class="col-md-12 mt-2">
              <table class="table  table-sm   table-bordered">
                <thead>
                  <tr>
                    <th colspan="2">Aldrete Score</th>
                  </tr>
                </thead>
                <tbody>
                
                  <tr>
                    <td>Warna Kulit</td>
                    <td>{{dataRuangPulih.arancrp_ald1_warna_kulit||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Aktifitas Motorik</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_motorik||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Pernafasan</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_pernafasan||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Tekanan Darah</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_tekanan_darah||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Kesadaran</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_kesadaran||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Mual Muntah</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_mual_muntah||"0"}}</td>
                  </tr>
                  <tr>
                    <td>Pendarahan</td>
                    <td>{{dataRuangPulih.arancrp_ald1_aktivitas_pendarahan||"0"}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>TOTAL</td>
                    <td>
                      <div v-if="dataRuangPulih.arancrp_ald1_total >= 12">
                        <span class="font-weight-semibold text-info-800">{{dataRuangPulih.arancrp_ald1_total||0}}</span> - pasien pindah ruangan
                      </div>
                      
                      <div v-else-if="dataRuangPulih.arancrp_ald1_total < 8">
                        <span class="font-weight-semibold text-danger-800">{{dataRuangPulih.arancrp_ald1_total||0}}</span> - pasien pindah ke ICU / Ruang Perawatan Intensif
                      </div>
                      
                      <div v-else>
                        <span class="font-weight-semibold text-success-800">{{dataRuangPulih.arancrp_ald1_total||0}}</span>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Pemantauan di ruang pulih sadar</h6>
        </div>
        <div class="card-body p-3">
          <div class="chart-container mb-3">
            <label for="">Tekanan Darah</label>
            <LineChart :chartData="resumeData"/>
          </div>
          <div>
            <label for="">Pemantauan</label>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th width="40">Jam</th>
                  <th>Nadi</th>
                  <th>Cairan Masuk</th>
                  <th>Cairan Keluar</th>
                  <th>SpO<sub>2</sub></th> </tr> </thead> <tbody>
                <tr v-for="(v,k) in (dataRuangPulihList||[])" :key="k+'pemantauan'">
                  <td>{{v.aranpp_tanggal | moment("DD MMMM YYYY")}} {{v.aranpp_waktu||"-"}}</td>
                  <td>{{v.aranpp_nadi||"-"}}x/mnt</td>
                  <td>{{v.aranpp_cairan_masuk||"-"}}cc</td>
                  <td>{{v.aranpp_cairan_keluar||"-"}}cc</td>
                  <td>{{v.aranpp_spo2||"-"}}%</td>
                </tr>
                <tr v-if="!(dataRuangPulihList||[]).length">
                  <td colspan="6">Tidak ada Data Pemantauan</td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Intruksi Pasca Operasi</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive mb-2">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Posisi </h4>
                      <p>{{dataRuangPulih.arancrp_posisi_operasi||"-"}}</p>
                    </div>
                  </td>

                  <td>
                    <div class="result_tab">
                      <h4>Beri O2 </h4>
                      <p v-if="dataRuangPulih.arancrp_beri_oksigen && dataRuangPulih.arancrp_beri_oksigen_text">{{dataRuangPulih.arancrp_beri_oksigen||"-"}} lt/m, {{dataRuangPulih.arancrp_beri_oksigen_text||"-"}}</p>
                      <p v-else>-</p>
                    </div>
                  </td>


                  <td>
                    <div class="result_tab">
                      <h4>Bila tensi turun di bawah</h4>
                      <p v-if="dataRuangPulih.arancrp_tensi_turun">{{dataRuangPulih.arancrp_tensi_turun}} mmHg </p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Berikan </h4>
                      <p>{{dataRuangPulih.arancrp_berikan||"-"}}</p>
                    </div>
                  </td>

                  <td>
                    <div class="result_tab">
                      <h4>Bila mual/muntah, beri</h4>
                      <p>{{dataRuangPulih.arancrp_bila_mual||"-"}}</p>
                    </div>
                  </td>


                  <td>
                    <div class="result_tab">
                      <h4>Bila kesakitan, beri</h4>
                      <p>{{dataRuangPulih.arancrp_bila_kesakitan||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table class="table  table-sm   table-bordered">
            <thead>
              <tr>
                <th colspan="2">Aldrete Score</th>
              </tr>
            </thead>
            <tbody>
            
              <tr>
                <td>Warna Kulit</td>
                <td>{{dataRuangPulih.arancrp_ald2_warna_kulit||"0"}}</td>
              </tr>
              <tr>
                <td>Aktifitas Motorik</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_motorik||"0"}}</td>
              </tr>
              <tr>
                <td>Pernafasan</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_pernafasan||"0"}}</td>
              </tr>
              <tr>
                <td>Tekanan Darah</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_tekanan_darah||"0"}}</td>
              </tr>
              <tr>
                <td>Kesadaran</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_kesadaran||"0"}}</td>
              </tr>
              <tr>
                <td>Mual Muntah</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_mual_muntah||"0"}}</td>
              </tr>
              <tr>
                <td>Pendarahan</td>
                <td>{{dataRuangPulih.arancrp_ald2_aktivitas_pendarahan||"0"}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td>
                  <div v-if="dataRuangPulih.arancrp_ald2_total >= 12">
                    <span class="font-weight-semibold text-info-800">{{dataRuangPulih.arancrp_ald2_total||0}}</span> - pasien pindah ruangan
                  </div>
                  
                  <div v-else-if="dataRuangPulih.arancrp_ald2_total < 8">
                    <span class="font-weight-semibold text-danger-800">{{dataRuangPulih.arancrp_ald2_total||0}}</span> - pasien pindah ke ICU / Ruang Perawatan Intensif
                  </div>
                  
                  <div v-else>
                    <span class="font-weight-semibold text-success-800">{{dataRuangPulih.arancrp_ald2_total||0}}</span>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          
          <table class="table  table-sm  table-bordered">
            <thead>
              <tr>
                <th colspan="2">Bromage Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kriteria</td>
                <td><span class="font-weight-semibold text-info-800">({{dataRuangPulih.arancrp_brs_kriteria||0}})</span> 
                {{getConfigDynamic(Config.mr.mBromageScore,dataRuangPulih.arancrp_brs_kriteria)||"-"}}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table  table-sm   table-bordered">
            <thead>
              <tr>
                <th colspan="2">Steward Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kesadaran</td>
                <td>{{dataRuangPulih.arancrp_sts_kesadaran||"-"}}</td>
              </tr>
              <tr>
                <td>Respirasi</td>
                <td>{{dataRuangPulih.arancrp_sts_pernafasan||"-"}}</td>
              </tr>
              <tr>
                <td>Motorik</td>
                <td>{{dataRuangPulih.arancrp_sts_motorik||"-"}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td>
                  <div v-if="dataRuangPulih.arancrp_sts_total >= 5">
                    Total: <span class="font-weight-semibold text-info-800">{{dataRuangPulih.arancrp_sts_total||0}}</span> - pasien boleh pindah ke ruangan
                  </div>
                  <div v-else>
                    Total: <span class="font-weight-semibold text-danger-800">{{dataRuangPulih.arancrp_sts_total||0}}</span> - pasien belum boleh pindah ke ruangan
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Keluar RR</h6>
        </div>
        <div class="card-body p-3">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Tanggal, Jam </h4>
                    <p v-if="dataRuangPulih.arancrp_rr_tanggal">{{dataRuangPulih.arancrp_rr_tanggal | moment("DD MMMM YYYY")}}, {{dataRuangPulih.arancrp_rr_jam}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Tekanan Darah</h4>
                    <p v-if="dataRuangPulih.arancrp_rr_tekanan_darah_min && dataRuangPulih.arancrp_rr_tekanan_darah_max">
                    {{dataRuangPulih.arancrp_rr_tekanan_darah_min}} / {{dataRuangPulih.arancrp_rr_tekanan_darah_max}} mmHG</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Nadi</h4>
                    <p v-if="dataRuangPulih.arancrp_rr_nadi">{{dataRuangPulih.arancrp_rr_nadi||"-"}}/mnt</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pernafasan </h4>
                    <p v-if="dataRuangPulih.arancrp_rr_pernafasan">{{dataRuangPulih.arancrp_rr_pernafasan||"-"}}x/mnt - Reguler</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>SPO2</h4>
                    <p v-if="dataRuangPulih.aranrcp_rr_spo2">{{dataRuangPulih.aranrcp_rr_spo2||"-"}}%</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Pindah Ruang</h6>
        </div>
        <div class="card-body p-3">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pindah Ke Ruang </h4>
                    <p>{{getConfigDynamic(mRanapBangsal,dataRuangPulih.arancrp_pindah_keruang)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Ruang</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapRuangTo,dataRuangPulih.arancrp_ruang)||"-"}}</p>
                    <small v-if="dataRuangPulih.arancrp_ruang == 'LN'">{{dataRuangPulih.arancrp_ruang_text||"-"}}</small>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    
    <div class="col-md-8">
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda Tangan</h6>
        </div>
        <div class="card-body p-3">
          <table class="table  table-sm  table-bordered">
            <tbody>
              <tr>
                <td style="width:50%;">
                  <div class="result_tab">
                    <h4>TTD & Nama Terang Dokter Anestesi </h4>
                    <p>{{dataRuangPulih.arancrp_nama_dokter_anestesi||"-"}}</p>
                    <div class="mt-1">
                      <img style="width:400px;height:200px;" :src="dataRuangPulih.arancrp_ttd_dokter_anestesi?dataRuangPulih.arancrp_ttd_dokter_anestesi:'https://placeholder.com/400x200'">
                    </div>
                  </div>
                </td>
                
                <td style="width:50%;">
                  <div class="result_tab">
                    <h4>TTD & Nama Terang Perawat Anestesi </h4>
                    <p>{{dataRuangPulih.arancrp_nama_asisten_anestesi||"-"}}</p>
                    <div class="mt-1">
                      <img style="width:400px;height:200px;" :src="dataRuangPulih.arancrp_ttd_asisten_anestesi?dataRuangPulih.arancrp_ttd_asisten_anestesi:'https://placeholder.com/400x200'">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import LineChart from '@/components/chart/LineChart'


export default {
  components: {
    LineChart
  },
  computed: {
    dataOperasi(){
      return this.$parent.dataOperasi.dataOperasi
    },
    dataRuangPulih(){
      return this.$parent.dataOperasi.dataRuangPulih
    },
    dataRuangPulihList(){
      return this.$parent.dataOperasi.dataRuangPulihList
    },
    mRanapKamar(){
      return this.$parent.dataOperasi.mRanapKamar
    },

    dataPasien(){
      return this.$parent.dataOperasi.dataPasien
    },
    Config(){ return Config },

    getLabels(){
      let labels = []
      for(let i = 0; i < (this.dataRuangPulihList||[]).length; i++){
        let dates = this.dataRuangPulihList[i]['aranpp_waktu']+' '+moment(this.dataRuangPulihList[i]['aranpp_tanggal']).format('DD MMM YYYY')
        labels.push(dates)
      }
      return labels
    },
    getSystole(){
      let data = []
      for(let i = 0; i < (this.dataRuangPulihList||[]).length; i++){
        data.push(this.dataRuangPulihList[i].aranpp_tekanan_darah_min)
      }
      return data
    },
    getDiastole(){
      let data = []
      for(let i = 0; i < (this.dataRuangPulihList||[]).length; i++){
        data.push(this.dataRuangPulihList[i].aranpp_tekanan_darah_max)
      }
      return data
    },
    resumeData() {
      return {
        "labels": this.getLabels,
        "datasets": [
          {
              "label": "Sistolik",
              "data": this.getSystole,
              "backgroundColor": "#D7241F"
          },
          {
              "label": "Diastolik",
              "data": this.getDiastole,
              "backgroundColor": "#264654"
          }
        ]
      }
    },
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
  },
}

</script>