<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Rawat Bersama</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="">Pilih Dokter <strong class="text-danger">*</strong></label>

            <v-select 
              :disabled="isParent.row.isEditRaber"
              :options="isParent.mDokter"
              v-model="isParent.row.arancptlln_raber_multi_dokter_id"
              placeholder="-- Pilih Dokter --"
              label="text"
              multiple
              class="vs__multiple"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
            
            <VValidate 
                name="Dokter Pemeriksaan Raber" 
                v-model="isParent.row.arancptlln_raber_multi_dokter_id" 
                :rules="toValidate(isParent.mrValidation.arancptlln_raber_multi_dokter_id)"
            />

            <!--
            <v-select 
              :options="isParent.mDokter"
              v-model="isParent.row.arancptlln_raber_dokter_id"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
          
            <VValidate 
                name="Dokter Pemeriksaan Raber" 
                v-model="isParent.row.arancptlln_raber_dokter_id" 
                :rules="toValidate(isParent.mrValidation.arancptlln_raber_dokter_id)"
            />
            -->
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-md-8">
          <div class="form-group mb-0">
            <label for="konsulIkhtisar">Ikhtisar Klinik & Pemeriksaan Penunjang <strong class="text-danger">*</strong></label>
            <b-form-textarea v-model="isParent.row.arancptlln_raber_ikhtisar" name="konsulIkhtisar" id="konsulIkhtisar" rows="4"
              class="form-control">
            </b-form-textarea>
            
            <VValidate 
                name="Ikhtisar Klinik & Pemeriksaan Penunjang Pemeriksaan Raber" 
                v-model="isParent.row.arancptlln_raber_ikhtisar" 
                :rules="toValidate(isParent.mrValidation.arancptlln_raber_ikhtisar)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  data() {
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
  },     
}

</script>